import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import LoginPage from '@/pages/Login.vue';
import LogoutPage from '@/pages/Logout.vue';
import PageNotFoundPage from '@/pages/PageNotFound.vue';
import AnalyzerSingle from '@/pages/Analyzer/Single.vue';
import AnalyzerBase from '@/pages/Analyzer/Base.vue';
import AnalyzerOverview from '@/pages/Analyzer/Overview.vue';
import ConnectorSingle from '@/pages/Connector/Single.vue';
import ConnectorBase from '@/pages/Connector/Base.vue';
import ConnectorOverview from '@/pages/Connector/Overview.vue';
import PassThrough from '@/components/PassThrough.vue';
import CallOverview from '@/pages/Analyzer/Call/Overview.vue';
import CallSingle from '@/pages/Analyzer/Call/Single.vue';
import AgentInsights from '@/pages/Analyzer/AgentInsights.vue';
import ConversationInsights from '@/pages/Analyzer/ConversationInsights.vue';
import Analytics from '@/pages/Analyzer/Analytics.vue';
import AnalyzerStatus from '@/pages/Analyzer/Status.vue';
import HomePage from '@/pages/Home.vue';
import { getBreadcrumb } from '@/js/breadcrumbs';
import AnalyzerConfiguration from '@/pages/Analyzer/Configuration.vue';
import NotAllowedToViewPage from '@/pages/NotAllowedToViewPage.vue';
import SingleSignOnWrapper from '@/pages/Administration/SingleSignOnWrapper.vue';
import Cookies from 'js-cookie';
import endpoints from '@/js/endpoints';

Vue.use(VueRouter);

async function checkAuth(to, from, next) {
  try {
    const authStatus = await store.dispatch('authentication');
    if (authStatus === 'success') {
      const superuserRequired = to.matched.some((route) => route.meta.requiresSuperuser);
      const isSuperuser = store.getters['auth/isSuperuser'];
      if (superuserRequired && !isSuperuser) {
        next({ name: 'not-allowed-to-view', query: { requestedPath: to.path } });
      } else {
        next();
      }
    } else if (authStatus === 'no token') {
      next({ name: 'login', query: { originalPath: to.path } });
    } else {
      console.log(`unexpected status ${authStatus}`);
      next({ name: 'login', query: { originalPath: to.path } });
    }
  } catch (error) {
    console.log(error);
    next({ name: 'login', query: { originalPath: to.path } });
  }
  next();
}
const routes = [
  {
    path: '/',
    beforeEnter: checkAuth,
    name: 'home',
    component: HomePage,
    meta: {
      breadcrumb: { display: ' ', link: null },
    },
  },
  { path: '/not-allowed-to-view', component: NotAllowedToViewPage, name: 'not-allowed-to-view' },
  {
    path: '/login',
    component: LoginPage,
    name: 'login',
    meta: {
      breadcrumb: { display: ' ', link: null },
    },
  },
  {
    path: '/login-supwiz',
    name: 'login-supwiz',
    beforeEnter: () => window.location.replace(endpoints.ssoLoginSupwiz),
  },
  {
    path: '/logout',
    component: LogoutPage,
    name: 'logout',
    meta: {
      breadcrumb: { display: ' ', link: null },
    },
  },
  {
    path: '/connector',
    component: ConnectorBase,
    beforeEnter: checkAuth,
    meta: {
      breadcrumb: {
        display: 'Connectors', link: { name: 'connector-overview' },
      },
    },
    children: [
      {
        path: '',
        component: ConnectorOverview,
        name: 'connector-overview',
        meta: {
          breadcrumb: { display: ' ', link: null },
        },
      },
      {
        path: ':connectorId',
        component: ConnectorSingle,
        name: 'connector-single',
        meta: {
          breadcrumb: getBreadcrumb,
        },
      },
    ],
  },
  {
    path: '/analyzer',
    component: AnalyzerBase,
    beforeEnter: checkAuth,
    meta: {
      breadcrumb: { display: 'Analyzers', link: { name: 'analyzer-overview' } },
    },
    children: [
      {
        path: '',
        name: 'analyzer-overview',
        component: AnalyzerOverview,
        meta: {
          breadcrumb: getBreadcrumb,
        },
      },
      {
        path: ':analyzerId',
        name: 'analyzer-single',
        component: AnalyzerSingle,
        redirect: { name: 'configuration' },
        meta: {
          breadcrumb: getBreadcrumb,
        },
        children: [
          {
            path: 'configuration',
            name: 'configuration',
            component: AnalyzerConfiguration,
            meta: {
              breadcrumb: { display: 'Configuration', link: { name: 'configuration' } },
            },
          },
          {
            path: 'call',
            name: 'call',
            component: PassThrough,
            redirect: { name: 'call-overview' },
            meta: {
              breadcrumb: { display: 'Call', link: { name: 'call' } },
            },
            children: [
              {
                path: '',
                component: CallOverview,
                name: 'call-overview',
                meta: {
                  breadcrumb: { display: ' ', link: null },
                },
              },
              {
                path: ':callId',
                component: CallSingle,
                name: 'call-single',
                meta: {
                  breadcrumb: { display: 'Single', link: { name: 'call-single' } },
                },
              },
            ],
          },
          {
            path: 'agent-insights',
            component: AgentInsights,
            name: 'agent-insights',
            meta: {
              breadcrumb: { display: 'Agent Insights', link: { name: 'agent-insights' } },
            },
          },
          {
            path: 'conversation-insights',
            component: ConversationInsights,
            name: 'conversation-insights',
            meta: {
              breadcrumb: { display: 'Conversation Insights', link: { name: 'conversation-insights' } },
            },
          },
          {
            path: 'analytics',
            component: Analytics,
            name: 'analytics',
            meta: {
              breadcrumb: { display: 'analytics', link: { name: 'analytics' } },
            },
          },
          {
            path: 'status',
            component: AnalyzerStatus,
            name: 'status',
            meta: {
              breadcrumb: { display: 'Status', link: { name: 'status' } },
            },
          },
        ],
      },

    ],
  },
  {
    path: '/administration',
    name: 'administration',
    beforeEnter: checkAuth,
    redirect: { name: 'single-sign-on' },
    component: PassThrough,
    meta: {
      breadcrumb: { display: 'Administration', link: { name: 'administration' } },
      requiresSuperuser: true,
    },
    children: [
      {
        path: 'single-sign-on',
        name: 'single-sign-on',
        beforeEnter: checkAuth,
        component: SingleSignOnWrapper,
        meta: {
          breadcrumb: { display: 'Single Sign On', link: { name: 'single-sign-on' } },
          requiresSuperuser: true,
        },
      },
    ],
  },
  {
    path: '/sso-auth',
    name: 'sso-auth',
    beforeEnter: (to, from, next) => {
      const { originalPath } = to.query;
      if (to.query.success === 'true') {
        const refreshToken = Cookies.get('jwt_refresh_token');
        const accessToken = Cookies.get('jwt_access_token');
        Cookies.remove('jwt_refresh_token');
        Cookies.remove('jwt_access_token');
        if (refreshToken && accessToken) {
          store.commit('auth/updateAccessToken', accessToken);
          store.commit('auth/updateRefreshToken', refreshToken);
          if (originalPath) {
            next(originalPath);
          } else {
            next({ name: 'home' });
          }
        } else {
          next({ name: 'login', query: { sso_error: 'unexpected_error', originalPath } });
        }
      } else {
        next({ name: 'login', query: { sso_error: to.query.sso_error, originalPath } });
      }
    },
  },
  // At the end we have a catch all. Since routes are matched in order this will only match if
  // no other route matches.
  {
    path: '*',
    component: PageNotFoundPage,
    name: '404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.params.analyzerId !== undefined) {
    store.commit('sidebar/setShowSidebar', true);
  } else {
    store.commit('sidebar/setShowSidebar', false);
  }
  store.commit('sidebar/setLoading', true);
  next();
});
router.afterEach(() => {
  store.commit('sidebar/setLoading', false);
});
export default router;
