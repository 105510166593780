<template>
  <section>
    <h3>Synchronization</h3>
    <b-card no-body>
      <p class="mb-0">
        The analyzer retrieves data from the connected system that it can transcribe and analyze.
        This section contains settings specifying what data is retrieved from the connected system.
      </p>
    </b-card>
    <hr>
    <div @click="fetchGroupsLocal" @keyup.tab="fetchGroupsLocal">
      <edit-key-value
        class="my-3"
        description="Select the department(s) that data will be synchronized from"
        :key-prop="`Filter ${connectorGroupName}`"
        :value-prop="null"
        :min-key-width="keyWidth"
        type=""
        @click="fetchGroupsLocal"
      >
        <template #inputcontent>
          <b-form-group
            class="mb-0 px-1 border r-25-right"
          >
            <b-overlay
              :show="isFetching"
              rounded="sm"
            >
              <chip-list
                disabled-text="This department has been deleted"
                :value="filterGroupValues"
                :completions="groupsCompletions"
                :disabled-entries="deletedGroups"
                @input="updateDepartments"
              />
              <template #overlay>
                <div class="text-center">
                  <b-spinner
                    style="width: 1rem; height: 1rem;"
                  />
                  <p class="mb-0">
                    Fetching {{ connectorGroupName }}s...
                  </p>
                </div>
              </template>
            </b-overlay>
          </b-form-group>
        </template>
      </edit-key-value>
    </div>

    <edit-key-value
      class="my-3"
      key-prop="Filter inbound call"
      description="Choose whether inbound calls from end-users will be synchronized"
      :value-prop="analyzerForm.filterInboundCall"
      :min-key-width="keyWidth"
      type="checkbox"
      @input="(x)=>analyzerForm.filterInboundCall = x"
    />
    <edit-key-value
      v-if="[connectorTypes.ZENDESK.value, connectorTypes.TRIO.value].includes(connectorType)"
      class="my-3"
      key-prop="Filter outbound call"
      description="Choose whether outbound calls to end-users will be synchronized"
      :value-prop="analyzerForm.filterOutboundCall"
      :min-key-width="keyWidth"
      type="checkbox"
      @input="(x)=>analyzerForm.filterOutboundCall = x"
    />
    <edit-key-value
      class="mt-3"
      key-prop="Synchronize automatically"
      description="Choose whether data will be automatically synchronized"
      :value-prop="analyzerForm.syncAutomatically"
      :min-key-width="keyWidth"
      type="checkbox"
      @input="(x)=>analyzerForm.syncAutomatically = x"
    />
    <b-collapse
      v-model="analyzerForm.syncAutomatically"
    >
      <edit-key-value
        class="mt-3"
        key-prop="Synchronize all calls"
        description="Choose whether all should be synchronized"
        :value-prop="analyzerForm.openStartDate"
        :min-key-width="keyWidth"
        type="checkbox"
        @input="(x)=>toggleAllCalls(x)"
      />
      <b-collapse
        v-model="showStartDate"
      >
        <edit-key-value
          class="mt-3"
          key-prop="Synchronize from"
          description="Automatic synchronization should only include data after this timestamp"
          :value-prop="analyzerForm.syncFrom"
          :min-key-width="keyWidth"
          :disabled="analyzerForm.openStartDate"
          type="date"
          @input="(x)=>analyzerForm.syncFrom = x"
        />
      </b-collapse>
      <b-form-group v-if="false">
        <template #label>
          <span>Synchronization interval (time between sync. jobs)</span>
          <span class="float-right">
            {{ timeFormat(analyzerForm.syncInterval) }} (mm:ss)

          </span>
        </template>
        <b-form-input
          v-model="analyzerForm.syncInterval"
          type="range"
          size="sm"
          min="15"
          max="600"
          :no-wheel="false"
        />
      </b-form-group>
    </b-collapse>

    <b-button
      variant="primary"
      class="mt-3 d-block"
      :style="`width: ${keyWidth}px;`"
      :disabled="!unsavedChanges"
      @click="updateAnalyzer(analyzerForm)"
    >
      Save Changes
    </b-button>
    <span
      v-if="unsavedChanges"
      class="unsaved-text"
    >
      *Unsaved changes
    </span>
  </section>
</template>
<script>
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';
import { cloneDeep } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import ChipList from 'supwiz/components/ChipList.vue';
import { connectorTypes, connectorGroupNames } from '@/js/constants';

export default {
  name: 'Synchronization',
  components: {
    EditKeyValue,
    ChipList,
  },
  data() {
    return {
      analyzerForm: null,
      groups: [],
      connectorTypes,
      connectorGroupNames,
      showStartDate: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['headerAuthorization']),
    ...mapGetters('connector', ['typeFromId']),
    ...mapState('externalSystem', ['isFetching']),
    ...mapState('analyzer', { analyzerDetails: 'details' }),
    keyWidth() {
      return 220;
    },
    connectorType() {
      return this.typeFromId(this.analyzerDetails.dataSource);
    },
    connectorGroupName() {
      return this.connectorGroupNames[this.connectorType];
    },
    unsavedChanges() {
      return JSON.stringify(this.analyzerForm) !== JSON.stringify(this.analyzerDetails);
    },
    filterGroupValues() {
      return this.analyzerForm.filterDepartment.map((e) => e.id);
    },
    groupsCompletions() {
      return this.groups.length ? this.groups.map((e) => ({ key: e.id, value: e.name }))
        : this.analyzerForm.filterDepartment.map((e) => ({ key: e.id, value: e.name }));
    },
    deletedGroups() {
      return this.analyzerForm.filterDepartment.filter((e) => e.deleted).map((e) => e.id);
    },
  },
  watch: {
    unsavedChanges(newVal) {
      this.$emit('unsavedChanges', newVal);
    },
  },
  created() {
    this.analyzerForm = cloneDeep(this.analyzerDetails);
    this.showStartDate = !this.analyzerForm.openStartDate;
  },
  methods: {
    ...mapActions('analyzer', { updateAnalyzer: 'patchItem' }),
    ...mapActions('sidebar', ['showWarning']),
    ...mapActions('externalSystem', ['fetchZendeskDepartments', 'fetchPuzzelQueues', 'fetchTrioServices']),
    async fetchGroupsLocal() {
      if (!this.groups.length) {
        const activeElement = document.activeElement;
        this.groups = [];
        if (this.connectorType === this.connectorTypes.ZENDESK.value) {
          this.groups = await this.fetchZendeskDepartments(this.analyzerDetails.dataSource);
        } else if (this.connectorType === this.connectorTypes.PUZZEL.value) {
          this.groups = await this.fetchPuzzelQueues(this.analyzerDetails.dataSource);
        } else if (this.connectorType === this.connectorTypes.TRIO.value) {
          this.groups = await this.fetchTrioServices(this.analyzerDetails.dataSource);
        }
        if (document.activeElement === activeElement) {
          document.activeElement.blur();
          this.$nextTick(() => activeElement.focus());
        }
      }
    },
    async updateDepartments(ids) {
      if (!this.groups.length) {
        await this.fetchGroupsLocal();
      }
      const formattedGroups = [];
      ids.forEach((element) => {
        const current = this.groups.find((e) => e.id === element);
        formattedGroups.push({ id: element, name: current.name, deleted: current.deleted });
      });
      this.analyzerForm.filterDepartment = formattedGroups;
    },
    toggleAllCalls(val) {
      if (val === true) {
        this.analyzerForm.openStartDate = true;
        this.showStartDate = false;
        this.analyzerForm.syncFrom = null;
      } else {
        this.analyzerForm.openStartDate = false;
        this.showStartDate = true;
      }
    },
  },
};
</script>
