<template>
  <keep-alive
    v-if="chartType === 'line'"
  >
    <LineChart
      :data="chartData"
      :options="options"
    />
  </keep-alive>
  <keep-alive
    v-else-if="chartType === 'bar'"
  >
    <Bar
      :data="chartData"
      :options="options"
    />
  </keep-alive>
  <keep-alive
    v-else-if="chartType === 'pie'"
  >
    <Pie
      :data="chartData"
      :options="options"
    />
  </keep-alive>
  <keep-alive
    v-else-if="chartType === 'doughnut'"
  >
    <Doughnut
      :data="chartData"
      :options="options"
    />
  </keep-alive>
  <div v-else>
    Invalid chart type.
  </div>
</template>

<script>
import {
  Doughnut,
  Bar,
  Line as LineChart,
  Pie,
} from 'vue-chartjs';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  ArcElement,
  LineElement,
  LinearScale,
  PointElement,
} from 'chart.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  BarElement,
  CategoryScale,
  LineElement,
  LinearScale,
  ArcElement,
  PointElement,
);

export default {
  name: 'TypedChart',
  components: {
    Bar,
    LineChart,
    Doughnut,
    Pie,
  },
  props: {
    chartType: { type: String, default: 'line' },
    chartData: { type: Object, required: true },
    options: { type: Object, required: true },
  },
  computed: {
    updatedChartOptions() {
      const updatedOptions = { ...this.options };
      // if (this.chartType === 'pie') {
      //   updatedOptions.plugins.legend = {
      //     labels: {
      //       generateLabels(chart) {
      //         const original = ChartJS.overrides.pie.plugins.legend.labels.generateLabels;
      //         const labelsOriginal = original.call(this, chart);

      //         // Build an array of colors used in the datasets of the chart
      //         let datasetColors = chart.data.datasets.map((e) => e.backgroundColor);
      //         datasetColors = datasetColors.flat();

      //         // Modify the color and hide state of each label
      //         labelsOriginal.forEach((label) => {
      //           label.datasetIndex = (label.index - label.index % 2) / 2;

      //           // The hidden state must match the dataset's hidden state
      //           label.hidden = !chart.isDatasetVisible(label.datasetIndex);

      //           // Change the color to match the dataset
      //           label.fillStyle = datasetColors[label.index];
      //         });

      //         return labelsOriginal;
      //       },
      //       onClick(mouseEvent, legendItem, legend) {
      //         legend.chart.getDatasetMeta(
      //           legendItem.datasetIndex,
      //         ).hidden = legend.chart.isDatasetVisible(legendItem.datasetIndex);
      //         legend.chart.update();
      //       },
      //     },
      //   };
      // }

      return updatedOptions;
    },
  },

};
</script>
