<template>
  <b-card
    v-if="analyzerDetails"
    no-body
    class="r-75 d-flex flex-column px-0"
    :class="unsavedChanges ? 'border-warning' : ''"
  >
    <div
      v-if="chosenSection"
      class="d-flex flex-row mb-3 top-buttons"
    >
      <b-button
        v-for="(item, index) in configSections"
        :key="index"
        v-b-tooltip.hover.bottom
        :variant="isActive(item.name) ? 'secondary' : 'light'"
        :title="item.name"
        class="flex-fill p-0 "
        @click="chooseSection(item.name)"
      >
        <b-img
          :src="require(`@/assets/settingsIcons/${item.icon}`)"
          fluid
          class="mx-auto my-0"
          style="max-width:40px"
          alt="Responsive image"
        />
      </b-button>
    </div>
    <div
      class="mx-3 mt-0"
    >
      <b-row
        v-if="!chosenSection"
        class="mb-2 pr-2"
      >
        <b-col
          v-for="(item, index) in configSections"
          :key="index"
          :cols="windowWidth <= 1480 ? '4' : '3'"
          class="mt-2 mb-0 pl-2 pr-0"
        >
          <b-card
            style="border-radius:2px; cursor:pointer"
            no-body
            class="text-center shadow-sm cart"
            @click="chooseSection(item.name)"
          >
            <span
              v-b-tooltip.top.hover.noninteractive.viewport
              :title="item.tooltipText"
            >
              <b-img
                :src="require(`@/assets/settingsIcons/${item.icon}`)"
                fluid
                class="mx-auto my-2"
                style="max-width:90px"
                alt="Responsive image"
              />

              <h4
                class="mb-2"
                style="font-size: 1.4em;"
              >
                {{ item.name }}
              </h4></span>
          </b-card>
        </b-col>
      </b-row>
      <div
        v-if="chosenSection"
        key="2"
        class="w-100 mb-3"
      >
        <general
          v-if="chosenSection === 'general'"
          :is-edit="true"
          @unsavedChanges="(val)=>updateUnsavedChanges(val)"
        />
        <conversation-score
          v-if="chosenSection === 'conversation-score'"
          @unsavedChanges="(val)=>updateUnsavedChanges(val)"
        />
        <synchronization
          v-if="chosenSection === 'synchronization'"
          @unsavedChanges="(val)=>updateUnsavedChanges(val)"
        />
        <transcription
          v-if="chosenSection === 'transcription'"
          @unsavedChanges="(val)=>updateUnsavedChanges(val)"
        />
        <transcript-handling
          v-if="chosenSection === 'transcript-handling'"
          @unsavedChanges="(val)=>updateUnsavedChanges(val)"
        />
        <anonymization
          v-if="chosenSection === 'anonymization'"
          :id="analyzerDetails.anonymizationConfig"
          @unsavedChanges="(val)=>updateUnsavedChanges(val)"
        >
          <template slot="description">
            The analyzer supports data anonymization to enforce user privacy. These settings define
            if, when, and what to anonymize and if and when to delete data.
          </template>
        </anonymization>
        <custom-categories v-if="chosenSection === 'custom-categories'" />
      </div>
    </div>
  </b-card>
  <b-card
    v-else
    title="Analyzer does not exist"
    class="r-75"
  >
    <b-link :to="{ name: 'analyzer-overview' }">
      Choose different analyzer
    </b-link>
  </b-card>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { connectorTypes } from '@/js/constants';
import Anonymization from 'supwiz/components/anonymization/Anonymization.vue';
import General from '@/components/Analyzer/Configuration/General.vue';
import Synchronization from '@/components/Analyzer/Configuration/Synchronization.vue';
import Transcription from '@/components/Analyzer/Configuration/Transcription.vue';
import TranscriptHandling from '@/components/Analyzer/Configuration/TranscriptHandling.vue';
import CustomCategories from '@/components/Analyzer/Configuration/CustomCategories.vue';
import ConversationScore from '@/components/Analyzer/Configuration/ConversationScore.vue';

export default {
  name: 'Configuration',
  components: {
    General,
    Synchronization,
    Transcription,
    TranscriptHandling,
    Anonymization,
    ConversationScore,
    CustomCategories,
  },
  beforeRouteUpdate(to, from, next) {
    const hash = to.hash.toLowerCase().replace('#', '');
    if (hash) {
      this.chosenSection = hash;
    } else {
      this.chosenSection = null;
    }
    next();
  },
  data() {
    return {
      unsavedChanges: false,
      chosenSection: null,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapState('analyzer', { analyzerDetails: 'details' }),
    ...mapGetters('connector', ['typeFromId']),
    connectorType() {
      return this.typeFromId(this.analyzerDetails.dataSource);
    },
    configSections() {
      const sections = [
        { name: 'General', icon: 'cog.svg', tooltipText: 'Configure general analyzer settings' },
        { name: 'Synchronization', icon: 'synchronization.svg', tooltipText: 'Configure synchronization' },
        { name: 'Transcription', icon: 'transcription.svg', tooltipText: 'Configure transcript settings' },
        { name: 'Transcript Handling', icon: 'transcriptionHandling.svg', tooltipText: 'Set transcript handling behavior' },
        { name: 'Custom Categories', icon: 'customCategories.svg', tooltipText: 'Define custom categories' },
        { name: 'Conversation score', icon: 'convScore.svg', tooltipText: 'Configure how conversation score is calculated' },
        { name: 'Anonymization', icon: 'anonymization.svg', tooltipText: 'Anonymize data' },
      ];
      if (this.connectorType === connectorTypes.FILE_UPLOAD.value) {
        return sections.filter((el) => el.name !== 'Transcription');
      }
      return sections;
    },
  },
  created() {
    if (!this.chosenSection) {
      const hash = this.$route.hash.toLowerCase().replace('#', '');
      this.chosenSection = hash;
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    chooseSection(name) {
      const tabHash = `#${name.replace(/ /g, '-').toLowerCase()}`;
      if (this.$route.hash.toLowerCase() !== tabHash) {
        this.$router.replace({ hash: tabHash });
      }
      this.chosenSection = name.replace(/ /g, '-').toLowerCase();
      if (document.activeElement) {
        document.activeElement.blur();
      }
    },
    isActive(name) {
      const h = this.$route.hash;
      if (h.toLowerCase().includes(name.replace(/ /g, '-').toLowerCase())) {
        return true;
      }
      return false;
    },
    updateUnsavedChanges(val) {
      this.unsavedChanges = val;
    },
  },

};
</script>
<style scoped>
.cart{
  transition: transform 50ms;
}
.cart:hover {
  transform: translateY(-5px);
}
.top-buttons{
  margin: 1px 1px 0px 1px;
}
.top-buttons > .btn{
  border-radius: 0%;
}
.top-buttons > button:first-child{
  border-top-left-radius: .75rem;
}
.top-buttons > button:last-child{
  border-top-right-radius: .75rem;
}
</style>
