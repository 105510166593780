<template>
  <section>
    <h3>Custom Categories</h3>
    <b-card no-body>
      <p class="mb-0">
        Define and edit custom word/tag based categories for use in the statistics module.
      </p>
    </b-card>
    <hr>
    <div class="border bg-light px-2 w-100">
      <table-data
        borderless
        show-empty
        :busy="isFetching"
        thead-tr-class="d-none"
        tbody-tr-class="bg-white shadow-sm p-2 my-2 text-break"
        table-class="spaced-table"
        :fields="fields"
        :items="localData"
        @add="openCategoryModal"
      >
        <template #cell(name)="data">
          <div class="text-center">
            <small class="text-muted">
              Category Name
            </small>
            <br>
            {{ data.item.name }}
          </div>
        </template>
        <template #cell(include)="data">
          <div class="text-center">
            <small class="text-muted">
              Includes
            </small>
            <br>
            {{ includeCategoriesLength(data.item) }} lists,
            {{ getItemNumber('include', data.item) }} items
          </div>
        </template>
        <template #cell(exclude)="data">
          <div class="text-center">
            <small class="text-muted">
              Excludes
            </small>
            <br>
            {{ excludeCategoriesLength(data.item) }} lists,
            {{ getItemNumber('exclude', data.item) }} items
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-b-tooltip.hover.noninteractive.viewport
            title="Edit category"
            variant="outline-secondary"
            class="ml-1 action-button"
            @click="editCategory(data.item)"
          >
            <font-awesome-icon
              icon="edit"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.noninteractive.viewport
            title="Delete category"
            variant="outline-secondary"
            class="ml-1 action-button"
            @click="deleteCategoryLocal(data.item)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-button>
        </template>
      </table-data>
    </div>
    <add-category-modal
      :modal-info="modalInfo"
      :active-item="activeItem"
      @hide="clearModal"
    />
  </section>
</template>
<script>
import TableData from 'supwiz/components/TableData.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import AddCategoryModal from '@/components/Analyzer/AddCategoryModal.vue';
import { supportedStemmingLanguages } from '@/js/constants';
import { categoryTemplate } from '@/js/utils';

export default {
  name: 'CustomCategories',
  components: {
    TableData,
    AddCategoryModal,
  },
  data() {
    return {
      modalInfo: {
        action: null,
        activeItemId: null,
      },
      fields: [
        { key: 'name', label: '' },
        { key: 'include', label: '' },
        { key: 'exclude', label: '' },
        { key: 'actions', label: '', tdClass: 'action-col' },
      ],
      keyWidth: 200,
      activeItem: {},
      localData: [],
    };
  },

  computed: {
    ...mapGetters('category', ['analyzerCategories', 'isFetching']),
    ...mapState('analyzer', { analyzerDetails: 'details' }),
    ...mapState('category', { categories: 'items' }),
    unsavedChanges() {
      return JSON.stringify(this.localData) !== JSON.stringify(this.analyzerCategories);
    },
    stemsAndSynonymsDisabled() {
      return !supportedStemmingLanguages.includes(this.analyzerDetails.language.slice(0, 2));
    },
  },
  watch: {
    analyzerCategories(n) {
      this.localData = cloneDeep(n);
    },
  },
  async created() {
    await this.fetchCategories();
    this.localData = cloneDeep(this.analyzerCategories);
  },
  methods: {
    categoryTemplate,
    ...mapActions('category', {
      fetchCategories: 'fetchItems',
      updateCategory: 'patchItem',
      deleteCategory: 'deleteItem',
    }),
    includeCategoriesLength(item) {
      return item.lines.phrasesAllowed ? item.lines.phrasesAllowed.length : 0;
    },
    excludeCategoriesLength(item) {
      return item.lines.phrasesDisallowed ? item.lines.phrasesDisallowed.length : 0;
    },
    openCategoryModal() {
      this.modalInfo.action = 'Add';
      this.activeItem = this.categoryTemplate(this.analyzerDetails.id);
      this.$nextTick(() => {
        this.$bvModal.show('add-category-modal');
      });
    },
    clearModal() {
      this.modalInfo.action = null;
      this.activeItem = {};
    },
    editCategory(item) {
      this.modalInfo.action = 'Edit';
      this.modalInfo.activeItemId = item.name;
      this.activeItem = cloneDeep(item);
      this.$nextTick(() => {
        this.$bvModal.show('add-category-modal');
      });
    },
    async deleteCategoryLocal(item) {
      const modalText = 'Are you sure that you want to delete this category?<br><strong>Doing this will also remove the'
        + ' category from all saved statistics configurations!</strong>';
      const modalOptions = {
        okTitle: 'Delete', okVariant: 'danger', html: true, centered: true,
      };
      const h = this.$createElement;
      const messageNode = h('span', { domProps: { innerHTML: modalText } });
      if (await this.$bvModal.msgBoxConfirm([messageNode], modalOptions)) {
        await this.deleteCategory({ item });
      }
    },
    getItemNumber(type, item) {
      if (type === 'include') {
        const phrasesLength = item.lines.phrasesAllowed.map((e) => e.length)
          .reduce((partialSum, a) => partialSum + a, 0);
        const tagsLength = item.lines.tagsAllowed.map((e) => e.length)
          .reduce((partialSum, a) => partialSum + a, 0);
        return phrasesLength + tagsLength;
      }
      const phrasesLength = item.lines.phrasesDisallowed.map((e) => e.length)
        .reduce((partialSum, a) => partialSum + a, 0);
      const tagsLength = item.lines.tagsDisallowed.map((e) => e.length)
        .reduce((partialSum, a) => partialSum + a, 0);
      return phrasesLength + tagsLength;
    },

  },
};
</script>
<style scoped>

::v-deep .spaced-table {
  font-size: 0.9375rem;
  border-collapse: separate;
  border-spacing: 0 0.5rem;
}
::v-deep .action-col{
  width: 100px;
}

</style>
