<template>
  <div
    class="px-0 container-fluid h-100"
  >
    <div>
      <b-card
        title="Connectors Overview"
        class="r-75"
        body-class="p-3"
      >
        <table-data
          :items="items"
          :fields="fields"
          hover
          empty-text="No connectors were found."
          :deleting-id="deletingId"
          @delete="deleteConnectorLocal"
          @row-clicked="setEditing"
          @add="$root.$emit('bv::show::modal', 'create-connector-modal')"
        />
      </b-card>
      <b-modal
        id="create-connector-modal"
        title="Create New Connector"
        size="lg"
        centered
        hide-footer
      >
        <connector-form @hide="$bvModal.hide('create-connector-modal')" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TableData from 'supwiz/components/TableData.vue';
import ConnectorForm from '@/components/Connector/ConnectorForm.vue';

export default {
  name: 'ConnectorOverview',
  components: {
    TableData,
    ConnectorForm,
  },
  data() {
    return {
      fields: [
        { key: 'delete', label: '', tdClass: 'delete-column' },
        {
          label: 'Name', key: 'name', class: 'w-25',
        },
        {
          label: 'Type', key: 'type', class: 'w-25', formatter: this.formatType,
        },
        {
          label: 'Description', key: 'description',
        },
      ],
    };
  },
  computed: {
    ...mapState('connector', { connectors: 'items', deletingId: 'deletingId' }),
    items() {
      return Object.values(this.connectors);
    },
  },
  methods: {
    ...mapActions('connector', { deleteConnector: 'deleteItem' }),
    setEditing(connector) {
      this.$router.push({ name: 'connector-single', params: { connectorId: connector.id } });
    },
    async deleteConnectorLocal(item) {
      const modalText = `Are you sure that you want to delete ${item.name}?`;
      const modalOptions = { okTitle: 'Delete', okVariant: 'danger' };
      if (await this.$bvModal.msgBoxConfirm(modalText, modalOptions)) {
        await this.deleteConnector({ item });
      }
    },
    formatType(value) {
      if (!value) return 'Unknown';
      // DataSources should always be named <Platform>DataSource hence -10
      const ret = value.substring(0, value.length - 10);
      return ret[0].toUpperCase() + ret.slice(1);
    },
  },
};
</script>
