<template>
  <b-row
    v-if="isFetching"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <router-view v-else />
</template>
<script>
import {
  mapActions, mapMutations, mapState,
} from 'vuex';

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.params.analyzerId) {
        vm.fetchAnalyzerDetails({ id: to.params.analyzerId });
        vm.localIsFetching = false; // eslint-disable-line no-param-reassign
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.analyzerId !== from.params.analyzerId) {
      this.fetchAnalyzerDetails({ id: to.params.analyzerId });
    }
    next();
  },
  data() {
    return {
      localIsFetching: true,
    };
  },
  computed: {
    ...mapState('analyzer', ['isFetchingDetails']),
    isFetching() {
      return this.localIsFetching || this.isFetchingDetails;
    },
  },
  destroyed() {
    this.setAnalyzerDetails({});
    this.setSavedFilters(null);
    this.clearLocalStatsConfig();
    this.clearLocalStatisticsFilters();
    this.resetStatisticsData();
  },
  methods: {
    ...mapMutations('call', ['setSavedFilters']),
    ...mapActions('analyzer', { fetchAnalyzerDetails: 'fetchItemDetails' }),
    ...mapMutations('connector', { setAnalyzerDetails: 'setItemDetails' }),
    ...mapMutations('statistics', ['resetStatisticsData', 'clearLocalStatisticsFilters']),
    ...mapMutations('statsConfiguration', ['clearLocalStatsConfig']),
  },
};
</script>
