import { render, staticRenderFns } from "./NewKPICard.vue?vue&type=template&id=266f0cde&scoped=true"
import script from "./NewKPICard.vue?vue&type=script&lang=js"
export * from "./NewKPICard.vue?vue&type=script&lang=js"
import style0 from "./NewKPICard.vue?vue&type=style&index=0&id=266f0cde&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../products/voiceanalyzer/frontend/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "266f0cde",
  null
  
)

export default component.exports