<template>
  <h4>
    You are viewing
    <b-dropdown
      right
      toggle-class="text-decoration-none py-0 text-dark bg-white border mb-1 config-dropdown"
      menu-class="bg-white"
    >
      <template #button-content>
        <span class="config-title">
          {{ statsConfigurationDetails.name || 'new' }}
        </span>
      </template>
      <b-dropdown-header>Actions</b-dropdown-header>
      <b-dropdown-item-button
        v-if="!isNewConfiguration"
        @click.exact="newConfig(null)"
      >
        Create new filters
      </b-dropdown-item-button>
      <b-dropdown-item-button
        @click.exact="saveClicked"
      >
        {{ `${isNewConfiguration ? 'Save' : 'Update'} current filters` }}
      </b-dropdown-item-button>
      <b-dropdown-item-button
        v-if="!isNewConfiguration"
        @click.exact="deleteStatsConfigLocal"
      >
        Delete current filters
      </b-dropdown-item-button>
      <template v-if="configurations.length">
        <b-dropdown-divider class="my-1" />
        <b-dropdown-header>Choose filters</b-dropdown-header>
        <b-dropdown-item
          v-for="(item, index) in configurations"
          :key="index"
          @click="fetchStatsConfigDetailsLocal(item.id)"
        >
          {{ item.name }}
        </b-dropdown-item>
      </template>
    </b-dropdown>
    {{ title }}
  </h4>
</template>
<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { cloneDeep } from 'lodash';

export default {
  name: 'StatsConfigurationDropdown',
  props: {
    defaultFilterValues: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    filterSource: {
      type: [String, Object],
      default: () => null,
    },
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapState('statsConfiguration', { statsConfigurationDetails: 'details' }),
    ...mapState('statsConfiguration', { statsConfigurations: 'items' }),
    ...mapGetters('statsConfiguration', ['formattedConfigData']),
    ...mapState('analyzer', { analyzerDetails: 'details' }),
    isNewConfiguration() {
      return Object.keys(this.statsConfigurationDetails).length === 0;
    },
    configurations() {
      if (this.filterSource) {
        return Object.values(this.statsConfigurations)
          .filter((e) => this.filterSource === e.source && this.analyzerDetails.id === e.analyzer);
      }
      return Object.values(this.statsConfigurations);
    },
  },
  methods: {
    ...mapActions('statsConfiguration', {
      fetchStatsConfigDetails: 'fetchItemDetails',
      updateStatsConfig: 'patchItem',
      deleteStatsConfig: 'deleteItem',
    }),
    ...mapMutations('statsConfiguration', { setStatsConfigurationDetails: 'setItemDetails' }),
    ...mapMutations('statisticsFiltersStore', ['setFilters']),
    ...mapMutations('statsConfiguration', ['setLocalStatsConfig']),
    newConfig() {
      this.setStatsConfigurationDetails({});
      this.setFilters(cloneDeep(this.defaultFilterValues));
      this.$emit('needsRefresh', true);
    },
    saveClicked() {
      if (!this.isNewConfiguration) {
        this.updateStatsConfig(this.formattedConfigData(false));
      } else {
        this.$root.$emit('show-config-modal', true);
      }
    },
    fetchStatsConfigDetailsLocal(id) {
      this.$emit('needsRefresh', true);
      this.fetchStatsConfigDetails({ id });
    },
    async deleteStatsConfigLocal() {
      const modalText = 'Are you sure that you want to delete the filters?';
      const modalOptions = { okTitle: 'Delete', okVariant: 'danger' };
      const source = this.statsConfigurationDetails.source;
      if (await this.$bvModal.msgBoxConfirm(modalText, modalOptions)) {
        await this.deleteStatsConfig({ item: this.statsConfigurationDetails });
        this.setStatsConfigurationDetails({});
        this.setFilters(cloneDeep(this.defaultFilterValues));
        this.setLocalStatsConfig({ key: source, value: null });
      }
    },
  },
};
</script>
