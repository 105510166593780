<template>
  <router-view />
</template>
<script>

export default {
  computed: {
  },
  methods: {
  },
};
</script>
