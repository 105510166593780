<template>
  <b-row
    v-if="isFetching"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <div
    v-else-if="objectNotFound"
    class="text-center mt-5 text-muted"
  >
    <h1>Call not found</h1>
    <p>Requested call could not be found.</p>
  </div>
  <b-row v-else>
    <b-col sm="12" md="7" lg="5" xl="4">
      <DetailsCard
        v-bind="{
          callDetails,
          formatTime,
        }"
      />
    </b-col>
    <b-col class="pl-0" sm="12" md="5" lg="7" xl="8">
      <div class="mb-2">
        <b-card
          no-body
          class="r-50 p-2 d-inline-block mr-2"
        >
          <b-button
            variant="primary"
            @click="$router.go(-1)"
          >
            Return
          </b-button>
          <b-button
            variant="primary"
            class="ml-2"
            @click="rehandleCallProxy"
          >
            Re-handle call
          </b-button>
          <b-dropdown
            v-if="hasTranscript"
            text="Configuration"
            variant="primary"
            class="ml-2"
            menu-class="bg-white"
          >
            <b-dropdown-form form-class="p-2">
              <b-form-group
                label="Change User"
                class="mb-0"
              >
                <b-form-select
                  v-model="visitorId"
                  :options="visitorOptions"
                  size="sm"
                />
              </b-form-group>
            </b-dropdown-form>
          </b-dropdown>
        </b-card>
        <b-card
          v-if="hasTranscript && !hasSummary && openAiEnabled()"
          no-body
          class="r-50 p-2 d-inline-block mr-2"
        >
          <b-overlay
            :show="summarizing"
          >
            <b-button
              variant="primary"
              :disabled="summarizing"
              @click="fetchSummary(callDetails.id)"
            >
              Summarize
            </b-button>
          </b-overlay>
        </b-card>
      </div>
      <b-card
        v-if="hasTranscript"
        class="r-50"
      >
        <collapsible-section
          v-if="hasSummary"
          title="Summary"
          class="mt-3"
          rounded
          @visible="(val) => isVisible = val"
        >
          <template #content>
            <div class="bg-white p-2">
              <p class="mb-0">
                {{ callDetails.summary }}
              </p>
            </div>
          </template>
        </collapsible-section>
        <sentiment-analysis
          v-if="hasSentiment"
          class="mb-3"
          :data="sentimentData"
          object-type="call"
        />
        <CallLog
          v-bind="{
            callDetails,
            formatTime,
            hasSentiment,
            visitorSpeaker,
          }"
        />
      </b-card>
      <div
        v-else
        class="text-center mt-5 text-muted"
      >
        <h2>No Transcript</h2>
        <p>A transcript could not be found.</p>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import {
  mapActions, mapMutations, mapState, mapGetters,
} from 'vuex';
import moment from 'moment';
import axios from 'axios';
import endpoints from '@/js/endpoints';
import { openAiEnabled } from '@/js/featureFlags';
import SentimentAnalysis from '@/components/Analyzer/SentimentAnalysis.vue';
import CollapsibleSection from '@/components/CollapsibleSection.vue';
import DetailsCard from './Single/DetailsCard.vue';
import CallLog from './Single/CallLog.vue';

export default {
  name: 'CallSingle',
  components: {
    DetailsCard,
    SentimentAnalysis,
    CallLog,
    CollapsibleSection,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.params.callId) {
        vm.fetchCallDetails({ id: to.params.callId, transcript: true });
        vm.localIsFetching = false; // eslint-disable-line no-param-reassign
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.callId !== from.params.callId) {
      this.fetchCallDetails({ id: to.params.callId, transcript: true });
    }
    next();
  },
  data() {
    return {
      localIsFetching: false,
      isGeneratingSummary: false,
    };
  },
  computed: {
    ...mapState('analyzer', { analyzerDetails: 'details' }),
    ...mapState('call', ['isFetchingDetails', 'summarizing']),
    ...mapState('call', { callDetails: 'details' }),
    ...mapGetters('auth', ['headerAuthorization']),
    legacySentiment() {
      return this.callDetails.sentimentPrediction.kind === undefined;
    },
    sentimentData() {
      const data = {
        labels: [],
        datasets: [{
          label: 'Sentiment',
          data: [],
          borderWidth: 5,
          borderColor: '#073F5C',
          backgroundColor: '#073F5C',
        }],
        visitorPhrases: [],
      };
      const prediction = this.callDetails.sentimentPrediction;
      if (this.legacySentiment) {
        // Handle sentiments from legacy sentiment models
        prediction.sentimentsResults.forEach((e) => {
          data.labels.push(e.timestamp.toFixed(1));
          const value = this.calculateAverageSentiment(e.sentiment_result);
          data.datasets[0].data.push(value);
        });
        data.visitorPhrases = prediction.visitorPhrases;
      } else {
        const sentenceParts = prediction.phraseSentimentParts;
        const visitorPhrases = new Set(prediction.visitorPhrases);
        sentenceParts.forEach((e, index) => {
          const timestamp = this.callDetails.transcript.recognizedPhrases[e.phrase_idx]
            .offsetInTicks / 1e7;
          data.labels.push(timestamp.toFixed(1));
          const sentiment = prediction.sentences[e.sentiment_idx];
          const value = this.calculateAverageSentiment(sentiment.confidence_scores);
          data.datasets[0].data.push(value);
          if (visitorPhrases.has(e.phrase_idx)) {
            data.visitorPhrases.push(index);
          }
        });
      }
      return data;
    },
    isFetching() {
      return this.localIsFetching || this.isFetchingDetails;
    },
    objectNotFound() {
      return Object.keys(this.callDetails).length === 0;
    },
    visitorOptions() {
      if (this.callDetails?.transcriptSpeakers) {
        return this.callDetails.transcriptSpeakers.map((e, i) => (
          { value: i, text: `Speaker ${e}${this.defaultVisitorId === i ? ' (default)' : ''}` }));
      }
      return [];
    },
    hasSentiment() {
      return !!Object.values(this.callDetails?.sentimentPrediction?.transcriptSentiment
      || {}).length;
    },
    hasSummary() {
      return !!this.callDetails?.summary;
    },
    hasTranscript() {
      return this.callDetails ? Object.keys(this.callDetails.transcript).length !== 0 : false;
    },
    defaultVisitorId() {
      return this.analyzerDetails?.defaultVisitorSpeakerId;
    },
    visitorId: {
      get() {
        const customId = this.callDetails.customVisitorSpeakerId;
        return customId !== null ? customId : this.defaultVisitorId;
      },
      set(val) {
        const call = {
          id: this.callDetails.id,
          customVisitorSpeakerId: val,
        };
        this.updateSingleCall({ ...call, transcript: true });
        // Also, add the call to the re-handling queue since data will have changed.
        try {
          const params = {
            analyzer: this.analyzerDetails.id,
            id__in: `${this.callDetails.id}`, // This is kinda hacky, but hopefully it works.
          };
          const request = {
            ...this.$store.getters['auth/headerAuthorization'],
          };
          request.params = params;
          axios.post(`${endpoints.call}/rehandle_calls/`, {}, request);
        } catch (error) {
          this.$store.dispatch('sidebar/showWarning', {
            title: 'Failed to rehandle calls',
            text: error.message,
          });
        }
      },
    },
    visitorSpeaker() {
      return this.callDetails?.transcriptSpeakers[this.visitorId];
    },
  },
  destroyed() {
    this.setCallDetails({});
  },
  methods: {
    ...mapActions('call', {
      fetchCallDetails: 'fetchItemDetails',
      updateSingleCall: 'patchItem',
      fetchSummary: 'fetchSummary',
      rehandleCalls: 'rehandleCalls',
    }),
    ...mapMutations('call', { setCallDetails: 'setItemDetails' }),
    openAiEnabled,
    formatTime(duration, digits = 0) {
      if (duration) {
        const seconds = moment.duration(typeof duration === 'number' ? duration * 1000 : duration).asMilliseconds();
        const min = Math.floor(seconds / 60_000);
        let sec = Math.floor(seconds - (60_000 * min)) / 1_000;
        sec = parseFloat(sec.toFixed(digits));
        return `${min < 10 ? 0 : ''}${min}:${sec < 10 ? 0 : ''}${sec.toFixed(digits)}`;
      }
      return 'Unknown';
    },
    calculateAverageSentiment(sentimentResult) {
      const weights = {
        neutral: 0,
        negative: -1,
        positive: 1,
      };
      const weightedSum = Object.entries(sentimentResult).reduce(
        (sum, [sentiment, value]) => {
          if (sentiment in weights) {
            return sum + (value * weights[sentiment]);
          }
          return sum;
        },
        0,
      );
      return weightedSum;
    },
    async rehandleCallProxy() {
      await this.rehandleCalls([this.callDetails.id]);
      this.fetchCallDetails({ id: this.callDetails.id, transcript: true });
    },
  },
};
</script>
