<template>
  <div class="bg-light p-1 r-25 d-flex flex-wrap">
    <div
      v-for="{ key, value } in sortedPredictions"
      :key="key"
      class="py-1 px-2 m-1 r-25 bg-white shadow-sm"
    >
      {{ key }}
      <PercentageDisplay
        :decimal="value"
        color
        class="px-1 r-25 small"
      />
    </div>
  </div>
</template>

<script>
import { sortBy, isEmpty } from 'lodash';
import PercentageDisplay from '@/components/PercentageDisplay.vue';

export default {
  name: 'PredictionsDisplay',
  components: { PercentageDisplay },
  props: {
    predictions: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sortedPredictions() {
      if (isEmpty(this.predictions)) return [];
      const predictionsAsArray = Object.entries(this.predictions)
        .map(([key, value]) => ({
          key,
          value,
        }));
      return sortBy(
        predictionsAsArray,
        ['value'],
      ).reverse();
    },
  },
};
</script>

<style scoped>

</style>
