import axios from 'axios';
import Vue from 'vue';
import { sortBy } from 'lodash';
import { camelCaseToText, tagMappingToSnake, tagMappingToCamel } from '@/js/utils';
import {
  objToCamel, objToSnake, strToCamel, strToSnake,
} from 'supwiz/util/data';
import { connectorTypeToEndpoint } from '@/js/constants';

const paginatedDataTypes = ['analyzer', 'call', 'category'];

export default class {
  constructor(endpoint, dataType) {
    this.state = {
      isFetching: false,
      items: {},
      pagination: {
        count: null,
        page: 1,
        perPage: 10,
      },
      isFetchingDetails: false,
      details: {},
      deletingId: -1,
    };

    this.getters = {
      busy: (state) => state.isFetching || state.isFetchingDetails,
      isFetching: (state) => state.isFetching,
      items: (state) => state.items,
      detailsId: (state) => state.details.id,
      page: (state) => state.pagination.page,
    };

    this.mutations = {
      setIsFetching(state, payload) {
        state.isFetching = payload;
      },
      setItems(state, items) {
        const itemsList = sortBy(items, 'name');
        const itemsDict = {};
        for (const obj of itemsList) {
          if (dataType === 'analyzer') {
            itemsDict[obj.id] = {
              ...objToCamel(obj),
              tagMapping: tagMappingToCamel(obj.tag_mapping, true),
            };
          } else {
            itemsDict[obj.id] = objToCamel(obj);
            if (dataType === 'statsConfiguration') {
              itemsDict[obj.id].source = strToCamel(obj.source);
            }
          }
        }
        state.items = itemsDict;
      },
      updateItem(state, { item }) {
        Vue.set(state.items, item.id, item);
      },
      updatePagination(state, payload) {
        state.pagination = Object.assign(state.pagination, payload);
      },
      resetPagination(state) {
        state.pagination = {
          count: null,
          page: 1,
          perPage: 10,
        };
      },
      setIsFetchingDetails(state, payload) {
        state.isFetchingDetails = payload;
      },
      setItemDetails(state, data) {
        if (data) {
          if (dataType === 'analyzer') {
            state.details = {
              ...objToCamel(data),
              tagMapping: tagMappingToCamel(data.tag_mapping, true),
            };
          } else if (dataType === 'call' && Object.values(data).length) {
            const scoreCategories = {};
            Object.entries(data.score_categories).forEach(([k, v]) => {
              scoreCategories[k] = objToCamel(v);
            });
            state.details = { ...objToCamel(data), scoreCategories, prediction: data.prediction };
          } else {
            state.details = objToCamel(data);
            if (dataType === 'statsConfiguration' && state.details.source) {
              state.details.source = strToCamel(state.details.source);
            }
          }
        }
      },
      setDeletingId(state, payload) {
        state.deletingId = payload;
      },
    };

    this.actions = {
      async fetchItems({
        commit, dispatch, rootGetters,
      }, { params, refreshing } = { params: {}, refreshing: false }) {
        if (!refreshing) {
          commit('setIsFetching', true);
        }
        try {
          const request = { ...rootGetters['auth/headerAuthorization'] };
          request.params = params;
          const { data } = await axios.get(endpoint, request);
          if (paginatedDataTypes.includes(dataType)) {
            const { results, count } = data;
            commit('updatePagination', { count });
            commit('setItems', results);
            commit('setIsFetching', false);
            return results;
          }
          commit('setItems', data);
          commit('setIsFetching', false);
          return data;
        } catch (error) {
          if (error?.response?.status === 400) {
            Vue.prototype.goTo404Page();
          } else {
            const formattedDataType = dataType === 'query' ? 'querie' : dataType;
            dispatch('sidebar/showWarning', {
              title: `Failed to fetch ${camelCaseToText(formattedDataType)}s`,
              text: error.message,
            }, { root: true });
            throw error;
          }
        } finally {
          commit('setIsFetching', false);
        }
        return [];
      },
      async fetchItemDetails({
        commit, dispatch, rootGetters,
      }, { id, refreshing, transcript = null }) {
        if (!refreshing) {
          commit('setIsFetchingDetails', true);
        }
        try {
          const auth = rootGetters['auth/headerAuthorization'];
          let data;
          if (transcript) {
            const response = await axios.get(`${endpoint}${id}/?transcript=true`, auth);
            data = response.data;
          } else {
            const response = await axios.get(`${endpoint}${id}/`, auth);
            data = response.data;
          }
          commit('setItemDetails', data);
        } catch (error) {
          dispatch('sidebar/showWarning', {
            title: `Failed to fetch ${camelCaseToText(dataType)} details`,
            text: error.message,
          }, { root: true });
          commit('setIsFetchingDetails', false);
          throw error;
        }
        commit('setIsFetchingDetails', false);
      },
      async addItem({ dispatch, rootGetters }, { newItem, fetchParams = {} }) {
        const auth = rootGetters['auth/headerAuthorization'];
        let itemData;
        let addEndpoint;
        if (dataType === 'connector') {
          const { type, ...data } = objToSnake(newItem);
          itemData = data;
          addEndpoint = connectorTypeToEndpoint[type];
        } else if (dataType === 'analyzer') {
          itemData = {
            ...objToSnake(newItem),
            tag_mapping: tagMappingToSnake(newItem.tagMapping, true),
          };
          addEndpoint = endpoint;
        } else {
          itemData = objToSnake(newItem);
          if (dataType === 'statsConfiguration') {
            itemData.source = strToSnake(itemData.source);
          }
          addEndpoint = endpoint;
        }
        try {
          const { data: addedItem } = await axios.post(addEndpoint, itemData, auth);
          if ('id' in addedItem) {
            await dispatch('fetchItems', { params: fetchParams });
            return true;
          }
          dispatch('sidebar/showWarning', {
            title: 'Unexpected Response',
            text: `Got unexpected response when adding ${camelCaseToText(dataType)}`,
          }, { root: true });
          return false;
        } catch (error) {
          dispatch('sidebar/showWarning', {
            title: `Failed to add ${camelCaseToText(dataType)}`,
            text: error.message,
          }, { root: true });
          throw error;
        }
      },
      async deleteItem({ dispatch, rootGetters }, { item, fetchParams = {} }) {
        const auth = rootGetters['auth/headerAuthorization'];
        try {
          let deleteEndpoint;
          if (dataType === 'connector') {
            deleteEndpoint = connectorTypeToEndpoint[item.type] || endpoint;
          } else {
            deleteEndpoint = endpoint;
          }
          await axios.delete(`${deleteEndpoint}${item.id}/`, auth);
          dispatch('fetchItems', { params: fetchParams });
          return true;
        } catch (error) {
          dispatch('sidebar/showWarning', {
            title: `Failed to delete ${camelCaseToText(dataType)}`,
            text: error.message,
          }, { root: true });
          return false;
        }
      },
      async deleteItemWithTask({ commit, dispatch, rootGetters }, { item, fetchParams = {} }) {
        // define callbacks
        const callbackFailed = (msg) => {
          dispatch('sidebar/showWarning', {
            title: 'Deletion failed',
            text: msg !== null ? msg : `${dataType} deletion task did not complete.`,
          }, { root: true });
          commit('setDeletingId', -1);
        };
        const callbackDone = () => {
          dispatch('templateStore/templateSendNotification', {
            title: 'Task finished',
            text: `${dataType} deletion task has finished successfully.`,
            variant: 'primary',
            toast: true,
          }, { root: true });
          dispatch('fetchItems', { params: fetchParams, refreshing: false });
          commit('setDeletingId', -1);
        };
        // schedule item for deletion
        const auth = rootGetters['auth/headerAuthorization'];
        try {
          commit('setDeletingId', item.id);
          const resp = await axios.delete(`${endpoint}${item.id}/`, auth);
          commit('task/addTask', {
            celeryId: resp.data.celery_id,
            callbackDone,
            callbackFailed,
          }, { root: true });
          return resp;
        } catch (error) {
          dispatch('sidebar/showWarning', {
            title: `Failed to delete ${camelCaseToText(dataType)}`,
            text: error.message,
          }, { root: true });
          return false;
        }
      },
      async patchItem({ dispatch, rootGetters }, item) {
        const auth = rootGetters['auth/headerAuthorization'];
        let obj;
        if (dataType === 'analyzer') {
          obj = {
            ...objToSnake(item),
            tag_mapping: tagMappingToSnake(item.tagMapping, true),
          };
        } else {
          obj = objToSnake(item);
        }
        const { ...data } = obj;
        if (dataType === 'statsConfiguration') {
          data.source = strToSnake(data.source);
        }
        try {
          let updateEndpoint;
          if (dataType === 'connector') {
            updateEndpoint = connectorTypeToEndpoint[item.type];
          } else {
            updateEndpoint = endpoint;
          }
          await axios.patch(`${updateEndpoint}${item.id}/`, data, auth);
          dispatch('fetchItemDetails', { id: item.id, refreshing: false, transcript: item.transcript === true });
          return true;
        } catch (error) {
          dispatch('sidebar/showWarning', {
            title: `Failed to update ${camelCaseToText(dataType)}`,
            text: error.message,
          }, { root: true });
          return false;
        }
      },
    };
  }
}
