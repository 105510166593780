<template>
  <div>
    <b-card
      :title="isEdit ? 'Edit Connector' : ''"
      class="r-75"
      :body-class="isEdit ? 'p3' : 'p-0'"
      :class="unsavedChanges && isEdit ? 'border-warning' : ''"
    >
      <b-form
        :class="isEdit ? 'mt-4' : ''"
        @submit.prevent
      >
        <edit-key-value
          class="mb-3"
          key-prop="Name"
          :value-prop="connectorForm.name"
          :min-key-width="isEdit ? editKeyWidth : createKeyWidth"
          type="input"
          :state="getFormState($v.connectorForm.name)"
          @input="(x) => connectorForm.name = x"
          @touch="$v.connectorForm.name.$touch()"
        >
          <template #feedback>
            <b-form-invalid-feedback
              v-if="!$v.connectorForm.name.required"
            >
              Name is required
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="!$v.connectorForm.name.maxLength"
            >
              The name is too long
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              v-if="!$v.connectorForm.name.isUnique"
            >
              This name is already taken
            </b-form-invalid-feedback>
          </template>
        </edit-key-value>

        <edit-key-value
          v-if="isEdit"
          class="my-3"
          key-prop="Description"
          :value-prop="connectorForm.description"
          :min-key-width="editKeyWidth"
          type="textarea"
          @input="(x) => connectorForm.description = x"
        />

        <edit-key-value
          class="my-3"
          key-prop="Type"
          :value-prop="connectorForm.type"
          :min-key-width="isEdit ? editKeyWidth : createKeyWidth"
          :disabled="isEdit"
          :options="formattedConnectorTypes"
          type="select"
          :state="getFormState($v.connectorForm.type)"
          @input="(x) => connectorForm.type = x"
          @touch="$v.connectorForm.type.$touch()"
        >
          <template #feedback>
            <b-form-invalid-feedback
              v-if="!$v.connectorForm.type.required"
            >
              Type is required
            </b-form-invalid-feedback>
          </template>
        </edit-key-value>

        <template v-for="obj in extraConnectorFields[connectorType]">
          <edit-key-value
            v-if="isEdit || !obj.editOnly"
            :key="obj.key"
            class="my-3"
            :key-prop="obj.label"
            :description="obj.description"
            :value-prop="connectorForm[obj.key]"
            :min-key-width="isEdit ? editKeyWidth : createKeyWidth"
            :type="obj.type"
            :options="formatOptions(obj.label, obj.options)"
            :state="getFormState($v.connectorForm[obj.key])"
            :disabled="isTestingConnectivity"
            @input="(x) => setNewValue(obj.key, x)"
            @touch="$v.connectorForm[obj.key].$touch()"
          >
            <template #feedback>
              <b-form-invalid-feedback
                v-if="$v.connectorForm[obj.key].required !== undefined
                  && !$v.connectorForm[obj.key].required"
              >
                This field cannot be empty
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                v-if="$v.connectorForm[obj.key].between !== undefined
                  && !$v.connectorForm[obj.key].between"
              >
                Value must be between 0 and 65535
              </b-form-invalid-feedback>
            </template>
          </edit-key-value>
        </template>
        <hr
          v-if="!isEdit"
          style="margin-left:-15px; margin-right:-15px;"
        >
        <div :class="isEdit ? 'text-left' : 'text-right'">
          <b-button
            v-if="!isEdit"
            class="mt-1 mr-2"
            @click="$emit('hide')"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            class="mt-1"
            :disabled="$v.connectorForm.$invalid || !unsavedChanges"
            @click="saveChanges"
          >
            {{ isEdit ? 'Update' : 'Create' }} Connector
          </b-button>
          <b-button
            v-if="connectorType !== connectorTypes.FILE_UPLOAD.value"
            class="mt-1 ml-1"
            variant="warning"
            :disabled="testConnectivityDisabled"
            @click="testConnectivity"
          >
            Test connectivity
            <b-spinner
              v-if="isTestingConnectivity"
              small
            />
          </b-button>
        </div>
        <span
          v-if="isEdit && unsavedChanges"
          class="unsaved-text"
        >
          *Unsaved changes
        </span>
      </b-form>
    </b-card>
    <b-card
      v-if="isEdit && connectorType === connectorTypes.FILE_UPLOAD.value"
      class="r-75 mt-4"
      :body-class="isEdit ? 'p3' : 'p-0'"
    >
      <div class="d-flex align-items-center">
        <h3>
          Upload data
        </h3>
      </div>
      <b-row class="mt-4">
        <b-col>
          <b-form-file
            id="new-src-file"
            v-model="newJsonFile"
            placeholder="Choose a file..."
            accept=".json"
          />
        </b-col>
        <b-col cols="2">
          <b-button
            class="custom-btn"
            variant="primary"
            :disabled="!Boolean(newJsonFile)"
            @click="uploadFile"
          >
            Upload File
          </b-button>
          <b-button
            id="format-info-button"
            v-b-tooltip.noninteractive.bottom
            v-b-modal.upload-format-modal
            variant="info"
            title="Show file format info"
            class="custom-btn ml-1"
          >
            <font-awesome-icon
              size="lg"
              icon="question-circle"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-table
        class="mt-2 mb-0"
        show-empty
        :items="uploadedFiles"
        :fields="fileFields"
        empty-text="There are no files to show"
      >
        <template #cell(actions)="data">
          <b-button
            v-b-tooltip.hover.noninteractive.viewport
            title="Delete file"
            @click="deleteFile(data)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-button>
        </template>
      </b-table>
      <b-modal
        id="upload-format-modal"
        title="File Upload Format"
        hide-footer
      >
        <span>
          The uploaded files should be json files containing the following content structure:
          <pre><code>[
    {
      "start_timestamp": "2024-01-01T12:00:00Z",
      "end_timestamp": "2024-01-01T12:01:30Z",
      "agent": "Some agent identifier",
      "department": "Some department",
      "phrases": [
        {
          "content": "Example message",
          "speaker": "agent",
          "timestamp": "2024-01-01T12:00:00Z"
        },
        {
          "content": "Example message",
          "speaker": "visitor",
          "timestamp": "2024-01-01T12:00:45Z"
        },
        ...
      ]
    },
    ...
  ]</code></pre>
        </span>
      </b-modal>
    </b-card>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import {
  required, maxLength, requiredIf, between,
} from 'vuelidate/lib/validators';
import { mapActions, mapGetters, mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import axios from 'axios';
import Vue from 'vue';
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';
import { connectorTypes } from '@/js/constants';
import endpoints from '@/js/endpoints';
import extraConnectorFields from '@/js/extraConnectorFields';

export default {
  name: 'ConnectorForm',
  components: {
    EditKeyValue,
  },
  mixins: [validationMixin],
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    extraConnectorFields,
    editKeyWidth: 170,
    createKeyWidth: 145,
    connectorTypes,
    connectorForm: null,
    showCredentials: false,
    isTestingConnectivity: false,
    newJsonFile: null,
    fileFields: ['name',
      {
        key: 'actions', label: 'Delete', tdClass: 'actions', thClass: 'actions',
      },
    ],
  }),
  computed: {
    ...mapState('connector', { connectors: 'items' }),
    ...mapState('connector', { connectorDetails: 'details' }),
    ...mapGetters('auth', ['headerAuthorization']),
    unsavedChanges() {
      const connectorCopy = cloneDeep(this.connectorForm);
      delete connectorCopy.credentials;
      delete connectorCopy.password;
      delete connectorCopy.apiPassword;
      return JSON.stringify(connectorCopy) !== JSON.stringify(this.connectorDetails)
      || (this.connectorForm.credentials && this.connectorForm.credentials.length !== 0)
      || (this.connectorForm.password && this.connectorForm.password.length !== 0)
      || (this.connectorForm.apiPassword && this.connectorForm.apiPassword.length !== 0);
    },
    formattedConnectorTypes() {
      return [{ value: null, text: 'Choose connector type', disabled: true }].concat(Object.values(connectorTypes));
    },
    testConnectivityDisabled() {
      return this.$v.connectorForm.$invalid || this.isTestingConnectivity;
    },
    fields() {
      return this.extraConnectorFields[this.connectorForm.type];
    },
    connectorType() {
      return this.connectorForm?.type;
    },
    uploadedFiles() {
      return this.connectorForm?.files || [];
    },
  },
  watch: {
    connectorType(n) {
      if (!this.isEdit && this.extraConnectorFields[n]) {
        const copy = {};
        copy.name = this.connectorForm.name;
        copy.description = this.connectorForm.description;
        copy.type = this.connectorForm.type;
        this.extraConnectorFields[n].forEach((e) => {
          Vue.set(copy, e.key, '');
        });
        this.connectorForm = copy;
        this.$v.connectorForm.$reset();
      }
    },
  },
  created() {
    this.connectorForm = this.isEdit
      ? cloneDeep(this.connectorDetails) : this.connectorTemplate();
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    ...mapActions('connector', { addConnector: 'addItem', updateConnector: 'patchItem' }),
    async saveChanges() {
      if (this.isEdit) {
        if (this.connectorForm?.credentials?.length === 0) {
          delete this.connectorForm.credentials;
        }
        this.updateConnector(this.connectorForm);
      } else if (await this.addConnector({ newItem: this.connectorForm })) {
        this.$nextTick(() => {
          this.$emit('hide');
        });
      }
    },
    async uploadFile() {
      const request = { ...this.headerAuthorization };
      const formData = new FormData();
      formData.append('datasource_id', this.connectorForm.id);
      formData.append('json_file', this.newJsonFile);
      axios.post(endpoints.jsonCallUpload, formData, request)
        .then(() => {
          this.newJsonFile = null;
          this.updateConnector(this.connectorForm);
        })
        .catch((error) => {
          this.showWarning({ title: 'Uploading file failed', text: error });
        });
    },
    async deleteFile(file) {
      if (await this.$bvModal.msgBoxConfirm('Are you sure you want to delete the file?', {
        title: 'Delete file',
        okTitle: 'Delete',
        okVariant: 'danger',
      })) {
        const fileId = file.item.uuid;
        const request = { ...this.headerAuthorization };
        axios.delete(`${endpoints.jsonCallUpload}${fileId}/`, request)
          .then(() => {
            this.updateConnector(this.connectorForm);
          })
          .catch((error) => {
            this.showWarning({ title: 'Deleting file failed', text: error });
          });
      }
    },
    formatOptions(name, options) {
      if (!options) {
        return null;
      }
      return [{ value: null, text: `Choose ${name.toLowerCase()}`, disabled: true }].concat(Object.values(options));
    },
    connectorTemplate() {
      return {
        name: '',
        description: '',
        type: null,
      };
    },
    getFormState(item) {
      if (!item.$dirty) {
        return null;
      } if (item.$invalid) {
        return false;
      }
      return true;
    },
    setNewValue(key, value) {
      Vue.set(this.connectorForm, key, value);
    },
    showConnectivityResult(data) {
      if (data.success) {
        this.showWarning({ title: 'Connectivity test succeeded', text: 'Endpoint and credentials verified', variant: 'success' });
        return;
      }
      this.showWarning({ title: 'Connectivity test failed', text: data.error });
    },
    async testConnectivity() {
      try {
        this.isTestingConnectivity = true;
        const request = { ...this.headerAuthorization };
        let existing;
        let endpoint;
        if (this.connectorForm.type === connectorTypes.ZENDESK.value) {
          endpoint = endpoints.dataSourceZendesk;
          existing = this.connectorDetails && !this.connectorForm.credentials;
          if (!existing) {
            request.data = {
              credentials: this.connectorForm.credentials,
              endpoint_url: this.connectorForm.endpointUrl,
            };
          }
        } else if (this.connectorForm.type === connectorTypes.PUZZEL.value) {
          endpoint = endpoints.dataSourcePuzzel;
          existing = this.connectorDetails && !this.connectorForm.password
              && !this.connectorForm.apiPassword;
          if (!existing) {
            request.data = {
              username: this.connectorForm.username,
              password: this.connectorForm.password,
              port: this.connectorForm.port,
              endpoint_url: this.connectorForm.endpointUrl,
              recordings_path: this.connectorForm.recordingsPath,
              metadata_path: this.connectorForm.metadataPath,
              audio_config: this.connectorForm.audioConfig,
              customer_key: this.connectorForm.customerKey,
              api_username: this.connectorForm.apiUsername,
              api_password: this.connectorForm.apiPassword,
            };
          }
        } else if (this.connectorForm.type === connectorTypes.TRIO.value) {
          endpoint = endpoints.dataSourceTrio;
          existing = this.connectorDetails && !this.connectorForm.password;
          if (!existing) {
            request.data = {
              username: this.connectorForm.username,
              password: this.connectorForm.password,
              port: this.connectorForm.port,
              endpoint_url: this.connectorForm.endpointUrl,
              recordings_path: this.connectorForm.recordingsPath,
              metadata_path: this.connectorForm.metadataPath,
              audio_config: this.connectorForm.audioConfig,
              api_username: this.connectorForm.apiUsername,
              api_password: this.connectorForm.apiPassword,
              api_key: this.connectorForm.apiKey,
              host: this.connectorForm.host,
            };
          }
        } else {
          throw new Error(`Connectivity type: ${this.connectorForm.type} not supported for testing.`);
        }
        if (existing) {
          request.method = 'GET';
          request.params = { endpoint_url: this.connectorForm.endpointUrl };
          request.url = `${endpoint}${this.connectorDetails.id}/test_connectivity/`;
        } else {
          request.method = 'POST';
          request.url = `${endpoint}test_new_connectivity/`;
        }
        const { data } = await axios(request);
        this.showConnectivityResult(data);
        this.isTestingConnectivity = false;
      } catch (error) {
        this.showWarning({
          title: 'Failed to test connectivity',
          text: error.message,
        });
        this.isTestingConnectivity = false;
      }
    },
  },
  validations() {
    return {
      connectorForm: {
        name: {
          required,
          maxLength: maxLength(120),
          isUnique(value) {
            return !Object.values(this.connectors).map(
              (connector) => connector.name,
            ).includes(value) || value === this.connectorDetails?.name;
          },
        },
        type: {
          required,
        },
        endpointUrl: {
          required: requiredIf(() => !this.isEdit
            && this.connectorForm.type !== connectorTypes.FILE_UPLOAD.value),
        },
        credentials: {
          required: requiredIf(() => !this.isEdit
            && this.connectorForm.type === connectorTypes.ZENDESK.value),
        },
        port: {
          required: requiredIf(() => [connectorTypes.PUZZEL.value, connectorTypes.TRIO.value]
            .includes(this.connectorForm.type)),
          between: between(0, 65535),
        },
        username: {
          required: requiredIf(() => [connectorTypes.PUZZEL.value, connectorTypes.TRIO.value]
            .includes(this.connectorForm.type)),
        },
        password: {
          required: requiredIf(() => !this.isEdit
            && [connectorTypes.PUZZEL.value, connectorTypes.TRIO.value]
              .includes(this.connectorForm.type)),
        },
        customerKey: {
          required: requiredIf(() => this.connectorForm.type === connectorTypes.PUZZEL.value),
        },
        apiUsername: {
          required: requiredIf(() => [connectorTypes.PUZZEL, connectorTypes.TRIO]
            .includes(this.connectorForm.type)),
        },
        apiPassword: {
          required: requiredIf(() => !this.isEdit
          && [connectorTypes.PUZZEL, connectorTypes.TRIO].includes(this.connectorForm.type)),
        },
        apiKey: {
          required: requiredIf(() => !this.isEdit
          && this.connectorForm.type === connectorTypes.TRIO.value),
        },
        host: {
          required: requiredIf(() => this.connectorForm.type === connectorTypes.TRIO.value),
        },
        recordingsPath: {},
        metadataPath: {},
        audioConfig: {
          required: requiredIf(() => [connectorTypes.PUZZEL.value, connectorTypes.TRIO.value]
            .includes(this.connectorForm.type)),
        },
      },
    };
  },
};
</script>

<style>
.custom-btn{
  padding-top: 5px;
  padding-bottom: 4px;
}
</style>
