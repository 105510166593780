<template>
  <div class="mb-2">
    <div class="border-bottom d-flex align-items-center">
      <b-button
        v-b-tooltip
        :title="title"
        variant="muted"
        :size="size"
        class="text-secondary text-truncate text-left
          collapsible-section-btn flex-fill shadow-none font-weight-normal"
        @click="toggleVisible"
      >
        <font-awesome-icon
          :icon="visible ? 'minus' : 'plus'"
          class="mr-3"
        />
        {{ title }}
        <slot name="title-append" />
      </b-button>
      <slot name="menu" />
    </div>
    <b-collapse v-model="visible">
      <slot name="content" />
    </b-collapse>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'lg',
    },
    startVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['visible'],
  data() {
    return {
      visible: false,
    };
  },
  created() {
    if (this.startVisible && !this.visible) this.toggleVisible();
  },
  methods: {
    toggleVisible() {
      this.visible = !this.visible;
      this.$emit('visible', this.visible);
    },
  },
};
</script>

<style scoped>
.collapsible-section-btn svg {
  opacity: .25;
}
</style>
