<template>
  <b-card
    title="Call details"
    class="r-50"
  >
    <CollapsibleSection
      v-for="{ title, fields } in detailsStructure"
      :key="title"
      :start-visible="true"
      :title="title"
    >
      <template #content>
        <b-list-group class="mt-2">
          <MetaField
            v-for="field in fields"
            :key="field.key"
            v-bind="{ field }"
          />
        </b-list-group>
      </template>
    </CollapsibleSection>
  </b-card>
</template>

<script>
import { mapState } from 'vuex';
import { formatNumber } from 'supwiz/util/formatters';
import { camelCaseToText } from '@/js/utils';
import CollapsibleSection from './CollapsibleSection.vue';
import MetaField from './MetaField.vue';

export default {
  name: 'DetailsCard',
  components: { CollapsibleSection, MetaField },
  props: {
    callDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('analyzer', { analyzerDetails: 'details' }),
    detailsStructure() {
      const sections = [
        {
          title: 'Information',
          fields: [
            'id',
            'analyzer',
            'externalId',
            'callEnded',
            'agentId',
            'departmentId',
            'duration',
            'status',
            // 'transcriptSpeakers',
            'tag',
            'activities',
            'customVisitorSpeakerId',
            'transcribeDuration',
            'categories',
          ],
        },
        {
          title: 'Conversation score',
          fields: [
            // 'conversationScore', // TODO
            'durationScore',
            'sentimentScore',
            'scoreCategories',
            'conversationScore', // finalScore
          ],
        },
        {
          title: 'External Link',
          fields: ['externalUrl'],
        },
      ];
      if (this.analyzerDetails.classification || this.analyzerDetails.sentimentAnalysis) {
        sections.splice(1, 0, {
          title: 'Evaluations',
          fields: [],
        });
      }
      if (this.analyzerDetails.classification) {
        sections[1].fields.splice(1, 0, 'prediction');
        sections[1].fields.splice(2, 0, 'subprediction');
      }
      if (this.analyzerDetails.sentimentAnalysis) {
        sections[1].fields.splice(1, 0, 'overallSentiment');
      }
      return sections.map(({ title, fields }) => {
        if (fields.length < 1) {
          return {};
        }
        return {
          title,
          fields: fields.map(this.getMetaFieldObj).filter((field) => field !== undefined),
        };
      });
    },
  },
  methods: {
    formatScoreComputation(value) {
      if (value === null) {
        return '-';
      }
      const { score, rawScore, normalizedImportance } = value;
      return `${formatNumber(score)} (${formatNumber(rawScore)} × ${formatNumber(normalizedImportance)}%)`;
    },
    formatDate(value) {
      try {
        const parsed = new Date(value);
        return parsed.toLocaleString();
      } catch {
        return value;
      }
    },
    formatTime(value) {
      try {
        const minutes = Math.floor(value / 60);
        const seconds = Math.floor(value - (minutes * 60));
        const miliseconds = Math.floor((value - (minutes * 60) - seconds) * 100);
        return `${minutes}:${seconds}.${miliseconds}`;
      } catch {
        return value;
      }
    },
    formatTagging(tag, callDetails) {
      return {
        main: tag,
        sub: callDetails.subtag,
      };
    },
    getMetaFieldObj(keyName) {
      const details = this.callDetails;
      const keyObjRaw = details[keyName];
      return this.prepareKvObj(keyName, keyObjRaw, details);
    },
    prepareKvObj(key, value, callDetails) {
      /* expected output, * indicates optional
      {
        stack: Boolean,
        hideKey: Boolean,
        keyFormatted: String*,
        key: String,
        negative: Boolean*,
        value: Boolean, Number, String, Object, Array
      }
      */
      const result = { key, value };
      result.keyFormatted = camelCaseToText(key);
      switch (key) {
        case 'id':
          result.keyFormatted = 'Call ID';
          break;
        case 'analyzer':
          result.value = this.analyzerDetails?.name || value;
          break;
        case 'externalUrl':
          result.stack = true;
          result.hideKey = true;
          result.keyFormatted = 'External URL';
          break;
        case 'callEnded':
          result.value = this.formatDate(value);
          break;
        case 'duration':
          result.value = this.formatTime(value);
          break;
        case 'tag':
          result.value = this.formatTagging(value, callDetails);
          break;
        case 'prediction':
          result.stack = true;
          // results will be shown in a predictions component
          break;
        case 'subprediction':
          result.stack = true;
          // results will be shown in a predictions component
          break;
        case 'transcribeDuration':
          if (value === -1) {
            result.value = 'Full transcript';
          } else if (value > 0) {
            result.value = `Max ${formatNumber(value)} seconds`;
          } else result.value = '';
          break;
        case 'overallSentiment': {
          const transcriptSentiment = this.callDetails.sentimentPrediction.transcriptSentiment;
          result.value = transcriptSentiment ? Object.keys(transcriptSentiment)
            .reduce((a, b) => (transcriptSentiment[a] > transcriptSentiment[b] ? a : b))
            : '';
          break;
        }
        case 'sentimentScore': {
          result.value = this.formatScoreComputation(this.callDetails.sentimentScore);
          break;
        }
        case 'durationScore': {
          result.value = this.formatScoreComputation(this.callDetails.durationScore);
          break;
        }
        case 'scoreCategories': {
          result.value = Object.entries(value).map(([k, v]) => (
            {
              key: k,
              value:
              {
                score: formatNumber(v.score),
                rawScore: formatNumber(v.rawScore),
                normalizedImportance: formatNumber(v.normalizedImportance),
              },
            }
          ));
          result.key = 'categoriesScore';
          result.keyFormatted = 'Categories score';
          break;
        }
        case 'categories': {
          if (!value) {
            result.value = [];
          } else {
            result.value = Object.values(value);
          }
          break;
        }
        case 'conversationScore':
          if (!value) {
            result.value = '-';
          } else {
            result.value = formatNumber(value);
          }
          result.key = 'finalScore';
          result.keyFormatted = 'Final score';
          break;
        default:
          if (value === null) {
            result.value = '';
          } else if (typeof value === 'object') {
            if (Array.isArray(value)) {
              if (!value.length) result.value = '';
            } else if (!Object.keys(value)?.length) {
              result.value = '';
            }
          } else if (typeof value === 'number') {
            result.value = formatNumber(value);
          }
          break;
      }
      return result;
    },
  },
};
</script>
