<template>
  <section>
    <h3>Transcript Handling</h3>
    <b-card no-body>
      <p class="mb-0">
        After synchronization and transcription, the transcript is processed by the system.
        These settings define how the analyzer processes call transcripts.
      </p>
    </b-card>
    <hr>

    <edit-key-value
      class="mb-3"
      description="Choose whether to analyze the sentiment (positive, neutral, negative) of the
        call"
      key-prop="Sentiment Analysis"
      :value-prop="analyzerForm.sentimentAnalysis"
      :min-key-width="keyWidth"
      type="checkbox"
      @input="(x)=>toggleSentiment(x)"
    />
    <small v-if="sentimentAnalysisWarning" class="text-danger">
      Make sure the sentiment importance in conversation score is
      set to 0% before turning off sentiment analysis.
    </small>
    <b-collapse
      v-model="analyzerForm.sentimentAnalysis"
    >
      <hr>
      <h4>Neutral sentences</h4>
      <p>
        Here you can add sentences that you want the sentiment analysis to consider neutral.
        This can be helpful to stop short answers like 'yes', 'no' or stopwords from influencing the
        sentiment too much. Sentences are case insensitive and punctuation is ignored.
      </p>
      <string-list
        class="mb-3"
        :strings="neutralSentences"
        placeholder="Add sentence with neutral sentiment"
        @change="setNeutralSentences"
      />
      <hr>
    </b-collapse>
    <edit-key-value
      v-if="openAiEnabled()"
      class="mb-3"
      description="Choose whether to summarize calls"
      key-prop="Summarization"
      :value-prop="analyzerForm.summarization"
      :min-key-width="keyWidth"
      type="checkbox"
      @input="(x)=>toggleSummarization(x)"
    />
    <b-collapse
      v-model="analyzerForm.summarization"
    >
      <edit-key-value
        v-if="openAiEnabled()"
        class="mb-3"
        description="Choose the minimum call duration for which to generate a summary"
        key-prop="Summary threshold"
        :value-prop="analyzerForm.summarizationThreshold"
        :min-key-width="keyWidth"
        type="number"
        @input="(x)=>analyzerForm.summarizationThreshold = x"
      />
    </b-collapse>
    <edit-key-value
      class="my-3"
      description="Choose whether predict the intent of the call"
      key-prop="Classification"
      :value-prop="analyzerForm.classification"
      :min-key-width="keyWidth"
      type="checkbox"
      @input="(x)=>toggleClassification(x)"
    />
    <b-collapse
      v-model="analyzerForm.classification"
    >
      <hr>
      <h4>Top level classification</h4>
      <b-overlay
        :show="isFetchingClassifiers"
      >
        <edit-key-value
          key-prop="Classifier"
          description="Choose the classifier model used for predicting the intent of the call"
          :value-prop="selectedClassifier"
          :min-key-width="keyWidth"
          :options="classifierOptions"
          type="select"
          :disabled="isFetchingClassifiers || isFetchingLabels"
          :state="getFormState($v.selectedClassifier.$invalid,
                               $v.selectedClassifier.$dirty)"
          @input="(x)=>selectClassifier(x)"
          @touch="touchClassifier"
        >
          <template #feedback>
            <b-form-invalid-feedback
              v-if="!$v.selectedClassifier.required"
            >
              Classifier required
            </b-form-invalid-feedback>
          </template>
        </edit-key-value>
      </b-overlay>
      <edit-key-value
        v-if="selectedClassifier !== null"
        class="my-3"
        key-prop="Classifier version"
        description="Choose the version of the selected classifier"
        :value-prop="analyzerForm.clfId"
        :min-key-width="keyWidth"
        :options="versionOptions"
        type="select"
        :state="getFormState($v.analyzerForm.clfId.$invalid,
                             $v.analyzerForm.clfId.$dirty)"
        @input="(x)=>analyzerForm.clfId = x"
        @touch="touchClassifier"
      >
        <template #feedback>
          <b-form-invalid-feedback
            v-if="!$v.analyzerForm.clfId.required"
          >
            Classifier version required
          </b-form-invalid-feedback>
        </template>
      </edit-key-value>
      <edit-key-value
        class="my-3"
        key-prop="Classification strategy"
        description="The classifier can be applied to different parts of the transcript.
          Choose whether to apply the classifier to the full transcript or alternative strategies."
        :value-prop="analyzerForm.clfStrategy"
        :min-key-width="keyWidth"
        :options="predictionStrategyTypes"
        type="select"
        @input="(x)=>analyzerForm.clfStrategy = x"
      >
        <template #feedback>
          <b-form-invalid-feedback
            v-if="!$v.analyzerForm.clfId.required"
          >
            Classifier version required
          </b-form-invalid-feedback>
        </template>
      </edit-key-value>
      <hr>
      <h4>Tags & tag classification</h4>
      <b-row>
        <b-col cols="5">
          <div class="border r-25 p-3">
            <b-row class="mb-2">
              <b-col>
                <b-form-input v-model="searchKeyword" placeholder="Type to search" />
              </b-col>
            </b-row>
            <b-list-group style="max-height: 350px; overflow-y: auto;" class="border">
              <b-list-group-item
                v-for="(tag, index) of tagItems()"
                :key="index"
                button
                :active="activeTagClass === tag.class"
                class="d-flex justify-content-between align-items-start"
                @click="setActiveTag(tag)"
              >
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  class="mb-1 action-button p-0 mr-3"
                  @click.stop="deleteTag(tag)"
                >
                  <font-awesome-icon
                    icon="trash-alt"
                    style="transform:scale(1.6) translateY(0px)"
                  />
                </b-button>
                <span style="flex: 1;">
                  {{ tag.label }} {{ tag.tag ? `(${tag.tag})` : '' }}
                </span>
                <font-awesome-icon
                  :icon="tag.reachable ? 'circle-check' : 'circle-xmark'"
                  :color="tag.reachable ? 'green' : 'red'"
                />
              </b-list-group-item>
            </b-list-group>
            <b-row>
              <b-col class="pr-1">
                <b-button
                  v-if="addTagEnabled"
                  block
                  size="sm"
                  class="my-1"
                  variant="primary"
                  @click="populateTagMapping()"
                >
                  Add all tags
                </b-button>
              </b-col>
              <b-col class="pl-1">
                <b-button
                  v-if="addTagEnabled"
                  variant="primary"
                  class="my-1"
                  size="sm"
                  block
                  @click="showAddTagModal()"
                >
                  Add tag
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col class="pl-0">
          <div class="border r-25 p-3">
            <template v-if="activeTag">
              <b-row>
                <b-col>
                  <h5>Label: <strong>{{ activeTag.label }}</strong></h5>
                </b-col>
                <b-col cols="auto">
                  <font-awesome-icon
                    :icon="activeTag.reachable ? 'circle-check' : 'circle-xmark'"
                    :color="activeTag.reachable ? 'green' : 'red'"
                  />
                </b-col>
              </b-row>
              <edit-key-value
                class="mb-3"
                description=""
                key-prop="State"
                :value-prop="activeTag.state"
                :min-key-width="nestedKeyWidth"
                type="checkbox"
                @input="()=>toggleDisableTag(activeTag.class)"
              />
              <edit-key-value
                class="mb-3"
                description=""
                key-prop="Tag"
                :value-prop="activeTag.tag"
                :min-key-width="nestedKeyWidth"
                :state="!$v.activeTag.$invalid"
                type="input"
                @input="(v)=>updateTag(activeTag, v)"
              />
              <edit-key-value
                key-prop="Classifier"
                description="Choose the classifier model used for predicting the intent of the call"
                :value-prop="selectedSubClassifier"
                class="my-3"
                :min-key-width="nestedKeyWidth"
                :options="classifierOptions"
                type="select"
                :disabled="isFetchingClassifiers || isFetchingLabels"
                @input="(x)=>selectSubClassifier(x)"
              />
              <edit-key-value
                v-if="selectedSubClassifier !== null"
                class="my-3"
                key-prop="Version"
                description="Choose the version of the selected classifier"
                :value-prop="activeTag.clfId"
                :min-key-width="nestedKeyWidth"
                :options="subClassifierVersionOptions"
                type="select"
                @input="(x)=>selectSubClassifierVersion(x)"
              />
              <div
                v-b-popover.hover.top="'Select the tag that should be sent back to the connected system'
                  + ' for a given predicted class/label. Only tags listed in this table will be sent back.'"
                class="d-block bg-primary text-center text-white table-label py-1"
                :style="`width: ${nestedKeyWidth}px;`"
              >
                Label tags
              </div>
              <table-data
                small
                sticky-header
                :fields="tagFields"
                :items="tagItems(activeTag)"
                show-empty
                empty-text="There are no tags to show"
                @delete="deleteSubTag"
              >
                <template #head(state)="data">
                  <span v-b-popover.hover.right="data.field.description">
                    {{ data.label }}
                  </span>
                </template>
                <template #head(reachable)="data">
                  <span v-b-popover.hover.right="data.field.description">
                    {{ data.label }}
                  </span>
                </template>
                <template #head(tag)="data">
                  <span v-b-popover.hover.right="data.field.description">
                    {{ data.label }}
                  </span>
                </template>
                <template #head(class)="data">
                  <span v-b-popover.hover.right="data.field.description">
                    {{ data.label }}
                  </span>
                </template>
                <template #cell(tag)="data">
                  <b-input
                    size="sm"
                    :value="data.item.tag"
                    :placeholder="data.item.label"
                    :state="getNestedTagState(data.item.tag)"
                    @input="(x)=>updateSubTag(data.item, x)"
                  />
                  <b-form-invalid-feedback>
                    Tags must contain only alphanumeric characters, underscores, and dashes.
                  </b-form-invalid-feedback>
                </template>
                <template #cell(state)="data">
                  <b-form-checkbox
                    v-model="data.item.state"
                    switch
                    @change="toggleDisableSubTag(data.item.class)"
                  >
                    {{ data.item.state ? 'Enabled' : 'Disabled' }}
                  </b-form-checkbox>
                </template>
                <template #cell(reachable)="data">
                  <b-spinner
                    v-if="isFetchingClassifiers"
                    small
                  />
                  <font-awesome-icon
                    v-else-if="data.item.reachable"
                    icon="check"
                    style="color:green"
                  />
                  <font-awesome-icon
                    v-else
                    icon="times-circle"
                    style="color:red"
                  />
                </template>
              </table-data>
              <b-button
                v-if="nestedAddTagEnabled"
                size="sm"
                class="my-1 mr-2"
                variant="primary"
                @click="populateNestedTagMapping()"
              >
                Add all tags
              </b-button>
              <b-button
                v-if="nestedAddTagEnabled"
                variant="primary"
                class="my-1"
                size="sm"
                @click="showAddTagModal(true)"
              >
                Add tag
              </b-button>
            </template>
            <span v-else class="text-center d-block"> Select tag to update</span>
          </div>
        </b-col>
      </b-row>
    </b-collapse>
    <b-modal
      id="add-tag-modal"
      title="Add tag"
      :ok-disabled="$v.newTag.$invalid"
      centered
      @ok="addTag"
      @hide="clearTag"
    >
      <b-form-group label="Tag class">
        <b-form-select
          :options="addSubTag ? nestedTagClassOptions : tagClassOptions"
          :state="getFormState($v.newTag.class.$invalid,
                               $v.newTag.class.$dirty)"
          @input="(x) => {
            newTag.label = x.label;
            newTag.class = x.name;
            $v.newTag.class.$touch()
          }"
        />
        <b-form-invalid-feedback v-if="!$v.newTag.class.required">
          Class is required
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Enter tag">
        <b-input
          v-model="newTag.tag"
          :placeholder="newTag.label"
          :state="getFormState($v.newTag.tag.$invalid,
                               $v.newTag.tag.$dirty)"
          @input="$v.newTag.tag.$touch()"
        />
        <b-form-invalid-feedback
          v-if="!$v.newTag.tag.zendeskTagValidation"
        >
          Tags must contain only alphanumeric characters, underscores, and dashes.
        </b-form-invalid-feedback>
      </b-form-group>
    </b-modal>
    <template v-if="supportsPushing">
      <hr>
      <h4>
        Connector integration
      </h4>
      <edit-key-value
        v-if="supportsPushTranscripts"
        class="mb-3"
        description="Choose whether transcripts should be sent back to the connected system"
        key-prop="Add transcripts"
        :value-prop="analyzerForm.addTranscript"
        :min-key-width="keyWidth"
        type="checkbox"
        @input="(x)=>analyzerForm.addTranscript = x"
      />
      <b-collapse
        v-model="analyzerForm.classification"
      >
        <edit-key-value
          v-if="supportsPushTags && analyzerForm.classification"
          class="mb-3"
          key-prop="Add tags"
          description="Choose whether tags should be sent back to the connected system"
          :value-prop="analyzerForm.addTag"
          :min-key-width="keyWidth"
          type="checkbox"
          @input="(x)=>analyzerForm.addTag = x"
        />
      </b-collapse>
      <b-collapse
        v-model="analyzerForm.summarization"
      >
        <edit-key-value
          v-if="supportsPushSummaries && analyzerForm.summarization && openAiEnabled()"
          class="mb-3"
          description="Choose whether summaries should be sent back to the connected system"
          key-prop="Add summaries"
          :value-prop="analyzerForm.addSummary"
          :min-key-width="keyWidth"
          type="checkbox"
          @input="(x)=>analyzerForm.addSummary = x"
        />
      </b-collapse>
    </template>
    <b-button
      variant="primary"
      class="mt-2 d-block"
      :style="`width: ${keyWidth}px;`"
      :disabled="$v.analyzerForm.$invalid || !unsavedChanges"
      @click="updateAnalyzer(analyzerForm)"
    >
      Save Changes
    </b-button>
    <span
      v-if="unsavedChanges"
      class="unsaved-text"
    >
      *Unsaved changes
    </span>
  </section>
</template>
<script>
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';
import StringList from 'supwiz/components/StringList.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import axios from 'axios';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import TableData from 'supwiz/components/TableData.vue';
import Vue from 'vue';
import endpoints from '@/js/endpoints';
import { predictionStrategyTypes, featureSupport } from '@/js/constants';
import { openAiEnabled } from '@/js/featureFlags';
import update from 'immutability-helper';

const zendeskTagValidation = (value) => {
  const regex = /^[a-zA-Z0-9_-]+$/g;
  return regex.test(value) || !value.length;
};
export default {
  name: 'Synchronization',
  components: {
    EditKeyValue, TableData, StringList,
  },
  mixins: [validationMixin],
  data() {
    return {
      analyzerForm: null,
      predictionStrategyTypes,
      classifiers: null,
      selectedClassifier: null,
      labels: null,
      selectedSubClassifier: null,
      subClassifierLabels: null,
      isFetchingClassifiers: false,
      isFetchingLabels: false,
      newTag: this.tagTemplate(),
      tagFields: [
        {
          key: 'delete', label: '', tdClass: 'delete-column',
        },
        { key: 'label', label: 'Label', description: 'The label chosen by the classifier' },
        { key: 'tag', label: 'Tag', description: 'The tag that is sent back to the connected system' },
        {
          key: 'state', label: 'State', tdClass: 'state-col', description: 'Whether the tag should be sent back to the connected system',
        },
        {
          key: 'reachable',
          label: 'Reachable',
          tdClass: 'state-col',
          description: 'Whether the selected classifier version can output the class, i.e. if the class is one of the classifier\'s labels',
        },
      ],
      sentimentAnalysisWarning: false,
      searchKeyword: '',
      activeTagClass: null,
      addSubTag: false,
    };
  },
  computed: {
    ...mapState('analyzer', { analyzerDetails: 'details' }),
    ...mapGetters('auth', ['headerAuthorization']),
    ...mapGetters('connector', ['typeFromId']),
    activeTag() {
      return this.tagItems().find((e) => e.class === this.activeTagClass);
    },
    connectorType() {
      return this.typeFromId(this.analyzerDetails.dataSource);
    },
    supportsPushTranscripts() {
      return featureSupport.pushTranscripts.map((e) => e.value).includes(this.connectorType);
    },
    supportsPushTags() {
      return featureSupport.pushTags.map((e) => e.value).includes(this.connectorType);
    },
    supportsPushSummaries() {
      return featureSupport.pushSummaries.map((e) => e.value).includes(this.connectorType);
    },
    supportsPushing() {
      return this.supportsPushTranscripts || this.supportsPushTags || this.supportsPushSummaries;
    },
    keyWidth() {
      return 220;
    },
    nestedKeyWidth() {
      return 150;
    },
    unsavedChanges() {
      return JSON.stringify(this.analyzerForm) !== JSON.stringify(this.analyzerDetails);
    },
    neutralSentences() {
      return this.analyzerForm.neutralSentences;
    },
    isDemoClassifier() {
      return this.analyzerDetails.clfType === 'demo';
    },
    demoClassifierOptions() {
      const demoOptions = [
        { value: this.analyzerDetails.clfId, text: this.analyzerDetails.clfId },
      ];
      Object.values(this.analyzerDetails.tagMapping).forEach((value) => {
        if (value.clfType === 'demo') {
          demoOptions.push({ value: value.clfId, text: value.clfId });
        }
      });
      return demoOptions;
    },
    classifierOptions() {
      if (this.isDemoClassifier) return this.demoClassifierOptions;
      if (this.classifiers === null) {
        return [];
      }
      return Object.entries(this.classifiers)
        .map(([key, c]) => ({ value: key, text: c.name }));
    },
    tagClassOptions() {
      const start = [{ value: null, text: 'Choose class', disabled: true }];
      if (this.classifiers && this.labels !== null) {
        if (!this.selectedClassifierVersion) {
          return start;
        }
        const options = this.labels
          .filter((v) => this.analyzerForm.tagMapping[v.name] === undefined)
          .map((v) => ({ value: { label: v.displayName, name: v.name }, text: v.displayName }));
        return start.concat(options);
      }
      return start;
    },
    nestedTagClassOptions() {
      const start = [{ value: null, text: 'Choose class', disabled: true }];
      if (this.classifiers && this.subClassifierLabels !== null) {
        if (!this.selectedSubClassifierVersion) {
          return start;
        }
        const options = this.subClassifierLabels
          .filter((v) => this.analyzerForm.tagMapping[this.activeTag.class]
            .tagMapping[v.name] === undefined)
          .map((v) => ({ value: { label: v.displayName, name: v.name }, text: v.displayName }));
        return start.concat(options);
      }
      return start;
    },
    addTagEnabled() {
      return this.tagClassOptions.length > 1;
    },
    nestedAddTagEnabled() {
      return this.nestedTagClassOptions.length > 1;
    },
    unreachableTags() {
      if (this.isFetchingClassifiers || !this.analyzerForm.tagMapping) {
        return new Set();
      }
      const tagLabels = new Set(Object.keys(this.analyzerForm.tagMapping));
      if (!this.selectedClassifierVersion) {
        return tagLabels;
      }
      const clfLabels = new Set(this.selectedClassifierVersion.labels);
      return new Set([...tagLabels].filter((x) => !clfLabels.has(x)));
    },
    unreachableSubTags() {
      if (this.isFetchingClassifiers
      || !this.analyzerForm.tagMapping[this.activeTag.class]?.tagMapping) {
        return new Set();
      }
      const tagLabels = new Set(
        Object.keys(this.analyzerForm.tagMapping[this.activeTag.class].tagMapping),
      );
      if (!this.selectedSubClassifierVersion) {
        return tagLabels;
      }
      const clfLabels = new Set(this.selectedSubClassifierVersion.labels);
      return new Set([...tagLabels].filter((x) => !clfLabels.has(x)));
    },
    selectedClassifierVersion() {
      if (this.isDemoClassifier) {
        return {
          id: this.analyzerDetails.clfId,
          labels: Object.keys(this.analyzerDetails.tagMapping),
        };
      }
      if (!this.analyzerForm.clfId || !this.classifiers) {
        return null;
      }
      const clfId = this.version2clf(this.analyzerForm.clfId);
      return this.classifiers[clfId].versions[this.analyzerForm.clfId];
    },
    selectedSubClassifierVersion() {
      if (this.isDemoClassifier) {
        return {
          id: this.analyzerDetails.tagMapping[this.activeTag.class].clfId,
          labels: Object.keys(this.analyzerDetails.tagMapping[this.activeTag.class]
            .tagMapping),
        };
      }
      if (!this.activeTag?.clfId || !this.classifiers) {
        return null;
      }
      const clfId = this.version2clf(this.activeTag.clfId);
      return this.classifiers[clfId].versions[this.activeTag.clfId];
    },
    versionOptions() {
      if (this.isDemoClassifier) return [{ value: this.analyzerDetails.clfId, text: 'demo version' }];
      if (this.selectedClassifier === null) {
        return [];
      }
      const options = Object.entries(this.classifiers[this.selectedClassifier].versions)
        .map(([key, o]) => ({ value: key, text: `${o.description} ${o.createdTime}` }));
      return options;
    },
    subClassifierVersionOptions() {
      if (this.isDemoClassifier) {
        return [{ value: this.analyzerDetails.tagMapping[this.activeTag.class].clfId, text: 'demo version' }];
      }
      if (this.selectedSubClassifier === null) {
        return [];
      }
      const options = Object.entries(this.classifiers[this.selectedSubClassifier].versions)
        .map(([key, o]) => ({ value: key, text: `${o.description} ${o.createdTime}` }));
      return options;
    },
  },
  watch: {
    unsavedChanges(newVal) {
      this.$emit('unsavedChanges', newVal);
    },
    selectedClassifier: {
      immediate: true,
      async handler(after, before) {
        if (after && after !== before) {
          await this.fetchClassifierLabels();
        }
      },
      deep: true,
    },
    selectedSubClassifier: {
      immediate: true,
      async handler(after, before) {
        if (after && after !== before) {
          await this.fetchClassifierLabels(true);
        }
      },
      deep: true,
    },
  },
  async created() {
    this.analyzerForm = cloneDeep(this.analyzerDetails);
    await this.fetchClassifiers();
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    ...mapActions('analyzer', { updateAnalyzer: 'patchItem' }),
    openAiEnabled,
    tagItems(tag = null) {
      const tags = tag ? this.analyzerForm.tagMapping[tag.class]?.tagMapping || {}
        : this.analyzerForm.tagMapping || {};
      const unreachableTags = tag ? this.unreachableSubTags : this.unreachableTags;
      return Object.entries(tags)
        .filter((e) => tag || e[0].toLocaleLowerCase()
          .includes(this.searchKeyword.toLocaleLowerCase()))
        .map(([key, value]) => ({
          class: key,
          label: value.label,
          tag: value.tag,
          state: !value.disabled,
          reachable: !unreachableTags.has(key),
          clfId: value.clfId,
          clfType: value.clfType,
          tagMapping: value.tagMapping,
        }));
    },
    async fetchClassifiers() {
      if (this.isDemoClassifier) {
        this.selectedClassifier = this.version2clf(this.analyzerDetails.clfId);
        return;
      }
      if (!this.classifiers !== null) {
        try {
          this.isFetchingClassifiers = true;
          this.classifiers = null;
          const request = { ...this.headerAuthorization };
          const { data } = await axios.get(`${endpoints.analyzer}classifiers/`, request);
          this.classifiers = data;
          if (this.analyzerDetails.clfId !== null && !this.selectedClassifier) {
            this.selectedClassifier = this.version2clf(this.analyzerDetails.clfId);
            if (this.selectedClassifier) {
              this.analyzerForm.clfId = this.analyzerDetails.clfId;
            }
          }
          this.isFetchingClassifiers = false;
          this.touchClassifier();
        } catch (error) {
          this.showWarning({
            title: 'Failed to fetch classifiers',
            text: error.message,
          });
          this.isFetchingClassifiers = false;
        }
      }
    },
    async fetchClassifierLabels(subClassifier = false) {
      if (this.isDemoClassifier) {
        this.labels = Object.keys(this.analyzerDetails.tagMapping);
        return;
      }
      try {
        this.isFetchingLabels = true;
        if (subClassifier) {
          this.subClassifierLabels = null;
        } else {
          this.labels = null;
        }
        const request = {
          ...this.headerAuthorization,
          params: { clfId: subClassifier ? this.selectedSubClassifier : this.selectedClassifier },
        };
        const { data } = await axios.get(`${endpoints.analyzer}classifier_labels/`, request);
        if (subClassifier) {
          this.subClassifierLabels = data;
        } else {
          this.labels = data;
        }
      } catch (error) {
        this.labels = null;
        this.showWarning({
          title: 'Failed to fetch classifier labels',
          text: error.message,
        });
      } finally {
        this.isFetchingLabels = false;
      }
    },
    populateTagMapping() {
      if (this.labels !== null) {
        if (this.analyzerForm.tagMapping === undefined) {
          this.analyzerForm.tagMapping = {};
        }
        const tagMapping = { ...this.analyzerForm.tagMapping };
        for (const label of this.labels) {
          if (tagMapping[label.name] === undefined) {
            tagMapping[label.name] = {
              tag: '',
              label: label.displayName,
              class: label.name,
              disabled: false,
              clfId: null,
              clfType: null,
              tagMapping: {},
            };
          }
        }
        this.analyzerForm.tagMapping = tagMapping;
      }
    },
    populateNestedTagMapping() {
      if (this.subClassifierLabels !== null) {
        if (this.analyzerForm.tagMapping[this.activeTag.class].tagMapping === undefined) {
          this.analyzerForm.tagMapping[this.activeTag.class].tagMapping = {};
        }
        const tagMapping = { ...this.analyzerForm.tagMapping[this.activeTag.class].tagMapping };
        for (const label of this.subClassifierLabels) {
          if (tagMapping[label.name] === undefined) {
            tagMapping[label.name] = {
              tag: '',
              label: label.displayName,
              class: label.name,
              disabled: false,
            };
          }
        }
        this.analyzerForm.tagMapping[this.activeTag.class].tagMapping = tagMapping;
      }
    },
    selectClassifier(value) {
      this.selectedClassifier = value;
      if (this.isDemoClassifier) return;
      if (this.classifiers[value].versions[this.analyzerDetails.clfId]) {
        this.analyzerForm.clfId = this.analyzerDetails.clfId;
      } else {
        this.analyzerForm.clfId = undefined;
      }
      this.analyzerForm.clfType = this.classifiers[value].type;
    },
    selectSubClassifier(value) {
      this.selectedSubClassifier = value;
      if (this.isDemoClassifier) return;
      const savedId = this.analyzerDetails.tagMapping[this.activeTag.class]?.clfId;
      if (this.classifiers[value] && this.classifiers[value].versions[savedId]) {
        this.analyzerForm.tagMapping[this.activeTag.class].clfId = this.analyzerDetails
          .tagMapping[this.activeTag.class].clfId;
      } else {
        this.analyzerForm.tagMapping[this.activeTag.class].clfId = null;
      }
      this.analyzerForm.tagMapping[this.activeTag.class].clfType = this.classifiers[value]?.type;
    },
    selectSubClassifierVersion(value) {
      Vue.set(this.analyzerForm.tagMapping[this.activeTag.class], 'clfId', value);
    },
    setNeutralSentences(sentences) {
      this.analyzerForm.neutralSentences = update(this.analyzerForm.neutralSentences, sentences);
    },
    setActiveTag(tag) {
      this.selectedSubClassifier = null;
      this.activeTagClass = tag.class;
      if (this.isDemoClassifier) {
        this.selectSubClassifier(tag.clfId);
        return;
      }
      if (this.classifiers) {
        Object.entries(this.classifiers).forEach(([key, value]) => {
          if (value.versions[tag.clfId]) {
            this.selectSubClassifier(key);
          }
        });
      }
    },
    touchClassifier() {
      this.$v.analyzerForm.clfId.$touch();
      this.$v.selectedClassifier.$touch();
    },
    getFormState(invalid, dirty) {
      if (!dirty) {
        return null;
      } if (invalid) {
        return false;
      }
      return true;
    },
    tagTemplate() {
      return {
        class: null,
        label: null,
        tag: '',
      };
    },
    addTag() {
      if (this.addSubTag) {
        Vue.set(
          this.analyzerForm.tagMapping[this.activeTag.class].tagMapping,
          this.newTag.class,
          {
            tag: this.newTag.tag,
            label: this.newTag.label,
            class: this.newTag.class,
            disabled: false,
          },
        );
      } else {
        Vue.set(
          this.analyzerForm.tagMapping,
          this.newTag.class,
          {
            tag: this.newTag.tag,
            label: this.newTag.label,
            class: this.newTag.class,
            disabled: false,
            clfId: null,
            clfType: null,
            tagMapping: {},
          },
        );
      }
    },
    clearTag() {
      this.newTag = this.tagTemplate();
      this.addSubTag = false;
      this.$v.newTag.$reset();
    },
    deleteTag(key) {
      Vue.delete(this.analyzerForm.tagMapping, key.class);
    },
    deleteSubTag(key) {
      Vue.delete(this.analyzerForm.tagMapping[this.activeTag.class].tagMapping, key.class);
    },
    deleteAllTags() {
      this.analyzerForm.tagMapping = {};
    },
    version2clf(versionId) {
      if (this.isDemoClassifier) {
        return this.analyzerDetails.clfId;
      }
      for (const [id, c] of Object.entries(this.classifiers)) {
        if (c.versions[versionId] !== undefined) {
          return id;
        }
      }
      return null;
    },
    toggleDisableTag(key) {
      this.analyzerForm.tagMapping[key].disabled = !this.analyzerForm.tagMapping[key].disabled;
    },
    toggleDisableSubTag(key) {
      const val = this.analyzerForm.tagMapping[this.activeTag.class].tagMapping[key].disabled;
      this.analyzerForm.tagMapping[this.activeTag.class].tagMapping[key].disabled = !val;
    },
    updateTag(tag, x) {
      Vue.set(this.analyzerForm.tagMapping[tag.class], 'tag', x);
    },
    updateSubTag(tag, x) {
      Vue.set(this.analyzerForm.tagMapping[this.activeTag.class].tagMapping[tag.class], 'tag', x);
    },
    updateTagClassifier(value) {
      Vue.set(this.analyzerForm.tagMapping[this.activeTag], 'clfId', value);
    },
    toggleClassification(value) {
      if (value === true) {
        this.analyzerForm.classification = true;
      } else {
        this.analyzerForm.classification = false;
        this.analyzerForm.addTag = false;
      }
    },
    toggleSentiment(value) {
      if (this.analyzerForm.scoreSentimentImportance !== 0 && !value) {
        this.sentimentAnalysisWarning = true;
      } else {
        this.analyzerForm.sentimentAnalysis = value;
        this.analyzerForm.scoreSentimentImportance = 0;
        this.sentimentAnalysisWarning = false;
      }
    },
    toggleSummarization(value) {
      if (value === true) {
        this.analyzerForm.summarization = true;
      } else {
        this.analyzerForm.summarization = false;
        this.analyzerForm.addSummary = false;
      }
    },
    showAddTagModal(addSubTag = false) {
      this.addSubTag = addSubTag;
      this.$bvModal.show('add-tag-modal');
    },
    getNestedTagState(tag) {
      return zendeskTagValidation(tag);
    },
  },
  validations() {
    return {
      analyzerForm: {
        clfId: {
          required: requiredIf(() => this.analyzerDetails && this.analyzerForm.classification),
        },
      },
      activeTag: {
        zendeskTagValidation: requiredIf(() => this.activeTag),
      },
      selectedClassifier: {
        required: requiredIf(() => this.analyzerDetails && this.analyzerForm.classification),
      },
      newTag: {
        label: {
          required,
        },
        class: {
          required,
        },
        tag: {
          zendeskTagValidation,
        },
      },
    };
  },
};
</script>
<style scoped>
.action-button {
  border: 0;
  color: grey;
}
.action-button:hover{
  color:black;
}
</style>
