<template>
  <collapsible-section
    :title="metricTitle"
    :disabled="collapseDisabled"
    @visible="(val) => isVisible = val"
  >
    <template #rightSide>
      <b-button-group
        v-if="isVisible"
      >
        <b-button
          v-if="fullInterval"
          variant="primary"
          :pressed="selectedChartType === 'pie'"
          class="chart-type-button"
          @click.stop="selectedChartType = 'pie'"
        >
          <font-awesome-icon icon="chart-pie" />
        </b-button>
        <b-button
          v-for="chartType in chartTypes"
          v-else
          :key="chartType"
          variant="primary"
          :pressed="chartType == selectedChartType"
          class="chart-type-button"
          @click.stop="selectedChartType = chartType"
        >
          <font-awesome-icon :icon="`chart-${chartType}`" />
        </b-button>
      </b-button-group>
    </template>
    <template #content>
      <div class="bg-white p-2">
        <b-row
          v-if="hasData"
          no-gutters
          style="min-height:500px;"
        >
          <b-col>
            <typed-chart
              :key="isVisible + selectedChartType"
              :chart-data="getMetricData()"
              :options="chartOptions"
              :chart-type="selectedChartType"
              class="h-100"
            />
          </b-col>
        </b-row>
      </div>
    </template>
  </collapsible-section>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import TypedChart from '@/components/typedChart.vue';
import CollapsibleSection from '@/components/CollapsibleSection.vue';
import { intervalOptions, metricOptions } from '@/js/constants';
import { matchingColors } from 'supwiz/util/data';

export default {
  name: 'DataDisplay',
  components: { CollapsibleSection, TypedChart },
  props: {
    metric: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
      selectedChartType: 'line',
    };
  },
  computed: {
    ...mapGetters('statistics', ['getStatistics']),
    ...mapGetters('statisticsFiltersStore', ['filters']),
    statisticsData() {
      return this.getStatistics('analytics');
    },
    hasData() {
      return this.statisticsData.general.groups?.length;
    },
    collapseDisabled() {
      return !this.statisticsData.general[this.metric]?.length
      && !this.statisticsData.general.grouped?.[0]?.[this.metric]?.length;
    },
    chartTypes() {
      return ['line', 'bar'];
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: this.selectedChartType !== 'pie',
          },
          y: {
            display: this.selectedChartType !== 'pie',
            ...(this.metric === 'average_conversation_score' ? { min: -100, max: 100 } : {}),
          },
        },
        plugins: {
          datalabels: {
            display: this.selectedChartType === 'pie',
            color: '#fff',
            formatter: (value, ctx) => {
              let sum = 0;
              const dataArr = ctx.chart.data.datasets[0].data;
              dataArr.forEach((element) => {
                sum += element || 0;
              });
              const percentage = `${(value * 100 / sum).toFixed(2)}%`;
              return percentage;
            },
          },
        },
        onHover: (event, chartElement) => {
          // eslint-disable-next-line no-param-reassign
          event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
      };
    },
    fullInterval() {
      return intervalOptions.FULL === this.filters.interval;
    },
    metricTitle() {
      return metricOptions.find((e) => e.value === this.metric).text;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'filters.interval': function (n) {
      if (n === intervalOptions.FULL) {
        this.selectedChartType = 'pie';
      } else if (this.selectedChartType === 'pie') {
        this.selectedChartType = 'line';
      }
    },
  },
  mounted() {
    if (this.fullInterval) {
      this.selectedChartType = 'pie';
    }
    if (this.metric === 'histogram_of_average_agent_score') {
      this.selectedChartType = 'bar';
    }
  },
  methods: {
    getMetricData() {
      if (this.statisticsData.general.groups.length) {
        const labels = this.getLabels(this.statisticsData.general.grouped?.[0].groups || []);
        const datasets = this.getDatasets(this.metric);
        return {
          labels,
          datasets,
        };
      }
      return {};
    },
    getLabels(timestamps) {
      switch (this.filters.interval) {
        case intervalOptions.MINUTE:
          return timestamps.map((t) => moment(t).format('mm:ss'));
        case intervalOptions.HOUR:
          return timestamps.map((t) => moment(t).format('hh:mm'));
        case intervalOptions.DAY:
          return timestamps.map((t) => moment(t).format('DD/MM/YYYY'));
        case intervalOptions.MONTH:
          return timestamps.map((t) => moment(t).format('MM/YYYY'));
        case intervalOptions.FULL:
          return this.statisticsData.general.groups;
        default:
          return null;
      }
    },
    getDatasets(metric) {
      const datasets = [];
      if (this.fullInterval) {
        datasets.push({
          borderWidth: 2,
          borderColor: '#eee',
          backgroundColor: (this.statisticsData.general[metric] || [])
            .map((_, index) => this.getColor(index)),
          data: this.statisticsData.general[metric],
        });

        return datasets;
      }
      (this.statisticsData.general.groups || []).forEach((label, index) => {
        const current = this.statisticsData.general.grouped[index];
        datasets.push({
          label,
          data: current[metric],
          fill: false,
          type: this.selectedChartType,
          borderColor: this.getColor(index),
          barPercentage: 1,
          backgroundColor: this.getColor(index),
        });
      });
      if (this.metric === 'conversation_score') {
        return datasets;
      }
      const mapped = datasets.map((d) => {
        const formattedData = (d?.data || []).map((e) => (e === null ? 0 : e));
        const current = { ...d, data: formattedData };
        return current;
      });
      return mapped;
    },
    getColor(index) {
      const i = index % matchingColors.length;
      return matchingColors[i];
    },
  },
};
</script>
