<template>
  <section>
    <div v-if="isEdit">
      <h3>
        General Settings
      </h3>
      <b-card
        no-body
      >
        <p class="mb-0">
          Basic configuration settings of the analyzer.
        </p>
      </b-card>
      <hr>
    </div>

    <edit-key-value
      class="mb-3"
      key-prop="Name"
      description="Name of the analyzer"
      :value-prop="analyzerForm.name"
      :min-key-width="isEdit ? editKeyWidth : createKeyWidth"
      type="input"
      :state="getFormState($v.analyzerForm.name.$invalid,
                           $v.analyzerForm.name.$dirty)"
      @input="(x) => analyzerForm.name = x"
      @touch="$v.analyzerForm.name.$touch()"
    >
      <template #feedback>
        <b-form-invalid-feedback
          v-if="!$v.analyzerForm.name.required"
        >
          Name is required
        </b-form-invalid-feedback>
        <b-form-invalid-feedback
          v-if="!$v.analyzerForm.name.isUnique"
        >
          Name already used by another Analyzer
        </b-form-invalid-feedback>
      </template>
    </edit-key-value>

    <edit-key-value
      class="my-3"
      key-prop="Connector"
      description="Connector used for synchronizing data"
      :value-prop="analyzerForm.dataSource"
      :min-key-width="isEdit ? editKeyWidth : createKeyWidth"
      :options="connectorOptions"
      type="select"
      :state="getFormState($v.analyzerForm.dataSource.$invalid,
                           $v.analyzerForm.dataSource.$dirty)"
      @input="(x) => analyzerForm.dataSource = x"
      @touch="$v.analyzerForm.dataSource.$touch()"
    >
      <template #feedback>
        <b-form-invalid-feedback
          v-if="!$v.analyzerForm.dataSource.required"
        >
          Connector is required
        </b-form-invalid-feedback>
      </template>
    </edit-key-value>

    <edit-key-value
      class="my-3"
      key-prop="Language"
      description="Language of the data being synchronized"
      :value-prop="analyzerForm.language"
      :min-key-width="isEdit ? editKeyWidth : createKeyWidth"
      :options="languages"
      type="select"
      :state="getFormState($v.analyzerForm.language.$invalid,
                           $v.analyzerForm.language.$dirty)"
      @input="(x) => analyzerForm.language = x"
      @touch="$v.analyzerForm.language.$touch()"
    >
      <template #feedback>
        <b-form-invalid-feedback
          v-if="!$v.analyzerForm.language.required"
        >
          Language is required
        </b-form-invalid-feedback>
      </template>
    </edit-key-value>
    <div :class="isEdit ? 'text-left mt-3' : 'text-right mt-3'">
      <b-button
        v-if="!isEdit"
        class="mr-2"
        @click="$emit('hide')"
      >
        Cancel
      </b-button>

      <b-button
        variant="primary"
        :style="`width: ${editKeyWidth}px;`"
        :disabled="$v.analyzerForm.$invalid || !unsavedChanges"
        @click="saveChangesButton"
      >
        {{ isEdit ? 'Save Changes' : 'Create Analyzer' }}
      </b-button>
    </div>
    <span
      v-if="isEdit && unsavedChanges"
      class="unsaved-text"
    >
      *Unsaved changes
    </span>
    <b-modal
      id="save-changes-modal"
      title="Save changes"
      ok-title="Save"
      ok-variant="warning"
      auto-focus-button="cancel"
      @ok="saveChanges"
    >
      The selected language is not compatible with the configured transcribe model
      ({{ transcribeModelName }}, {{ transcribeModelLocale }}).
      <br>Saving will set the transcribe model to the default model.
      <br>Are you sure you want to save the changes?
    </b-modal>
  </section>
</template>
<script>
import { validationMixin } from 'vuelidate';
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';
import { required } from 'vuelidate/lib/validators';
import { cloneDeep } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { languageOptions } from '@/js/constants';

export default {
  name: 'General',
  components: {
    EditKeyValue,
  },
  mixins: [validationMixin],
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editKeyWidth: 220,
      createKeyWidth: 100,
      analyzerForm: null,
    };
  },
  computed: {
    ...mapState('connector', { connectors: 'items' }),
    ...mapState('analyzer', { analyzers: 'items' }),
    ...mapState('analyzer', { analyzerDetails: 'details' }),
    unsavedChanges() {
      return JSON.stringify(this.analyzerForm) !== JSON.stringify(this.analyzerDetails);
    },
    connectorOptions() {
      return [{ value: null, text: 'Choose connector', disabled: true }].concat(Object.values(this.connectors).map((e) => ({ value: e.id, text: e.name })));
    },
    languages() {
      return [{ value: null, text: 'Choose language', disabled: true }].concat(languageOptions);
    },
    transcribeModelName() {
      return this.analyzeranalyzerForm?.transcribeModel?.name;
    },
    transcribeModelLocale() {
      return this.analyzerForm?.transcribeModel?.locale;
    },
  },
  watch: {
    unsavedChanges(newVal) {
      this.$emit('unsavedChanges', newVal);
    },
  },
  created() {
    this.analyzerForm = this.isEdit
      ? cloneDeep(this.analyzerDetails) : this.generalTemplate();
  },
  methods: {
    ...mapActions('analyzer', { addAnalyzer: 'addItem' }),
    ...mapActions('analyzer', { updateAnalyzer: 'patchItem' }),
    generalTemplate() {
      return {
        name: '',
        dataSource: null,
        language: null,
      };
    },
    getFormState(invalid, dirty) {
      if (!dirty) {
        return null;
      } if (invalid) {
        return false;
      }
      return true;
    },
    async saveChangesButton() {
      if (!this.isEdit) {
        await this.saveChanges();
        return;
      }
      if (this.analyzerForm.language && 'locale' in this.analyzerForm.transcribeModel
          && this.analyzerForm.language !== this.analyzerForm.transcribeModel?.locale) {
        this.$bvModal.show('save-changes-modal');
        return;
      }
      await this.saveChanges();
    },
    async saveChanges() {
      if (this.isEdit) {
        this.updateAnalyzer(this.analyzerForm);
      } else {
        await this.addAnalyzer({ newItem: this.analyzerForm });
        this.$emit('added');
        this.$emit('hide');
      }
    },
  },
  validations() {
    return {
      analyzerForm: {
        name: {
          required,
          isUnique(value) {
            return Object.values(this.analyzers).findIndex(
              (e) => (this.analyzerDetails ? e.id !== this.analyzerDetails.id : true)
                     && e.name === value,
            ) === -1;
          },
        },
        dataSource: {
          required,
        },
        language: {
          required,
        },
      },

    };
  },
};

</script>
