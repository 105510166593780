<template>
  <b-card
    class="r-75"
    body-class="p-3"
    :class="unsavedChanges ? 'border-warning' : ''"
  >
    <SingleSignOn
      :id="ssoConfigIds.CUSTOMER"
      :django-groups="djangoGroups"
      :methods="ssoMethods"
      @unsavedChanges="v => unsavedChanges = v"
    />
  </b-card>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import SingleSignOn from 'supwiz/components/SingleSignOn.vue';
import endpoints from '@/js/endpoints';
import { snakeCaseToText } from 'supwiz/util/data';
import { ssoConfigIds, createSsoMethods } from 'supwiz/util/sso';
import axios from 'axios';

export default {
  name: 'SingleSignOnWrapper',
  components: {
    SingleSignOn,
  },
  data() {
    return {
      unsavedChanges: false,
      ssoConfigIds,
    };
  },
  computed: {
    ...mapState('administration', ['groups']),
    djangoGroups() {
      return Object.entries(this.groups).map(
        (e) => ({ value: e[1], text: snakeCaseToText(e[0]) }),
      );
    },
    ssoMethods() {
      return createSsoMethods(axios, endpoints, this.$store.state.auth.jwt);
    },
  },
  async created() {
    await this.fetchGroups();
  },
  methods: {
    ...mapActions('administration', ['fetchGroups']),
  },
};

</script>
