<template>
  <b-list-group-item class="border-0 p-0 mt-3">
    <b-alert
      v-if="callDetails.transcribeDuration > 0"
      show
      variant="warning"
    >
      Transcript limited to first {{ callDetails.transcribeDuration }} seconds.
    </b-alert>
    <div
      v-for="(item, index) in formattedRecognizedPhrases"
      :key="index"
    >
      <b-row no-gutters>
        <b-col
          cols="12"
          class="text-center name"
          style="margin-bottom:-5px"
        >
          {{ formatTime(item.offset, 1) }}
        </b-col>
        <b-col
          :class="item.isVisitor ? 'speaker-col' : 'agent-col'"
          cols="5"
          :order="item.isVisitor ? '1' : '2'"
        >
          <div
            class="name px-2"
            :class="item.isVisitor ? 'text-right' : 'text-left'"
          >
            {{ item.isVisitor ? 'User' : 'Agent' }} (Speaker {{ item.speaker }})
          </div>
          <div
            v-for="(sentence, sIndex) in item.sentences"
            :key="`sentence_${index}_${sIndex}`"
            class="bubble m-1 py-2 px-3 mb-3"
          >
            {{ sentence.display }}
            <template v-if="hasSentiment">
              <b-badge
                :id="`sentiment-popover-${index}_${sIndex}`"
                pill
                :class="`text-${sentence.color} ${item.isVisitor ? 'emoji-right' : 'emoji-left'}`"
                class="p-1 d-block bg-white shadow emoji"
              >
                <font-awesome-icon :icon="sentence.icon" />
              </b-badge>
              <b-popover
                :target="`sentiment-popover-${index}_${sIndex}`"
                triggers="hover"
                title="sentiment"
                custom-class="text-center"
                placement="bottom"
              >
                <div style="width:100px;">
                  <b-row
                    v-for="(sentiment, i) in ['positive', 'neutral', 'negative']"
                    :key="i"
                    no-gutters
                  >
                    <b-col
                      cols="auto"
                      class="pr-1 my-auto"
                      :class="`text-${$options.sentimentMapper[sentiment][0]}`"
                    >
                      <font-awesome-icon
                        class="my-auto"
                        :icon="$options.sentimentMapper[sentiment][1]"
                      />
                    </b-col>
                    <b-col class="my-auto">
                      <b-progress
                        :max="1"
                        delay="1"
                        class="my-1"
                        precision="5"
                        variant=""
                        :value="sentence.sentiment[sentiment]"
                      />
                    </b-col>
                  </b-row>
                </div>
              </b-popover>
            </template>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-list-group-item>
</template>

<script>
export default {
  name: 'CallLog',
  sentimentMapper: {
    neutral: ['secondary', 'meh'],
    negative: ['warning', 'frown'],
    positive: ['success', 'smile'],
  },
  props: {
    callDetails: {
      type: Object,
      required: true,
    },
    formatTime: {
      type: Function,
      default: (val) => val,
    },
    hasSentiment: {
      type: Boolean,
      required: true,
    },
    visitorSpeaker: {
      type: [Number, String],
      default: undefined,
    },
  },
  computed: {
    legacySentiment() {
      return this.callDetails.sentimentPrediction.kind === undefined;
    },
    sentimentPartsMap() {
      if (!this.hasSentiment || this.legacySentiment) {
        return {};
      }
      const map = {};
      this.callDetails.sentimentPrediction.phraseSentimentParts.forEach((e) => {
        if (!(e.phrase_idx in map)) {
          map[e.phrase_idx] = [];
        }
        map[e.phrase_idx].push(e);
      });
      return map;
    },
    formattedRecognizedPhrases() {
      const transcript = this.callDetails.transcript.recognizedPhrases;
      if (!this.hasSentiment) {
        return transcript.map((e) => ({
          sentences: [{
            display: e.nBest[0].display,
          }],
          isVisitor: this.isVisitor(e),
          speaker: e.speaker,
          offset: e.offset,
        }));
      }
      if (this.legacySentiment) {
        return transcript.map((e, index) => ({
          sentences: [this.getSentenceSentimentObject({
            display: e.nBest[0].display,
            sentiment: this.getSentimentInfo(index),
          })],
          isVisitor: this.isVisitor(e),
          speaker: e.speaker,
          offset: e.offset,
        }));
      }
      // New sentiment logic
      const prediction = this.callDetails.sentimentPrediction;
      return transcript.map((e, index) => ({
        sentences: this.sentimentPartsMap[index].map((s) => (
          this.getSentenceSentimentObject({
            display: e.nBest[0].display.substr(s.offset, s.length),
            sentiment: prediction.sentences[s.sentiment_idx].confidence_scores,
          })
        )),
        isVisitor: this.isVisitor(e),
        speaker: e.speaker,
        offset: e.offset,
      }));
    },
  },
  methods: {
    isVisitor(item) {
      return item.speaker === this.visitorSpeaker;
    },
    getPhraseInfo(item) {
      const { nBest, ...data } = item;
      return data;
    },
    getSentimentInfo(index) {
      return this.callDetails?.sentimentPrediction?.sentimentsResults?.length
        ? this.callDetails.sentimentPrediction.sentimentsResults[index].sentiment_result : {};
    },
    getSentenceSentimentObject(element) {
      const sentiment = element.sentiment;
      const highest = Object.keys(sentiment)
        .reduce((a, b) => (sentiment[a] > sentiment[b] ? a : b));
      const mapper = this.$options.sentimentMapper[highest];
      return {
        ...element,
        color: mapper[0],
        icon: mapper[1],
        highest,
      };
    },
  },
};
</script>

<style scoped>
.bubble{
  color:white;
  min-width: 10%;
  width: fit-content;
  border-radius: 15px;
}
.name{
  color:#888;
  font-size: 0.8rem;
}
.agent-col{
  margin-right: auto;
}
.agent-col > .bubble{
  background-color: #2d3c5d;
  margin-right: auto !important;
}
.speaker-col{
  margin-left: auto;
}
.speaker-col > .bubble{
 background-color:#395d85;
 margin-left: auto !important;
}
.prediction{
  font-size: 120%;
}
.emoji{
  font-size:1rem;
  margin-bottom:-22px;
  width:24px;
  margin-left:auto;
}
.emoji-right{
  margin-right: -10px;
  margin-left:auto;
}
.emoji-left{
  margin-left: -10px;
  margin-right:auto;
}
</style>
