<template>
  <span
    :style="{ backgroundColor }"
  >
    {{ percentage }}
  </span>
</template>

<script>
import { formatNumber } from 'supwiz/util/formatters';
import { mapDecimalToColor } from '@/js/utils';

export default {
  name: 'PercentageDisplay',
  props: {
    decimal: {
      type: [Number, String, null, undefined],
      required: true,
    },
    color: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isNumber() {
      if ([null, undefined].includes(this.decimal)) return false;
      if (typeof this.decimal === 'string') return !Number.isNaN(Number(this.decimal));
      return !Number.isNaN(this.decimal);
    },
    percentage() {
      if (!this.isNumber) return '-';
      return `${formatNumber(this.decimal * 100)}%`;
    },
    backgroundColor() {
      if (!this.color || !this.isNumber) return '#ccc';
      return mapDecimalToColor(this.decimal, {
        saturation: 90,
        lightness: 85,
      });
    },
  },
};
</script>
