<template>
  <b-row
    v-if="!ready"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <div v-else>
    <statistics-filter
      v-if="ready"
      ref="statisticsFilter"
      :metrics="metricOptions"
      :extra-filters="extraFilters"
      :base-filters="baseFilters"
      :configuration="localStatsConfig"
      calendar-tooltip="Select date range for computing insights"
      @newFilters="(val) => needsRefresh = val"
      @fetchData="fetchDataProxy()"
    >
      <template #customSearch>
        <CustomSearch />
      </template>
      <template #filtersHeader>
        <stats-configuration-dropdown
          :filter-source="statisticsConfigSource.CONVERSATION_INSIGHTS"
          :default-filter-values="{}"
          title="conversation insights"
          @needsRefresh="v=>needsRefresh = v"
        />
      </template>
      <template #categories>
        <categories />
      </template>
    </statistics-filter>
    <b-overlay
      variant="white stat-overlay shadow"
      :show="anyComputing || needsRefresh"
      :opacity="needsRefresh ? '1.0' : '0.7'"
      :no-center="needsRefresh"
      style="min-height:50px"
    >
      <template
        v-if="needsRefresh"
        #overlay
      >
        <h4
          style="padding-top:20px"
          class="my-auto text-center pb-3"
        >
          Click "Show" to refresh statistics.
        </h4>
      </template>
      <div v-if="hasData">
        <b-row
          no-gutters
        >
          <b-col cols="6" xl="3" class="mb-3 pr-2">
            <NewKPICard
              title="Conversations"
              format-type="number"
              :value="getKPICardValue('count', 'actual')"
              :previous-value="getKPICardValue('count', 'previous')"
            />
          </b-col>
          <b-col cols="6" xl="3" class="mb-3 pl-2 px-xl-2">
            <NewKPICard
              title="Average conv. score"
              format-type="number"
              :value="getKPICardValue('average_score', 'actual')"
              :previous-value="getKPICardValue('average_score', 'previous')"
            />
          </b-col>
          <b-col cols="6" xl="3" class="mb-3 pr-2 px-xl-2">
            <NewKPICard
              title="Agents"
              format-type="number"
              :value="getKPICardValue('agents', 'actual')"
              :previous-value="null"
            />
          </b-col>
          <b-col cols="6" xl="3" class="mb-3 pl-2">
            <NewKPICard
              title="Departments"
              format-type="number"
              :value="getKPICardValue('groups', 'actual')"
              :previous-value="null"
            />
          </b-col>
        </b-row>
        <b-row class="d-flex">
          <b-col cols="12" xl="5" class="mb-3 pr-3 pr-xl-2">
            <b-card body-class="p-3" class="r-75 shadow h-100">
              <b-row class="mb-2">
                <b-col class="my-auto">
                  <b-card-title class="my-auto">
                    Topics
                  </b-card-title>
                </b-col>
                <b-col cols="auto">
                  <b-button
                    variant="primary"
                    :disabled="!hasTopicsData"
                    class="switch-btn"
                    @click="topicModeChart = !topicModeChart"
                  >
                    <font-awesome-icon :icon="topicModeChart ? 'table' : 'chart-pie'" />
                  </b-button>
                </b-col>
              </b-row>
              <div v-if="!hasTopicsData" class="text-center py-4">
                There no records to show.
              </div>
              <div v-else-if="topicModeChart" style="height: 500px;">
                <typed-chart
                  :chart-data="topicsChartData"
                  :options="topicsChartOptions"
                  chart-type="pie"
                  class="h-100"
                />
              </div>
              <b-table
                v-else
                :fields="topicFields"
                :items="topicItems"
              />
            </b-card>
          </b-col>
          <b-col cols="12" xl="7" class="mb-3 pl-3 pl-xl-2">
            <b-card body-class="p-3" class="r-75 shadow h-100">
              <b-row class="mb-2">
                <b-col class="my-auto">
                  <b-card-title class="my-auto">
                    {{ showTopConversations ? 'Top' : 'Underperforming' }} conversations
                  </b-card-title>
                </b-col>
                <b-col cols="auto" class="my-auto">
                  <b-button variant="primary" class="switch-btn" @click="showTopConversations = !showTopConversations">
                    <font-awesome-icon :icon="showTopConversations ? 'up-long' : 'down-long'" />
                  </b-button>
                </b-col>
              </b-row>
              <b-table
                id="conversation-performance-table"
                show-empty
                class="cursor-pointer"
                :per-page="conversationPerformancePagination.perPage"
                :current-page="conversationPerformancePagination.currentPage"
                :fields="conversationPerformanceFields"
                hover
                :items="showTopConversations ? bestConversationItems : worstConversationItems"
                @row-clicked="openConversation"
              />
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="!anonymizeAgents" cols="12" xl="7" class="mb-3 pr-3 pr-xl-2">
            <b-card title="Top agents per topic" body-class="p-3" class="r-75 shadow h-100">
              <b-table
                show-empty
                class="mb-0"
                :fields="topicAgentComparisonFields"
                :items="topicAgentComparisonItems"
              >
                <template #cell(topic)="row">
                  <b-col class="my-auto py-auto">
                    <b-badge
                      v-b-tooltip.hover.noninteractive.viewport="'Click to filter by this topic'"
                      pill
                      class="cursor-pointer big-badge"
                      @click="topicClicked(row.item.topic)"
                    >
                      {{ row.item.topic || 'None' }}
                    </b-badge>
                  </b-col>
                </template>
                <template #cell(agents)="row">
                  <div class="border r-25 h-auto">
                    <strong>
                      <b-row no-gutters class="text-center border-bottom mb-2">
                        <b-col>ID</b-col>
                        <b-col>Count</b-col>
                        <b-col>Score</b-col>
                      </b-row>
                    </strong>
                    <b-row v-for="(agent, index) in row.item.agents" :key="index" class="text-center pb-1" no-gutters>
                      <b-col class="my-auto py-auto">
                        <b-badge
                          v-b-tooltip.hover.noninteractive.viewport="'Click to filter by this agent'"
                          pill
                          class="cursor-pointer big-badge"
                          @click="agentIdClicked(agent.id)"
                        >
                          {{ agent.id }}
                        </b-badge>
                      </b-col>
                      <b-col> {{ agent.count }}</b-col>
                      <b-col> {{ agent.score || 'N/A' }}</b-col>
                    </b-row>
                  </div>
                </template>
              </b-table>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            :xl="anonymizeAgents ? '12' : '5'"
            :class="anonymizeAgents ? 'mb-3 pl-3' : 'mb-3 pl-3 pl-xl-2'"
          >
            <b-card title="Score per topic" body-class="p-3" class="r-75 shadow h-100">
              <b-table show-empty :fields="scorePerTopicFields" :items="scorePerTopicItems">
                <template #cell(name)="row">
                  <b-col class="my-auto py-auto">
                    <b-badge
                      v-b-tooltip.hover.noninteractive.viewport="'Click to filter by this topic'"
                      pill
                      class="cursor-pointer big-badge"
                      @click="topicClicked(row.item.name)"
                    >
                      {{ row.item.name || 'None' }}
                    </b-badge>
                  </b-col>
                </template>
                <template #cell(comparedToAvg)="row">
                  <b-col class="pl-0">
                    <font-awesome-icon
                      v-if="row.item.comparedToAvg > 0"
                      icon="up-long"
                      color="green"
                    />
                    <font-awesome-icon
                      v-else-if="row.item.comparedToAvg < 0"
                      icon="down-long"
                      color="red"
                    />
                    {{ row.item.comparedToAvg }}
                  </b-col>
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="d-flex">
          <b-col cols="12" class="mb-3">
            <b-card
              class="r-75 shadow"
              body-class="p-3"
            >
              <b-row class="mb-2">
                <b-col class="my-auto">
                  <b-card-title class="my-auto">
                    Distribution over time
                  </b-card-title>
                </b-col>

                <b-col cols="auto" class="my-auto">
                  <b-form-group
                    label-cols="auto"
                    label="Interval"
                    class="my-auto"
                  >
                    <b-form-select
                      v-model="distributionInterval"
                      :options="mappedIntervalOptions"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" style="height: 400px;">
                  <typed-chart
                    :chart-data="distributionChartData('Count', 'call_count')"
                    :options="distributionChartOptions('Count')"
                    chart-type="line"
                    class="h-100"
                  />
                </b-col>
                <b-col cols="6" style="height: 400px;">
                  <typed-chart
                    :chart-data="distributionChartData('Score', 'conversation_score')"
                    :options="distributionChartOptions('Score')"
                    chart-type="line"
                    class="h-100"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </div>

      <b-card
        v-else
        class="r-75 shadow"
      >
        <h4 class="mb-0">
          There is no data to show.
        </h4>
      </b-card>
    </b-overlay>
    <new-configuration-modal :source="statisticsConfigSource.CONVERSATION_INSIGHTS" />
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import StatisticsFilter from 'supwiz/components/statisticsFilters/StatisticsFilters.vue';
import NewKPICard from 'supwiz/components/NewKPICard.vue';
import moment from 'moment';
import {
  getFirstUserMessage, rgbToHsl, getExtraFilters,
} from '@/js/utils';
import {
  defaultFilterValues, metricOptions, mappedIntervalOptions,
  featureSupport, intervalOptions, statisticsConfigSource,
} from '@/js/constants';
import NewConfigurationModal from '@/components/Analyzer/NewConfigurationModal.vue';
import StatsConfigurationDropdown from '@/components/Analyzer/StatsConfigurationDropdown.vue';
import Categories from '@/components/Analyzer/Categories.vue';
import TypedChart from '@/components/typedChart.vue';
import { shuffledMatchingColors } from 'supwiz/util/data';
import { agentAnonymizationMode } from '@/js/featureFlags';
import CustomSearch from '@/components/CustomSearch.vue';

export default {
  name: 'ConversationInsights',
  components: {
    StatisticsFilter,
    Categories,
    NewConfigurationModal,
    NewKPICard,
    StatsConfigurationDropdown,
    TypedChart,
    CustomSearch,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchStatsConfigs({ params: { analyzer: to.params.analyzerId } });
    });
  },
  data() {
    return {
      statisticsConfigSource,
      defaultFilterValues,
      intervalOptions,
      mappedIntervalOptions,
      needsRefresh: false,
      metricOptions,
      ready: false,
      localStatsConfig: null,
      conversationPerformanceFields: [
        { key: 'id', label: 'ID', tdClass: 'id-col' },
        { label: 'First message', key: 'first_message' },
        { label: 'Score', key: 'score' },
        { label: 'Topic', key: 'tag', tdClass: 'topic-col' },
      ],
      conversationPerformancePagination: {
        currentPage: 1,
        perPage: 10,
      },
      topicAgentComparisonFields: [
        'topic',
        { key: 'agents', label: 'Agents', thClass: 'text-center' },
      ],
      topicFields: ['name', 'count'],
      scorePerTopicFields: ['name', 'score', 'comparedToAvg'],
      filterOptions: null,
      topicModeChart: true,
      showTopConversations: true,
    };
  },
  computed: {
    ...mapState('analyzer', { analyzerDetails: 'details' }),
    ...mapState('statistics', [
      'bestConversationPerformanceItems', 'worstConversationPerformanceItems',
      'computingStats', 'computingDistribution',
      'computingTopicAgent', 'selectedDistributionInterval']),
    ...mapGetters('statisticsFiltersStore', ['extraSelectedFilters', 'filters']),
    ...mapState('statsConfiguration', { statsConfigurationDetails: 'details' }),
    ...mapState('statsConfiguration', { statsConfigurations: 'items' }),
    ...mapGetters('connector', ['typeFromId']),
    ...mapGetters('statistics', ['getStatistics', 'getSavedFilters']),
    ...mapGetters('statsConfiguration', ['getSavedStatsConfig']),
    statisticsData() {
      return this.getStatistics('conversationInsights');
    },
    anyComputing() {
      return this.computingStats || this.computingDistribution || this.computingTopicAgent;
    },
    distributionInterval: {
      get() {
        return this.selectedDistributionInterval;
      },
      set(v) {
        this.setSelectedDistributionInterval(v);
      },
    },
    baseFilters() {
      return [];
    },
    anonymizeAgents() {
      return agentAnonymizationMode() === 'FULL';
    },
    supportsPushTags() {
      return featureSupport.pushTags.map((e) => e.value).includes(this.connectorType);
    },
    connectorType() {
      return this.typeFromId(this.analyzerDetails.dataSource);
    },
    hasData() {
      return Object.values(this.statisticsData).length;
    },
    dataArray() {
      return Object.values(this.statisticsData);
    },
    chosenMetrics() {
      return this.filters.metrics;
    },
    hasTopicsData() {
      return this.statisticsData.group_by_topic?.groups?.length;
    },
    topicItems() {
      const data = [];
      (this.statisticsData.group_by_topic?.groups || []).forEach((name, index) => {
        const count = this.statisticsData.group_by_topic?.topic_count?.[index] || 0;
        data.push({ name, count });
      });
      return data;
    },
    topicsChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label(context) {
                return `${context.dataset.labels[context.dataIndex]}: ${context.formattedValue}`;
              },
            },
          },
        },
      };
    },
    topicsChartData() {
      const data = this.statisticsData.group_by_topic;

      const labels = [];
      const datasets = [
      // Second level (subtopics)
        {
          labels: [],
          backgroundColor: [],
          data: [],
        },
        // First level (topics)
        {
          labels: [],
          backgroundColor: [],
          data: [],
        },
      ];
      data.groups.forEach((topic, index) => {
        // labels.push(topic);
        datasets[1].labels.push(topic || 'No topic');
        datasets[1].data.push(data.topic_count[index]);
        datasets[1].backgroundColor.push(shuffledMatchingColors()[index
        % shuffledMatchingColors().length]);
      });
      data.groups.forEach((topic, index) => {
        let lightIncrease = 9;
        data.group_by_subtopic[index].groups.forEach((subtopic, sIndex) => {
          // labels.push(`${topic}-${subtopic}`);
          const hsl = rgbToHsl(datasets[1].backgroundColor[index]);
          hsl.lightness = Math.min(Math.max((hsl.lightness + lightIncrease) % 100, 8), 92);
          lightIncrease += 9;
          datasets[0].labels.push(`${topic} - ${subtopic || ('No subtopic')}`);
          datasets[0].data.push(data.group_by_subtopic[index].topic_count[sIndex]);
          datasets[0].backgroundColor.push(`hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness.toFixed(1)}%)`);
        });
      });
      return {
        labels,
        datasets,
      };
    },
    bestConversationItems() {
      return this.bestConversationPerformanceItems.map((e) => ({
        id: e.id,
        first_message: getFirstUserMessage(this.analyzerDetails, e),
        score: (e.conversationScore || 0).toFixed(2),
        tag: e.tag,
      }));
    },
    worstConversationItems() {
      return this.worstConversationPerformanceItems.map((e) => ({
        id: e.id,
        first_message: getFirstUserMessage(this.analyzerDetails, e),
        score: (e.conversationScore || 0).toFixed(2),
        tag: e.tag,
      }));
    },
    topicAgentComparisonItems() {
      const data = [];
      (this.statisticsData.group_by_topic?.groups || []).forEach((topic, index) => {
        const topicAgents = this.statisticsData.group_by_topic?.group_by_agent[index];
        const agents = [];
        topicAgents.groups.forEach((id, aIndex) => {
          agents.push({
            id,
            count: topicAgents?.agent_count[aIndex],
            score: topicAgents?.agent_score[aIndex]
              ? topicAgents?.agent_score[aIndex].toFixed(2) : null,
          });
        });
        data.push({ topic, agents: agents.sort((a, b) => b.score - a.score).slice(0, 3) });
      });
      return data;
    },
    scorePerTopicItems() {
      const data = [];
      (this.statisticsData.group_by_topic?.groups || []).forEach((name, index) => {
        const score = this.statisticsData.group_by_topic?.topic_score?.[index] || 0;
        const comparedToAvg = score - (this.statisticsData.previous.actual?.average_score || 0);
        data.push({
          name,
          score: score.toFixed(2),
          comparedToAvg: comparedToAvg.toFixed(2),
        });
      });
      return data;
    },
  },
  watch: {
    selectedDistributionInterval() {
      this.fetchConversationInsightsDistribution();
    },
    async statsConfigurationDetails(n) {
      if (n.id !== this.localStatsConfig?.id) {
        this.setConfigProxy(this.statsConfigurationDetails);
      }
    },
  },
  async mounted() {
    await this.fetchFilters();
    // if there is saved stats config, load it
    const savedStatsConfig = this.getSavedStatsConfig('conversationInsights');
    if (savedStatsConfig) {
      await this.setConfigProxy(savedStatsConfig);
      this.setStatsConfigurationDetails(savedStatsConfig);
    }
    // if there are saved filters, load it
    this.$nextTick(() => {
      const savedFilters = this.getSavedFilters('conversationInsights');
      if (savedFilters) {
        this.setFilters(cloneDeep(savedFilters.filters));
        savedFilters.extraSelectedFilters.forEach((item) => {
          const alreadyAdded = this.extraSelectedFilters.find((e) => e.key === item.key);
          if (!alreadyAdded) {
            this.$refs.statisticsFilter.addExtraFilterLocal(item);
          }
        });
      } else {
        // fresh setup
        this.setFilters(cloneDeep(this.defaultFilterValues));
      }
    });
  },
  beforeDestroy() {
    // if there is config selected, save it
    if (this.localStatsConfig) {
      this.setLocalStatsConfig({ key: 'conversationInsights', value: cloneDeep(this.localStatsConfig) });
    }
    // save all current filters
    this.setLocalStatisticsFilters({ key: 'conversationInsights', value: cloneDeep(this.$store.state.statisticsFiltersStore) });
  },
  destroyed() {
    this.setStatsConfigurations({});
    this.setStatsConfigurationDetails({});
  },
  methods: {
    ...mapActions('statsConfiguration', ['setFiltersFromConfig']),
    ...mapMutations('statsConfiguration', ['setLocalStatsConfig']),
    ...mapActions('statistics', ['fetchStatistics', 'fetchConversationInsightsDistribution',
      'fetchConversationPerformanceItems']),
    ...mapMutations('statistics', ['setLocalStatisticsFilters']),
    ...mapActions('externalSystem', ['fetchGroups', 'fetchFilterValues']),
    ...mapMutations('statistics', ['setSelectedDistributionInterval']),
    ...mapMutations('statisticsFiltersStore', ['setFilters', 'setFilter']),
    ...mapMutations('statsConfiguration', {
      setStatsConfigurations: 'setItems',
      setStatsConfigurationDetails: 'setItemDetails',
    }),
    ...mapActions('statsConfiguration', {
      fetchStatsConfigs: 'fetchItems',
    }),
    ...mapActions('category', { fetchCategories: 'fetchItems' }),
    getExtraFilters,
    async setConfigProxy(payload) {
      this.setFilters(cloneDeep(this.defaultFilterValues));
      const configCopy = cloneDeep(payload);
      await this.setFiltersFromConfig(configCopy);
      this.localStatsConfig = configCopy;
      return true;
    },
    fetchDataProxy() {
      this.fetchStatistics('conversationInsights');
      this.fetchConversationPerformanceItems(this.showTopConversations);
    },
    async fetchFilters() {
      try {
        this.ready = false;
        const resp = await this.fetchFilterValues();
        this.filterOptions = resp.data;
        this.filterOptions.groups = await this.fetchGroups();
        this.extraFilters = getExtraFilters(
          this.filterOptions,
          this.analyzerDetails,
          this.connectorType,
        );
        await this.fetchCategories();
      } catch (error) {
        const filterValues = `agents${this.analyzerDetails.classification ? ' and tags' : ''}`;
        this.$store.dispatch('sidebar/showWarning', {
          title: `Failed to fetch filter values (${filterValues})`,
          text: error.message,
        });
        throw error;
      } finally {
        this.ready = true;
      }
    },
    distributionChartOptions(text) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        stacked: false,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        scales: {
          x: {
            display: true,
          },
          y: {
            min: text === 'Score' ? -105 : 0,
            max: text === 'Score' ? 105 : null,
            display: true,
            ticks: {
              stepSize: 25,
            },
            title: {
              display: true,
              text,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      };
    },
    distributionChartData(label, key) {
      const data = this.statisticsData.group_by_interval?.[key];
      const labels = this.getDistributionLabels(
        this.statisticsData.group_by_interval?.groups || [],
      );
      const datasets = [
        {
          label,
          data,
          borderColor: key === 'call_count' ? '#3A878C' : '#B3C890',
          backgroundColor: key === 'call_count' ? '#3A878C' : '#B3C890',
        },
      ];
      return {
        labels,
        datasets,
      };
    },
    getKPICardValue(key, period) {
      switch (key) {
        case 'count': {
          return this.statisticsData.previous[period]?.[key];
        }
        case 'average_score': {
          return parseFloat((this.statisticsData.previous[period]?.[key] || 0).toFixed(2), 10);
        }
        case 'agents': return this.filterOptions.agents.length;
        case 'groups': return this.filterOptions.groups.length;
        default: return null;
      }
    },
    getDistributionLabels(timestamps) {
      switch (this.distributionInterval) {
        case intervalOptions.HOUR:
          return timestamps.map((t) => moment(t).format('HH:mm'));
        case intervalOptions.DAY:
          return timestamps.map((t) => moment(t).format('DD/MM/YYYY'));
        case intervalOptions.MONTH:
          return timestamps.map((t) => moment(t).format('MM/YYYY'));
        case intervalOptions.FULL:
          return timestamps.map((t) => moment(t).format('DD/MM/YYYY'));
        default:
          return null;
      }
    },
    openConversation(item) {
      this.$router.push({ name: 'call-single', params: { callId: item.id } });
    },
    agentIdClicked(id) {
      const agentFilter = this.extraFilters.find((e) => e.key === 'agent');
      if (!this.extraSelectedFilters.find((e) => e.key === 'agent')) {
        this.$refs.statisticsFilter.addExtraFilterLocal(agentFilter);
      }
      this.setFilter({ prop: 'agent', value: [id] });
      this.$nextTick(() => {
        this.needsRefresh = false;
        this.fetchStatistics('conversationInsights');
        this.fetchConversationPerformanceItems(this.showTopConversations);
      });
    },
    topicClicked(topic) {
      const topicFilter = this.extraFilters.find((e) => e.key === 'tag');

      if (!this.extraSelectedFilters.find((e) => e.key === 'tag')) {
        this.$refs.statisticsFilter.addExtraFilterLocal(topicFilter);
      }
      this.setFilter({ prop: 'tag', value: [topic] });
      this.$nextTick(() => {
        this.needsRefresh = false;
        this.fetchStatistics('conversationInsights');
        this.fetchConversationPerformanceItems(this.showTopConversations);
      });
    },
  },
};
</script>
<style scoped>
.switch-btn{
  width: 35px;
  height: 35px;
}
::v-deep .id-col{
  min-width: 60px;
}
::v-deep .topic-col{
  min-width: 100px;
}
.big-badge {
  scale: 1.4;
}
</style>
