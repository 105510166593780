<template>
  <div class="px-0 container-fluid h-100">
    <b-row
      v-if="isFetching"
      class="h-100 align-items-center"
    >
      <b-col class="text-center">
        <b-spinner
          style="width: 5rem; height: 5rem;"
        />
      </b-col>
    </b-row>

    <router-view v-else />
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
  name: 'ConnectorBase',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchConnectors();
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'connector-overview') {
      this.fetchConnectors();
    }
    next();
  },
  computed: {
    ...mapState('connector', ['isFetching']),
  },
  methods: {
    ...mapActions('connector', { fetchConnectors: 'fetchItems' }),
  },
};
</script>
