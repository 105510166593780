<template>
  <div>
    <b-form-checkbox v-model="advanced" switch>
      Advanced search
    </b-form-checkbox>
    <b-form-input
      v-if="!advanced"
      :value="simpleSearchValue"
      class="mt-2"
      placeholder="Type word to search"
      @input="updateSimpleSearchValue"
    />
    <div v-else class="mt-1">
      <b-row class="align-items-center">
        <b-col cols="12" class="mb-1">
          <b-form-checkbox v-model="stemsAndSynonyms" switch>
            Stems and synonyms
          </b-form-checkbox>
        </b-col>
      </b-row>
      <h5>
        Search expression
        <font-awesome-icon
          id="search-expression-explanation"
          icon="circle-question"
          class="text-secondary"
        />
        <b-popover
          target="search-expression-explanation"
          triggers="hover"
          placement="bottom"
          boundary="viewport"
          custom-class="exp-desc"
        >
          <template #title>
            Search expression explanation
          </template>
          <h5>Buttons:</h5>
          <strong>Add Expression:</strong> Add a new search condition. Use '+' to nest within the
          current one.<br />
          <strong>Remove All Expressions:</strong> Clear all search conditions.
          <h5 class="mt-2">
            Operators:
          </h5>
          <strong>OR:</strong> Match at least one of the listed conditions.<br />
          <strong>AND:</strong> Combine multiple conditions that must all be met.<br />
          <br />
          <strong>Negate (!):</strong> Exclude matching results.
          <h5 class="mt-2">
            Speaker filter:
          </h5>
          Search for words spoken exclusively by the <strong>visitor</strong>, the
          <strong>agent</strong>, or <strong>both</strong>.
          <strong>Inherit</strong> adopts speaker filter from the parent expression.
        </b-popover>:
      </h5>
      <b-row no-gutters class="pt-1 align-items-end search-exp-inner">
        <b-col cols="auto" class="pr-2">
          <b-button
            v-b-tooltip.hover.noninteractive="'Negate expression. Click to toggle'"
            :variant="negate ? 'primary' : ''"
            style="width: 2rem"
            @click="toggleNegate"
          >
            <font-awesome-icon icon="exclamation" />
          </b-button>
        </b-col>
        <b-col cols="auto" class="pr-2">
          <b-input-group>
            <b-input-group-prepend
              v-b-tooltip.noninteractive="'Operator'"
              is-text
            >
              <font-awesome-icon icon="gears" />
            </b-input-group-prepend>
            <b-form-select v-model="operator" :options="searchOperatorTypes" />
          </b-input-group>
        </b-col>
        <b-col cols="auto" class="pr-2">
          <b-input-group>
            <b-input-group-prepend
              v-b-tooltip.noninteractive="'Speaker filter'"
              is-text
            >
              <font-awesome-icon icon="filter" />
            </b-input-group-prepend>
            <b-form-select v-model="speakerFilter" :options="speakerFilterOptions" />
          </b-input-group>
        </b-col>
        <b-col class="pr-2">
          <b-input-group @click.stop>
            <b-form-tags
              :value="values"
              :placeholder="`Enter word${operator === 'WORD' ? '' : 's'} to search`"
              :limit="operator === 'WORD' ? 1 : null"
              limit-tags-text="Word limit reached"
              @input="(newValues) => { values = newValues }"
            />
            <b-input-group-append v-if="values?.length">
              <b-button
                v-b-tooltip.noninteractive
                title="Clear words"
                @click="values = []"
              >
                <font-awesome-icon icon="times" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <div style="width: 800px;">
        <div class="search-exp-tree">
          <div class="search-exp-list ml-0">
            <SearchExpression
              v-for="exp in searchExpression.expressions"
              :id="exp.id"
              :key="exp.id"
            />
          </div>
        </div>
        <div v-if="operator !== 'WORD'" class="mt-2">
          <b-button
            variant="primary"
            sm
            @click="newExpression"
          >
            <font-awesome-icon icon="plus" />
            Add expression
          </b-button>
          <b-button
            variant="danger"
            class="ml-2"
            @click.stop="clearExpression"
          >
            <font-awesome-icon icon="times" />
            Remove all expressions
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchExpression from '@/components/SearchExpression.vue';
import { mapGetters, mapMutations } from 'vuex';
import { cloneDeep } from 'lodash';
import { searchOperatorTypes } from '@/js/constants';
import { guidGenerator } from 'supwiz/util/data';

export default {
  name: 'CustomSearch',
  components: {
    SearchExpression,
  },
  data() {
    return {
      searchOperatorTypes,
      speakerFilterOptions: [
        { text: 'Agent', value: 'agent' },
        { text: 'Visitor', value: 'visitor' },
        { text: 'Agent and visitor', value: 'both' },
      ],
    };
  },
  computed: {
    ...mapGetters('statisticsFiltersStore', ['filters']),
    advanced: {
      get() {
        return this.filters.search.advanced;
      },
      set(v) {
        const searchCopy = cloneDeep(this.filters.search);
        searchCopy.advanced = v;
        searchCopy.expressions = v ? [] : [''];
        if (!v) {
          searchCopy.stemsAndSynonyms = false;
        }
        this.setFilter({
          prop: 'search',
          value: searchCopy,
        });
      },
    },
    operator: {
      get() {
        return this.filters.search.operator;
      },
      set(v) {
        const searchCopy = cloneDeep(this.filters.search);
        if (v === 'WORD' || searchCopy.operator === 'WORD') {
          searchCopy.expressions = [];
          searchCopy.values = searchCopy.values ? [searchCopy.values[0]] : [];
        }
        searchCopy.operator = v;
        this.setFilter({
          prop: 'search',
          value: searchCopy,
        });
      },
    },
    stemsAndSynonyms: {
      get() {
        return this.filters.search.stemsAndSynonyms;
      },
      set(v) {
        const searchCopy = cloneDeep(this.filters.search);
        searchCopy.stemsAndSynonyms = v;
        this.setFilter({
          prop: 'search',
          value: searchCopy,
        });
      },
    },
    negate: {
      get() {
        return this.filters.search.negate;
      },
      set(v) {
        const searchCopy = cloneDeep(this.filters.search);
        searchCopy.negate = v;
        this.setFilter({
          prop: 'search',
          value: searchCopy,
        });
      },
    },
    speakerFilter: {
      get() {
        return this.filters.search.speakerFilter;
      },
      set(v) {
        const searchCopy = cloneDeep(this.filters.search);
        searchCopy.speakerFilter = v;
        this.setFilter({
          prop: 'search',
          value: searchCopy,
        });
      },
    },
    values: {
      get() {
        return this.filters.search.values;
      },
      set(v) {
        const searchCopy = cloneDeep(this.filters.search);
        searchCopy.values = v;
        this.setFilter({
          prop: 'search',
          value: searchCopy,
        });
      },
    },
    searchExpression() {
      return this.filters.search;
    },
    simpleSearchValue() {
      return this.filters.search.expressions?.[0] || '';
    },
  },
  methods: {
    ...mapMutations('statisticsFiltersStore', ['setFilter']),
    expressionTemplate() {
      return {
        id: guidGenerator(),
        operator: 'OR',
        negate: false,
        speakerFilter: null,
        values: [''],
        expressions: [],
        indent: 0,
      };
    },
    toggleNegate() {
      this.negate = !this.negate;
    },
    clearExpression() {
      const searchCopy = cloneDeep(this.filters.search);
      searchCopy.expressions = [];
      this.setFilter({
        prop: 'search',
        value: searchCopy,
      });
    },
    newExpression() {
      const searchCopy = cloneDeep(this.filters.search);
      searchCopy.expressions.push(this.expressionTemplate());

      this.setFilter({
        prop: 'search',
        value: searchCopy,
      });
    },
    updateSimpleSearchValue(value) {
      const searchCopy = cloneDeep(this.filters.search);
      searchCopy.expressions = [value];
      this.setFilter({
        prop: 'search',
        value: searchCopy,
      });
    },
  },
};

</script>
<style>
.search-exp-tree {
  --spacing: 1rem;
  --radius: 0px;
}
.search-exp-list {
  margin: 0;
  margin-left: calc(var(--spacing));
  padding: 0;
  position: relative;
}
.search-exp-item {
  border-left: 2px solid #ddd;
  margin-left: 1rem;
}
.search-exp-list .search-exp-inner {
  padding-left: 1rem;
  position: relative
}
.search-exp-list > .search-exp-item:last-child {
  border-color: transparent;
}
.search-exp-item .search-exp-inner::before {
  content      : '';
  position     : absolute;
  top          : 0;
  left         : -2px;
  width        : calc(var(--spacing) + 2px);
  bottom       : 1rem;
  border       : solid #ddd;
  border-width : 0 0 2px 2px;
}
.w-300 {
  width: 600px;
}
</style>
<style scoped>
.exp-desc {
  max-width: 500px !important;
}
</style>
