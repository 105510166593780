<template>
  <section>
    <h3>
      Conversation score
    </h3>
    <b-card
      no-body
    >
      <p class="mb-0">
        On this page you can specify how the final score of conversation
        (ranging from -100% - bad conversation to 100% - good conversation) is calculated.
        These scores are then utilized on the statistics page to provide valuable insights.
        <strong>Remember that the importance of metrics must sum up to 100%.</strong>
      </p>
    </b-card>
    <hr>
    <b-row class="mb-3">
      <b-col>
        <h4>
          Metric
        </h4>
      </b-col>
      <b-col cols="4">
        <h4>
          Importance
        </h4>
      </b-col>
    </b-row>
    <template v-if="analyzerDetails.sentimentAnalysis">
      <b-row>
        <b-col>
          <h5>Sentiment</h5>
          <p class="mb-1 text-muted">
            The sentiment score is based on the sentiment of only the visitor's utterances
            throughout the conversation. In addition, utterances at the end of the conversation
            carry larger weight than at the start. Also, utterances of positive or negative
            sentiment are allowed to carry larger weight than those of neutral sentiment.
          </p>
          <p class="mb-0 text-muted">
            <!-- TODO: Here we want user to be able to control the middle point -->
            A fully positive sentiment corresponds to a conversation score of <code>+100</code>, a
            neutral sentiment to <code>+50</code> and a fully negative to <code>-100</code>.
          </p>
        </b-col>
        <b-col cols="4" class="d-flex align-items-end">
          <b-input-group prepend="Accounts for" append="%">
            <b-form-input v-model="scoreSentimentImportance" type="number" min="0" max="100" />
          </b-input-group>
        </b-col>
      </b-row>
      <hr>
    </template>
    <b-row>
      <b-col>
        <h5>Duration</h5>
        <div class="pb-2" style="height: 200px;">
          <typed-chart
            :chart-data="chartData"
            :options="chartOptions"
            chart-type="line"
            class="h-100"
          />
        </div>
        <b-input-group append="seconds" class="mb-2">
          <b-input-group-prepend is-text>
            <div style="width: 150px">
              Perfect score up to
            </div>
          </b-input-group-prepend>
          <b-form-input v-model="analyzerForm.goodCallDurationCeiling" />
        </b-input-group>
        <b-input-group append="seconds">
          <b-input-group-prepend is-text>
            <div style="width: 150px">
              Lowest score after
            </div>
          </b-input-group-prepend>
          <b-form-input v-model="analyzerForm.goodCallDurationFloor" />
        </b-input-group>
      </b-col>
      <b-col cols="4" class="d-flex align-items-end">
        <b-input-group prepend="Accounts for" append="%">
          <b-form-input v-model="durationImportance" type="number" min="0" max="100" />
        </b-input-group>
      </b-col>
    </b-row>
    <hr>
    <template v-if="false">
      <b-row>
        <b-col>
          <h5>Pauses</h5>
          <edit-key-value
            key-prop="Max pause length of good conversation"
            description=""
            :value-prop="analyzerForm.maxPauseLength"
            :min-key-width="310"
            type="number"
            :state="getFormState()"
            @input="(x) => analyzerForm.maxPauseLength = x"
          />
        </b-col>
        <b-col cols="4" class="d-flex align-items-end">
          <b-input-group prepend="Accounts for" append="%">
            <b-form-input v-model="pauseImportance" type="number" min="0" max="100" />
          </b-input-group>
        </b-col>
      </b-row>
      <hr>
    </template>
    <b-row>
      <b-col>
        <h5>Categories</h5>

        <div>
          <b-button
            v-for="(scoreCategory, index) in analyzerForm.scoreCategories"
            :key="index"
            :variant="scoreCategory.value >= 0 ? 'success' : 'danger'"
            class="mr-2 mb-1"
            @click="editCategory(scoreCategory)"
          >
            {{ categoryId2Name(scoreCategory.category) }}
            | {{ scoreCategory.value }}
            | {{ scoreCategory.conversation_score_importance + "%" }}
            <font-awesome-icon icon="times" @click.stop="removeCategory(scoreCategory)" />
          </b-button>
        </div>
        <b-button
          variant="primary"
          class="px-3"
          @click="$bvModal.show('category-modal')"
        >
          <font-awesome-icon
            icon="plus"
          />
        </b-button>
      </b-col>

      <b-col cols="4" class="d-flex align-items-end">
        <b-input-group prepend="Accounts for" append="%">
          <b-form-input v-model="scoreCategoriesImportance" :disabled="true" type="number" min="0" max="100" />
        </b-input-group>
      </b-col>
    </b-row>
    <hr>

    <b-button
      variant="primary"
      class="mt-3 d-block"
      :style="`width: ${keyWidth}px;`"
      :disabled="!unsavedChanges || invalidImportance"
      @click="updateAnalyzer(analyzerForm)"
    >
      Save Changes
    </b-button>
    <small v-if="invalidImportance" class="text-danger">
      Importance must sum up to 100%
    </small>
    <span
      v-else-if="unsavedChanges"
      class="unsaved-text"
    >
      *Unsaved changes
    </span>
    <b-modal
      id="category-modal"
      :title="`${isNewCategory ? 'Add' : 'Update'} category`"
      :ok-title="isNewCategory ? 'Add' : 'Update'"
      :ok-disabled="$v.newCategory.$invalid"
      @hide="resetCategoryModal"
      @ok="okClicked"
    >
      <edit-key-value
        class="my-3"
        description="Select the department(s) that data will be synchronized from"
        key-prop="Select category"
        :value-prop="newCategory.category"
        :min-key-width="keyWidth"
        :state="!$v.newCategory.category.$invalid"
        :options="filteredCategoryOptions"
        type="select"
        @input="(x) => newCategory.category = x"
      >
        <template #feedback>
          <b-form-invalid-feedback>
            You need to choose a category
          </b-form-invalid-feedback>
        </template>
      </edit-key-value>

      <edit-key-value
        class="my-3"
        key-prop="Category value"
        description="Value between -100 and 100"
        :value-prop="newCategory.value"
        :min-key-width="keyWidth"
        type="number"
        :number-constraints="{ min: -100, max: 100 }"
        :state="!$v.newCategory.value.$invalid"
        @input="(x) => newCategory.value = x"
      >
        <template #feedback>
          <b-form-invalid-feedback v-if="!$v.newCategory.value.required">
            You need to set a value.
          </b-form-invalid-feedback>
          <b-form-invalid-feedback v-else-if="!$v.newCategory.value.between">
            Value must be between -100 and 100
          </b-form-invalid-feedback>
        </template>
      </edit-key-value>

      <edit-key-value
        class="my-3"
        key-prop="Category importance"
        description="Value between 0 and 100"
        :value-prop="newCategory.conversation_score_importance"
        :min-key-width="keyWidth"
        type="number"
        :number-constraints="{ min: 0, max: 100 }"
        :state="!$v.newCategory.conversation_score_importance.$invalid"
        @input="(x) => newCategory.conversation_score_importance = x"
      >
        <template #feedback>
          <b-form-invalid-feedback v-if="!$v.newCategory.conversation_score_importance.required">
            You need to set a value.
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            v-else-if="!$v.newCategory.conversation_score_importance.between"
          >
            Value must be between 0 and 100
          </b-form-invalid-feedback>
        </template>
      </edit-key-value>
    </b-modal>
  </section>
</template>
<script>
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';
import { cloneDeep } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import { required, between } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import moment from 'moment';
import TypedChart from '@/components/typedChart.vue';

export default {
  name: 'ConversationScore',
  components: {
    EditKeyValue, TypedChart,
  },
  mixins: [validationMixin],
  data() {
    return {
      analyzerForm: null,
      isNewCategory: true,
      newCategory: {
        value: null,
        category: null,
        analyzer: null,
        conversation_score_importance: null,
      },
    };
  },
  computed: {
    ...mapGetters('category', ['analyzerCategories']),
    ...mapState('analyzer', { analyzerDetails: 'details' }),
    keyWidth() {
      return 180;
    },
    unsavedChanges() {
      return JSON.stringify(this.analyzerForm) !== JSON.stringify(this.analyzerDetails);
    },
    categoryOptions() {
      return this.analyzerCategories.map((e) => ({ value: e.id, text: e.name }));
    },
    filteredCategoryOptions() {
      return this.categoryOptions.filter((e) => !this.analyzerForm.scoreCategories
        .map((i) => i.category).includes(e.value) || this.newCategory.category === e.value);
    },
    invalidImportance() {
      let importanceSum = parseInt(this.analyzerForm.durationImportance, 10)
        + parseInt(this.scoreCategoriesImportance, 10);
      if (this.analyzerDetails.sentimentAnalysis) {
        importanceSum += parseInt(this.analyzerForm.scoreSentimentImportance, 10);
      }
      return importanceSum !== 100;
    },
    scoreSentimentImportance: {
      get() { return this.analyzerForm.scoreSentimentImportance; },
      set(value) { this.analyzerForm.scoreSentimentImportance = parseInt(value, 10); },
    },
    durationImportance: {
      get() { return this.analyzerForm.durationImportance; },
      set(value) { this.analyzerForm.durationImportance = parseInt(value, 10); },
    },
    pauseImportance: {
      get() { return this.analyzerForm.pauseImportance; },
      set(value) { this.analyzerForm.pauseImportance = parseInt(value, 10); },
    },
    scoreCategoriesImportance: {
      get() {
        const sum = this.analyzerForm.scoreCategories
          .reduce((acc, scoreCat) => acc + scoreCat.conversation_score_importance, 0);
        return sum;
      },
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Duration (minutes)',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Assigned score',
            },
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        },
      };
    },
    chartData() {
      const minSeconds = moment.duration(this.analyzerForm.goodCallDurationCeiling, 'seconds');
      const minMinutes = minSeconds.minutes();
      const maxSeconds = moment.duration(this.analyzerForm.goodCallDurationFloor, 'seconds');
      const maxMinutes = maxSeconds.minutes();
      const labels = [];
      const datasets = [{
        borderRadius: 3,
        borderColor: '#435B66',
        backgroundColor: '#435B66',
        data: [],
      }];
      let lastDefinedValue = null;
      for (let i = Math.max(0, minMinutes - 5); i < maxMinutes + 5; i++) {
        labels.push(i);
        let value;
        if (i < minMinutes) {
          value = 100;
        } else if (i > maxMinutes) {
          value = -100;
        } else {
          if (lastDefinedValue === null) {
            lastDefinedValue = 100;
          }
          value = lastDefinedValue - ((i - minMinutes) * (200 / (maxMinutes - minMinutes)));
        }
        datasets[0].data.push(value);
      }
      return {
        labels,
        datasets,
      };
    },
  },
  watch: {
    unsavedChanges(newVal) {
      this.$emit('unsavedChanges', newVal);
    },
  },
  async created() {
    this.analyzerForm = {
      ...cloneDeep(this.analyzerDetails),
    };
    await this.fetchCategories();
  },
  methods: {
    ...mapActions('category', { fetchCategories: 'fetchItems' }),
    ...mapActions('analyzer', { updateAnalyzer: 'patchItem' }),
    getFormState(invalid, dirty) {
      if (!dirty) {
        return null;
      } if (invalid) {
        return false;
      }
      return true;
    },
    categoryId2Name(id) {
      return this.categoryOptions.find((e) => e.value === id)?.text;
    },
    resetCategoryModal() {
      this.newCategory = {
        value: null,
        category: null,
        analyzer: null,
        conversation_score_importance: null,
      };
      this.isNewCategory = true;
    },
    okClicked() {
      if (this.isNewCategory) {
        this.analyzerForm.scoreCategories.push(
          {
            category: this.newCategory.category,
            value: this.newCategory.value,
            analyzer: this.analyzerForm.id,
            conversation_score_importance: this.newCategory.conversation_score_importance,
          },
        );
      } else {
        const current = this.analyzerForm.scoreCategories
          .find((e) => e.category === this.newCategory.category);
        const index = this.analyzerForm.scoreCategories.indexOf(current);
        this.analyzerForm[index] = {
          category: this.newCategory.category,
          value: this.newCategory.value,
          analyzer: this.analyzerForm.id,
          conversation_score_importance: this.newCategory.conversation_score_importance,
        };
      }
    },
    editCategory(payload) {
      this.newCategory = payload;
      this.isNewCategory = false;

      this.$bvModal.show('category-modal');
    },
    removeCategory(payload) {
      this.analyzerForm.scoreCategories
        .splice(this.analyzerForm.scoreCategories.indexOf(payload), 1);
    },
  },
  validations() {
    return {
      newCategory: {
        category: { required },
        value: {
          required,
          between: between(-100, 100),
        },
        conversation_score_importance: {
          required,
          between: between(0, 100),
        },
      },
    };
  },
};
</script>
