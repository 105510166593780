import axios from 'axios';
import Vue from 'vue';
import endpoints from '@/js/endpoints';

const adminState = {
  groups: [],
  isFetchingGroups: false,
};

const mutations = {
  setGroups(state, groups) {
    Vue.set(state, 'groups', groups);
  },
  setIsFetchingGroups(state, value) {
    state.isFetchingGroups = value;
  },
};

const actions = {
  async fetchGroups({ commit, rootGetters }) {
    commit('setIsFetchingGroups', true);
    const resp = await axios.get(endpoints.groups, rootGetters['auth/headerAuthorization']);
    commit('setGroups', resp.data);
    commit('setIsFetchingGroups', false);
  },
};

export default {
  namespaced: true,
  state: adminState,
  mutations,
  actions,
};
