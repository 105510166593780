<template>
  <b-row
    v-if="!ready"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <div v-else>
    <statistics-filter
      v-if="ready"
      ref="statisticsFilter"
      :metrics="metricOptions"
      :extra-filters="extraFilters"
      :base-filters="baseFilters"
      :configuration="localStatsConfig"
      calendar-tooltip="Select date range for computing statistics"
      @newFilters="(val) => needsRefresh = val"
      @fetchData="fetchStatistics('analytics')"
    >
      <template #filtersHeader>
        <stats-configuration-dropdown
          :default-filter-values="defaultFilterValues"
          title="analytics"
          :filter-source="statisticsConfigSource.ANALYTICS"
          @needsRefresh="v=>needsRefresh = v"
        />
      </template>
      <template #categories>
        <categories />
      </template>
    </statistics-filter>
    <b-overlay
      variant="white stat-overlay shadow"
      :show="computingStats || needsRefresh"
      :opacity="needsRefresh ? '1.0' : '0.7'"
      :no-center="needsRefresh"
      style="min-height:50px"
    >
      <template
        v-if="needsRefresh"
        #overlay
      >
        <h4
          style="padding-top:20px"
          class="my-auto text-center pb-3"
        >
          Click "Show" to refresh statistics.
        </h4>
      </template>
      <div v-if="hasData">
        <b-card
          class="r-75 shadow"
          body-class="p-3"
        >
          <b-list-group>
            <data-display
              v-for="(metric, index) in chosenMetrics"
              :key="index"
              :metric="metric"
            />
          </b-list-group>
        </b-card>
      </div>

      <b-card
        v-else
        class="r-75 shadow"
      >
        <h4 class="mb-0">
          There is no data to show.
        </h4>
      </b-card>
    </b-overlay>
    <new-configuration-modal :source="statisticsConfigSource.ANALYTICS" />
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';

import { cloneDeep } from 'lodash';
import StatisticsFilter from 'supwiz/components/statisticsFilters/StatisticsFilters.vue';
import DataDisplay from '@/components/Analyzer/DataDisplay.vue';
import {
  mappedGroupByOptions, metricOptions, defaultFilterValues, statisticsConfigSource,
} from '@/js/constants';
import Categories from '@/components/Analyzer/Categories.vue';
import NewConfigurationModal from '@/components/Analyzer/NewConfigurationModal.vue';
import StatsConfigurationDropdown from '@/components/Analyzer/StatsConfigurationDropdown.vue';
import { agentAnonymizationMode } from '@/js/featureFlags';
import { getExtraFilters } from '@/js/utils';

export default {
  name: 'AnalyzerStatistics',
  components: {
    StatisticsFilter,
    DataDisplay,
    Categories,
    StatsConfigurationDropdown,
    NewConfigurationModal,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchStatsConfigs({ params: { analyzer: to.params.analyzerId } });
    });
  },
  data: () => ({
    defaultFilterValues,
    statisticsConfigSource,
    showFilters: true,
    needsRefresh: false,
    ready: false,
    metricOptions,
    localStatsConfig: null,
    extraFilters: [],
  }),
  computed: {
    ...mapState('statistics', ['computingStats']),
    ...mapGetters('statisticsFiltersStore', ['filters', 'extraSelectedFilters']),
    ...mapState('statsConfiguration', { statsConfigurations: 'items' }),
    ...mapState('statsConfiguration', { statsConfigurationDetails: 'details' }),
    ...mapGetters('statsConfiguration', ['getSavedStatsConfig']),
    ...mapState('analyzer', { analyzerDetails: 'details' }),
    ...mapGetters('connector', ['typeFromId']),
    ...mapGetters('statistics', ['getStatistics', 'getSavedFilters']),
    statisticsData() {
      return this.getStatistics('analytics');
    },
    anonymizeAgents() {
      return agentAnonymizationMode() === 'FULL';
    },
    baseFilters() {
      const groupOptions = this.anonymizeAgents ? mappedGroupByOptions.filter((e) => e.value !== 'agent_id') : mappedGroupByOptions;
      return [
        {
          key: 'metrics', label: 'Metrics', type: 'multiselect', options: this.metricOptions, draggable: true, tooltip: 'Select which metrics should be computed and in what order',
        },
        {
          key: 'groupBy', label: 'Group by', type: 'select', options: groupOptions, tooltip: 'Select how statistics should be grouped',
        },
      ];
    },
    connectorType() {
      return this.typeFromId(this.analyzerDetails.dataSource);
    },
    isNewConfiguration() {
      return Object.keys(this.statsConfigurationDetails).length === 0;
    },
    chosenMetrics() {
      return this.filters.metrics;
    },
    hasData() {
      if (!Object.values(this.statisticsData).length) {
        return false;
      }
      if (!this.statisticsData.general?.groups) {
        return false;
      }
      return true;
    },
  },
  watch: {
    async statsConfigurationDetails(n) {
      if (n.id !== this.localStatsConfig?.id) {
        this.setConfigProxy(this.statsConfigurationDetails);
      }
    },
  },
  async mounted() {
    await this.fetchFilters();
    // if there is saved stats config, load it
    const savedStatsConfig = this.getSavedStatsConfig('analytics');
    if (savedStatsConfig) {
      await this.setConfigProxy(savedStatsConfig);
      this.setStatsConfigurationDetails(savedStatsConfig);
    }
    // if there are saved filters, load it
    this.$nextTick(() => {
      const savedFilters = this.getSavedFilters('analytics');
      if (savedFilters) {
        this.setFilters(cloneDeep(savedFilters.filters));
        savedFilters.extraSelectedFilters.forEach((item) => {
          const alreadyAdded = this.extraSelectedFilters.find((e) => e.key === item.key);
          if (!alreadyAdded) {
            this.$refs.statisticsFilter.addExtraFilterLocal(item);
          }
        });
      } else {
        // fresh setup
        this.setFilters(cloneDeep(this.defaultFilterValues));
      }
    });
  },
  beforeDestroy() {
    // if there is config selected, save it
    if (this.localStatsConfig) {
      this.setLocalStatsConfig({ key: 'analytics', value: cloneDeep(this.localStatsConfig) });
    }
    // save all current filters
    this.setLocalStatisticsFilters({ key: 'analytics', value: cloneDeep(this.$store.state.statisticsFiltersStore) });
  },
  destroyed() {
    this.setStatsConfigurations({});
    this.setStatsConfigurationDetails({});
  },
  methods: {
    ...mapActions('statsConfiguration', ['setFiltersFromConfig']),
    ...mapMutations('statsConfiguration', ['setLocalStatsConfig']),
    ...mapMutations('statisticsFiltersStore', ['setFilters']),
    ...mapActions('statistics', ['fetchStatistics']),
    ...mapMutations('statistics', ['setLocalStatisticsFilters']),
    ...mapActions('externalSystem', ['fetchGroups', 'fetchFilterValues']),
    ...mapMutations('statsConfiguration', {
      setStatsConfigurations: 'setItems',
      setStatsConfigurationDetails: 'setItemDetails',
    }),
    ...mapActions('statsConfiguration', {
      fetchStatsConfigs: 'fetchItems',
    }),
    ...mapActions('category', { fetchCategories: 'fetchItems' }),
    getExtraFilters,
    async setConfigProxy(payload) {
      this.setFilters(cloneDeep(this.defaultFilterValues));
      const configCopy = cloneDeep(payload);
      await this.setFiltersFromConfig(configCopy);
      this.localStatsConfig = configCopy;
      return true;
    },
    async fetchFilters() {
      try {
        this.ready = false;
        const resp = await this.fetchFilterValues();
        this.filterOptions = resp.data;
        this.filterOptions.groups = await this.fetchGroups();
        this.extraFilters = getExtraFilters(
          this.filterOptions,
          this.analyzerDetails,
          this.connectorType,
        );
        await this.fetchCategories();
      } catch (error) {
        const filterValues = `agents${this.analyzerDetails.classification ? ' and tags' : ''}`;
        this.$store.dispatch('sidebar/showWarning', {
          title: `Failed to fetch filter values (${filterValues})`,
          text: error.message,
        });
        throw error;
      } finally {
        this.ready = true;
      }
    },
  },
};
</script>
<style scoped>

</style>
