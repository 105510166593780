import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'supwiz/styling/supwiz_bootstrap.scss';
import BootstrapVue from 'bootstrap-vue';
// Import and configure icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@/js/icons';

import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import router from '@/router';
import store from './store';
import App from './App.vue';

Vue.config.productionTip = false;
// Register font-awesome-icon globally, so that we don't need to register it within every component.
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.use(BootstrapVue);

Vue.prototype.goTo404Page = () => {
  if (router.history.current.name !== '404') {
    router.push({ name: '404' });
  }
};

sync(store, router);
store.state.$router = router;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
