<template>
  <div>
    <b-card
      class="r-75"
      body-class="p-3"
    >
      <b-row>
        <b-col>
          <h4 class="card-title mb-3 mt-1">
            Analyzers Overview
          </h4>
        </b-col>
        <b-col cols="auto">
          <b-pagination
            v-model="currentPage"
            :total-rows="pagination.count"
            :per-page="pagination.perPage"
            size="sm"
            class="mb-3 mt-1"
            aria-controls="analyzer-table"
          />
        </b-col>
      </b-row>
      <table-data
        id="analyzer-table"
        ref="analyzer-table"
        :items="itemsProvider"
        :fields="fields"
        :current-page="currentPage"
        :per-page="pagination.perPage"
        :deleting-id="deletingId"
        class="mb-0"
        show-empty
        hover
        @delete="deleteAnalyzerLocal"
        @row-clicked="setEditing"
        @add="$root.$emit('bv::show::modal', 'create-analyzer-modal')"
      >
        <template #cell(dataSource)="data">
          {{ nameFromId(data.item.dataSource) }}
        </template>
      </table-data>
    </b-card>
    <b-modal
      id="create-analyzer-modal"
      title="Create New Analyzer"
      scrollable
      hide-footer
      centered
    >
      <general
        @added="refreshTable"
        @hide="$bvModal.hide('create-analyzer-modal')"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';
import TableData from 'supwiz/components/TableData.vue';
import General from '@/components/Analyzer/Configuration/General.vue';
import { languageOptions } from '@/js/constants';
import { objToCamel } from 'supwiz/util/data';

export default {
  name: 'ConnectorOverview',
  components: {
    TableData,
    General,
  },
  data() {
    return {
      fields: [
        { key: 'delete', label: '', tdClass: 'delete-column' },
        { label: 'ID', key: 'id', tdClass: 'align-middle' },
        { label: 'Name', key: 'name' },
        { label: 'Connector', key: 'dataSource' },
        {
          label: 'language',
          key: 'language',
          formatter: (value) => languageOptions.find((l) => l.value === value).text,
        },
      ],
      deletingId: -1,
    };
  },
  computed: {
    ...mapState('analyzer', ['pagination']),
    ...mapGetters('connector', ['nameFromId']),
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.updatePagination({ page: val });
      },
    },
  },
  methods: {
    ...mapActions('analyzer', {
      deleteAnalyzer: 'deleteItem',
      fetchAnalyzers: 'fetchItems',
    }),
    ...mapMutations('analyzer', ['updatePagination']),
    async itemsProvider() {
      const analyzers = await this.fetchAnalyzers({ params: { page: this.pagination.page } });
      return Object.values(analyzers.map((e) => objToCamel(e)));
    },
    setEditing(analyzer) {
      this.$router.push({ name: 'analyzer-single', params: { analyzerId: analyzer.id } });
    },
    refreshTable() {
      this.$nextTick(() => {
        this.$refs['analyzer-table'].$refs.table.refresh();
      });
    },
    async deleteAnalyzerLocal(data) {
      const modalText = 'Are you sure that you want to delete this analyzer?';
      const modalOptions = { okTitle: 'Delete', okVariant: 'danger' };
      if (await this.$bvModal.msgBoxConfirm(modalText, modalOptions)) {
        this.deletingId = data.id;
        await this.deleteAnalyzer({ item: data });
        this.refreshTable();
        this.deletingId = -1;
      }
    },

  },
};
</script>
