<template>
  <section>
    <h3>Transcription</h3>
    <b-card no-body>
      <p class="mb-0">
        After synchronization of data, the data is transcribed.
        These settings define how and how much to transcribe of each call.
      </p>
    </b-card>
    <hr>
    <b-overlay
      :show="isFetchingTranscribeModels"
    >
      <edit-key-value
        class="my-3"
        key-prop="Transcribe model"
        description="Choose the model used for transcribing audio data to text"
        :value-prop="selectedTranscribeModel"
        :min-key-width="keyWidth"
        :options="transcribeModelOptions"
        :disabled="isFetchingTranscribeModels"
        type="select"
        @input="(x)=>selectedTranscribeModel = x"
      />
    </b-overlay>
    <edit-key-value
      class="my-3"
      key-prop="Default visitor speaker"
      :description="connectorAudioConfig === connectorAudioConfigs.STEREO.value
        ? 'Choose which channel of the call audio should be used for the visitor speaker'
        : `The audio format does not contain any information about the participants of the
          call (who is speaking). Choose whether the end user usually speaks as the first or second
          person in a call. The reverse of this setting will be used for outbound calls`"
      :value-prop="analyzerForm.defaultVisitorSpeakerId"
      :min-key-width="keyWidth"
      :options="connectorAudioConfig === connectorAudioConfigs.STEREO.value
        ? speakerOptionsStereo : speakerOptionsMono"
      type="select"
      @input="(x)=>analyzerForm.defaultVisitorSpeakerId = x"
    />
    <div
      v-b-popover.hover.top="'Define how much should be fully transcribed and how much should be'
        + ' partially transcribed'"
      class="d-block bg-primary text-center text-white table-label py-1"
      :style="`width: ${keyWidth}px;`"
    >
      Transcribe duration
    </div>
    <table-data
      class="mb-0"
      small
      add-button-text="Add setting"
      sticky-header
      :fields="transcriptFields"
      :items="transcriptItems"
      :add-button-width="keyWidth"
      show-empty
      empty-text="There are no transcript settings to show"
      @add="addTranscriptSetting"
    >
      <template #head(percentage)="data">
        <span
          v-b-popover.hover.right="data.field.description"
        >
          {{ data.label }}
        </span>
      </template>
      <template #head(duration)="data">
        <span
          v-b-popover.hover.right="data.field.description"
        >
          {{ data.label }}
        </span>
      </template>
      <template #head(fullTranscript)="data">
        <span
          v-b-popover.hover.right="data.field.description"
        >
          {{ data.label }}
        </span>
      </template>
      <template #cell(remove)="data">
        <b-button
          size="sm"
          variant="outline-secondary"
          class="action-button p-0 no-border"
          @click="deleteTranscriptSetting(data.index)"
        >
          <font-awesome-icon
            icon="trash-alt"
            style="transform:scale(1.5) translateY(1px)"
          />
        </b-button>
      </template>
      <template #cell(percentage)="data">
        <b-input-group
          size="sm"
        >
          <b-form-input
            v-model="data.item.percentage"
            type="number"
            size="sm"
            min="0"
            :state="!$v.transcriptItems.$each[data.index].percentage.$invalid"
            :max="availableTranscriptPercentage(data.index)"
            :number="true"
          />
          <b-input-group-append>
            <b-input-group-text class="r-25-right">
              %
            </b-input-group-text>
          </b-input-group-append>
        </b-input-group>
      </template>
      <template #cell(duration)="data">
        <b-form-input
          v-if="isFullTranscript(data.item.duration)"
          size="sm"
          :state="true"
          placeholder="Full transcript"
          disabled
          class="w-100"
        />
        <b-input-group
          v-else
          size="sm"
        >
          <b-form-input
            v-model="data.item.duration"
            type="number"
            size="sm"
            min="0"
            :state="!$v.transcriptItems.$each[data.index].duration.$invalid"
            :number="true"
          />
          <b-input-group-append>
            <b-input-group-text class="r-25-right">
              seconds
            </b-input-group-text>
          </b-input-group-append>
          <b-form-invalid-feedback
            v-if="!$v.transcriptItems.$each[data.index].duration.required
              || !$v.transcriptItems.$each[data.index].duration.posivitiveNumber"
          >
            You must enter a positive number.
          </b-form-invalid-feedback>
          <b-form-invalid-feedback
            v-if="!$v.transcriptItems.$each[data.index].duration.isUnique"
          >
            Transcript length must be unique.
          </b-form-invalid-feedback>
        </b-input-group>
      </template>
      <template #cell(fullTranscript)="data">
        <b-form-checkbox
          :checked="data.item.duration === -1"
          class="mt-1"
          :disabled="fullTranscriptDisabled(data.item)"
          @input="(v)=>updateFullTranscript(v, data.index)"
        />
      </template>
      <template
        v-if="!isHundred()"
        #table-caption
      >
        <span class="text-danger">
          Percentage must sum up to 100.
        </span>
      </template>
    </table-data>

    <b-button
      variant="primary"
      class="mt-3 d-block"
      :style="`width: ${keyWidth}px;`"
      :disabled="!unsavedChanges || $v.transcriptItems.$invalid"
      @click="updateAnalyzer(analyzerForm)"
    >
      Save Changes
    </b-button>
    <span
      v-if="unsavedChanges"
      class="unsaved-text"
    >
      *Unsaved changes
    </span>
  </section>
</template>
<script>
import { validationMixin } from 'vuelidate';
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';
import { cloneDeep } from 'lodash';
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters, mapState } from 'vuex';
import TableData from 'supwiz/components/TableData.vue';
import endpoints from '@/js/endpoints';
import { connectorAudioConfigs } from '@/js/constants';

export default {
  name: 'Transcription',
  components: {
    EditKeyValue, TableData,
  },
  mixins: [validationMixin],
  data() {
    return {
      connectorAudioConfigs,
      analyzerForm: null,
      speakerOptionsMono: [
        { text: 'First speaker in call', value: 0 },
        { text: 'Second speaker in call', value: 1, default: true },
      ],
      speakerOptionsStereo: [
        { text: 'Left channel in call audio', value: 0 },
        { text: 'Right channel in call audio', value: 1, default: true },
      ],
      transcriptFields: [
        { key: 'remove', label: '', tdClass: 'delete-column' },
        {
          key: 'percentage', label: 'Percentage to transcribe', tdClass: 'align-top w-25', description: 'The percentage of calls that will be transcribed for the chosen duration',
        },
        {
          key: 'duration', label: 'Duration to transcribe', tdClass: 'align-top', description: 'In seconds from the start of the call, how much of the call will be included',
        },
        {
          key: 'fullTranscript', label: 'Full transcript', tdClass: 'align-top transcript-col', thClass: 'transcript-col', description: 'Whether to include the whole call',
        },
      ],
      transcribeModels: null,
      isFetchingTranscribeModels: false,
    };
  },
  computed: {
    ...mapState('analyzer', { analyzerDetails: 'details' }),
    ...mapGetters('auth', ['headerAuthorization']),
    ...mapGetters('connector', ['audioConfigFromId']),
    connectorAudioConfig() {
      return this.audioConfigFromId(this.analyzerDetails.dataSource);
    },
    keyWidth() {
      return 220;
    },
    unsavedChanges() {
      return JSON.stringify(this.analyzerForm) !== JSON.stringify(this.analyzerDetails);
    },
    transcriptItems() {
      return this.analyzerForm.transcribeDuration;
    },
    hasClassifier() {
      return this.analyzer.classifier;
    },
    transcribeModelOptions() {
      if (this.transcribeModels === null) {
        return [];
      }
      const models = Object.values(this.transcribeModels.custom).map(
        (e) => ({ value: e.self, text: this.modelText(e) }),
      );
      const baseModels = Object.values(this.transcribeModels.base).map(
        (e) => ({ value: e.self, text: this.modelText(e) }),
      );
      models.push({ label: 'Base models', options: baseModels });
      models.unshift({ value: null, text: 'Newest base model (default)' });
      return models;
    },
    selectedTranscribeModel: {
      get() {
        const selected = this.analyzerForm?.transcribeModel?.self;
        return selected === undefined ? null : selected;
      },
      set(value) {
        this.analyzerForm.transcribeModel = value in this.transcribeModels.base
          ? this.transcribeModels.base[value] : this.transcribeModels.custom[value];
      },
    },
  },
  watch: {
    unsavedChanges(newVal) {
      this.$emit('unsavedChanges', newVal);
    },
  },
  async created() {
    this.analyzerForm = cloneDeep(this.analyzerDetails);
    await this.fetchTranscribeModels();
  },
  methods: {
    ...mapActions('analyzer', { updateAnalyzer: 'patchItem' }),
    ...mapActions('sidebar', ['showWarning']),
    modelText(model) {
      return `${model.name} (${model.locale})`;
    },
    isHundred() {
      return this.transcriptItems.length ? this.transcriptItems.map((e) => e.percentage)
        .reduce((a, b) => a + b) === 100 : true;
    },
    availableTranscriptPercentage(index) {
      const copy = [...this.transcriptItems];
      copy.splice(index, 1);
      const test = copy.map((e) => e.percentage)
        .reduce((partialSum, a) => partialSum + a, 0);
      return 100 - test;
    },
    isFullTranscript(duration) {
      return duration === -1;
    },
    fullTranscriptDisabled(item) {
      return !!this.analyzerForm.transcribeDuration.filter((e) => e.duration === -1).length
      && item.duration !== -1;
    },
    addTranscriptSetting() {
      this.analyzerForm.transcribeDuration
        .push({ percentage: 0, duration: 0 });
    },
    deleteTranscriptSetting(index) {
      this.analyzerForm.transcribeDuration.splice(index, 1);
    },
    updateFullTranscript(v, index) {
      this.transcriptItems[index].duration = v ? -1 : 0;
    },
    async fetchTranscribeModels() {
      if (this.transcribeModels === null) {
        try {
          this.isFetchingTranscribeModels = true;
          this.transcribeModels = null;
          const request = { ...this.headerAuthorization };
          const { data: customData } = await axios.get(`${endpoints.analyzer}${this.analyzerDetails.id}/transcribe_models/`, request);
          const { data: baseData } = await axios.get(`${endpoints.analyzer}${this.analyzerDetails.id}/transcribe_models/`, { ...request, params: { base_models: true } });
          this.transcribeModels = {
            base: Object.fromEntries(baseData.map((x) => [x.self, x])),
            custom: Object.fromEntries(customData.map((x) => [x.self, x])),
          };
          this.isFetchingTranscribeModels = false;
        } catch (error) {
          this.showWarning({
            title: 'Failed to fetch transcribe models',
            text: error.message,
          });
        }
      }
    },
  },
  validations() {
    return {
      transcriptItems: {
        $each: {
          percentage: {
            isHundred() { return this.isHundred(); },
          },
          duration: {
            required,
            isUnique(value) {
              return this.transcriptItems
                .filter((e) => e.duration === value).length === 1;
            },
            posivitiveNumber(value) {
              return value > -2;
            },
          },
        },
      },
    };
  },
};
</script>
<style scoped>
::v-deep .transcript-col{
  width:120px;
}
</style>
