<template>
  <div class="search-exp-item" @click.stop>
    <b-row no-gutters class="pt-1 align-items-end search-exp-inner">
      <b-col cols="auto" class="pr-2">
        <b-button
          v-b-tooltip.hover.noninteractive="'Negate expression. Click to toggle'"
          :variant="exp.negate ? 'primary' : ''"
          style="width: 2rem"
          @click="toggleNegate"
        >
          <font-awesome-icon icon="exclamation" />
        </b-button>
      </b-col>
      <b-col cols="auto" class="pr-2">
        <b-input-group>
          <b-input-group-prepend
            v-b-tooltip.noninteractive="'Operator'"
            is-text
          >
            <font-awesome-icon icon="gears" />
          </b-input-group-prepend>
          <b-form-select v-model="operator" :options="searchOperatorTypes" />
        </b-input-group>
      </b-col>
      <b-col cols="auto" class="pr-2">
        <b-input-group>
          <b-input-group-prepend
            v-b-tooltip.noninteractive="'Speaker filter'"
            is-text
          >
            <font-awesome-icon icon="filter" />
          </b-input-group-prepend>
          <b-form-select v-model="speakerFilter" :options="speakerFilterOptions" />
        </b-input-group>
      </b-col>
      <b-col class="pr-2">
        <b-input-group>
          <b-form-tags
            :value="exp.values"
            :placeholder="`Enter word${exp.operator === 'WORD' ? '' : 's'} to search`"
            :limit="exp.operator === 'WORD' ? 1 : null"
            limit-tags-text="Word limit reached"
            @input="updateValues"
          />
          <b-input-group-append v-if="exp.values.length > 0">
            <b-button
              v-b-tooltip.noninteractive
              title="Clear words"
              @click="exp.values = []"
            >
              <font-awesome-icon icon="times" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="auto">
        <b-button
          v-if="exp.operator !== 'WORD'"
          v-b-tooltip.noninteractive
          title="Add nested expression"
          variant="primary"
          @click="addNestedExpression(exp)"
        >
          <font-awesome-icon icon="plus" />
        </b-button>
        <b-button
          v-b-tooltip.noninteractive
          class="ml-1"
          title="Remove expression"
          variant="danger"
          @click.stop="deleteExpression(exp)"
        >
          <font-awesome-icon icon="times" />
        </b-button>
      </b-col>
    </b-row>
    <div class="search-exp-list">
      <SearchExpression
        v-for="nestedExp in exp.expressions"
        :id="nestedExp.id"
        :key="nestedExp.id"
        :parent-id="id"
      />
    </div>
  </div>
</template>
<script>
import { guidGenerator } from 'supwiz/util/data';
import { mapGetters, mapMutations } from 'vuex';
import { cloneDeep } from 'lodash';
import { searchOperatorTypes } from '@/js/constants';

export default {
  name: 'SearchExpression',
  props: {
    id: {
      type: String,
      required: true,
    },
    parentId: {
      type: [String, Object],
      default: () => null,
    },
  },
  data() {
    return {
      searchOperatorTypes,
      speakerFilterOptions: [
        { text: 'Inherit', value: null },
        { text: 'Agent', value: 'agent' },
        { text: 'Visitor', value: 'visitor' },
        { text: 'Agent and visitor', value: 'both' },
      ],
    };
  },
  computed: {
    ...mapGetters('statisticsFiltersStore', ['filters']),
    expressions() {
      return this.filters.search.expressions;
    },
    exp() {
      return this.getExpression(this.id, this.expressions);
    },
    operator: {
      get() {
        return this.exp.operator;
      },
      set(v) {
        const searchCopy = cloneDeep(this.filters.search);
        const current = this.getExpression(this.id, searchCopy.expressions);
        current.operator = v;
        // Clear expressions if changing to "WORD" operator
        if (v === 'WORD') {
          current.expressions = [];
          current.values = current.values ? [current.values[0]] : [];
        }
        this.setFilter({
          prop: 'search',
          value: searchCopy,
        });
      },
    },
    speakerFilter: {
      get() {
        return this.exp.speakerFilter;
      },
      set(v) {
        const searchCopy = cloneDeep(this.filters.search);
        const current = this.getExpression(this.id, searchCopy.expressions);
        current.speakerFilter = v;
        this.setFilter({
          prop: 'search',
          value: searchCopy,
        });
      },
    },
  },
  methods: {
    ...mapMutations('statisticsFiltersStore', ['setFilter']),
    expressionTemplate() {
      return {
        id: guidGenerator(),
        operator: 'OR',
        negate: false,
        speakerFilter: null,
        values: [''],
        expressions: [],
      };
    },
    toggleType() {
      const searchCopy = cloneDeep(this.filters.search);
      const current = this.getExpression(this.id, searchCopy.expressions);
      current.isExpression = !current.isExpression;
      current.values = current.isExpression ? [] : [''];
      this.setFilter({
        prop: 'search',
        value: searchCopy,
      });
    },
    getExpression(id, expressions) {
      const result = expressions.find((e) => e.id === id);
      if (result) {
        return result;
      }
      for (const e of expressions) {
        if (e?.expressions?.length > 0) {
          const foundExpression = this.getExpression(id, e.expressions);
          if (foundExpression) {
            return foundExpression;
          }
        }
      }
      return null;
    },
    updateValues(values) {
      const searchCopy = cloneDeep(this.filters.search);
      const current = this.getExpression(this.exp.id, searchCopy.expressions);
      current.values = values;
      this.setFilter({
        prop: 'search',
        value: searchCopy,
      });
    },
    toggleNegate() {
      const searchCopy = cloneDeep(this.filters.search);
      const current = this.getExpression(this.exp.id, searchCopy.expressions);
      current.negate = !current.negate;
      this.setFilter({
        prop: 'search',
        value: searchCopy,
      });
    },
    addNestedExpression(exp) {
      const searchCopy = cloneDeep(this.filters.search);
      const current = this.getExpression(exp.id, searchCopy.expressions);
      const newExp = this.expressionTemplate();
      newExp.indent = 10;
      current.expressions.push(newExp);
      this.setFilter({
        prop: 'search',
        value: searchCopy,
      });
    },
    deleteExpression(exp) {
      const searchCopy = cloneDeep(this.filters.search);
      if (this.parentId) {
        const current = this.getExpression(this.parentId, searchCopy.expressions);
        current.expressions = current.expressions.filter((e) => e.id !== exp.id);
      } else {
        searchCopy.expressions = searchCopy.expressions.filter((e) => e.id !== exp.id);
      }
      this.setFilter({
        prop: 'search',
        value: searchCopy,
      });
    },
  },
};

</script>
