<template>
  <div class="w-100 my-1">
    <b-row no-gutters>
      <b-col cols="auto">
        <b-button-group
          vertical
          class="h-100"
        >
          <b-button
            v-for="(v, i) in Object.entries(itemTypes)"
            :key="i"
            size="sm"
            :style="selected === v[0] ? getStyle(v[0]) : ''"
            @click="selected = v[0]"
          >
            {{ v[1] }}
          </b-button>
        </b-button-group>
      </b-col>
      <b-col
        class="border r-25 pl-1 mx-1"
      >
        <div
          class="d-flex flex-wrap"
        >
          <div
            v-for="(element, i) in formattedItems"
            :key="i"
            class="d-inline-flex align-items-center r-25 mr-1 mt-1 p-0"
            :style="getStyle(element.type)"
          >
            <span class="text-md-center pl-2">
              {{ element.value }}
            </span>
            <b-button
              variant="link"
              class="text-decoration-none text-white"
              @click="deleteItem(element.type, index)"
            >
              <font-awesome-icon
                icon="times"
                style="transform: scale(.95)"
              />
            </b-button>
          </div>
          <div
            class="flex-grow-1 bg-transparent w-100"
          >
            <b-form-input
              v-model="value"
              :placeholder="`Add ${itemTypes[selected]}`"
              class="bg-transparent shadow-none border-0"
              @keydown.enter="addItem()"
            />
          </div>
        </div>
      </b-col>
      <b-col cols="auto">
        <b-button
          v-b-tooltip.noninteractive
          class="h-100"
          title="Delete List"
          @click="deleteList()"
        >
          <font-awesome-icon icon="times" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  name: 'Selector',
  mixins: [validationMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
    activeItem: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selected: 'phrasesAllowed',
      itemTypes: { },
      colorMap: {
        phrasesAllowed: '#2cb74c',
        phrasesDisallowed: '#de2d0f',
        tagsAllowed: '#1d7831',
        tagsDisallowed: '#941e0a',
      },
      value: '',
    };
  },
  computed: {
    ...mapGetters('category', ['analyzerCategories']),
    formattedItems() {
      if (this.type === 'include') {
        return this.activeItem.lines.phrasesAllowed[this.index]?.map((e) => ({ value: e, type: 'phrasesAllowed' }))
          .concat(this.activeItem.lines.tagsAllowed[this.index]?.map((e) => ({ value: e, type: 'tagsAllowed' })));
      }
      return this.activeItem.lines.phrasesDisallowed[this.index]?.map((e) => ({ value: e, type: 'phrasesDisallowed' }))
        .concat(this.activeItem.lines.tagsDisallowed[this.index]?.map((e) => ({ value: e, type: 'tagsDisallowed' })));
    },
  },
  mounted() {
    if (this.type === 'include') {
      this.selected = 'phrasesAllowed';
      this.itemTypes = {
        phrasesAllowed: 'text',
        tagsAllowed: 'tag',
      };
    } else {
      this.selected = 'phrasesDisallowed';
      this.itemTypes = {
        phrasesDisallowed: 'text',
        tagsDisallowed: 'tag',
      };
    }
  },
  methods: {
    addItem() {
      if (this.value.trim().length !== 0) {
        this.$emit('add', { value: this.value, type: this.selected });
        this.value = '';
      }
    },
    deleteItem(type, index) {
      this.$emit('delete-item', { type, index });
    },
    deleteList() {
      this.$emit('delete-list', null);
    },
    getStyle(type) {
      return `background-color: ${this.colorMap[type]}; color: white`;
    },
  },
  validations() {
    return {
      activeItem: {
        name: {
          required,
        },
      },
    };
  },
};
</script>
