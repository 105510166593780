<template>
  <b-list-group-item
    class="d-flex py-1 px-0 border-0"
    :class="{ 'flex-column': field.stack }"
    flush
  >
    <div
      v-if="!field.hideKey"
      v-b-tooltip.noninteractive
      class="pl-3 text-truncate text-muted text-capitalize pr-0"
      :class="{ 'col-6': !field.stack }"
      :title="field.keyFormatted || field.key"
    >
      <div
        v-if="!field.hideIcon"
        class="field-icon-wrap d-inline-block text-center"
      >
        <font-awesome-icon
          v-if="fieldIcon"
          :icon="fieldIcon"
        />
      </div>
      {{ field.keyFormatted || field.key }}
    </div>
    <div
      class="font-weight-normal text-break pr-3"
      :class="{
        'col pl-0': !field.stack,
        'pl-3': field.stack,
        'text-danger': field.negative,
      }"
    >
      <template v-if="field.key === 'categoriesScore'">
        <b-badge v-for="(item, index) in field.value" :key="index" class="mr-1">
          {{ item.key }} | {{ item.value.score }}
          ({{ item.value.rawScore }} × {{ item.value.normalizedImportance }}%)
        </b-badge>
      </template>
      <template v-else-if="field.key === 'categories'">
        <b-badge v-for="(item, index) in field.value" :key="index" class="mr-1">
          {{ item.name }}
        </b-badge>
      </template>
      <template v-else-if="field.key === 'tag'">
        <b-badge v-if="field.value.main" class="mr-1" variant="primary">
          {{ field.value.main }}
        </b-badge>
        <b-badge v-if="field.value.sub" class="mr-1">
          {{ field.value.sub }}
        </b-badge>
      </template>
      <b-link
        v-else-if="field.key === 'externalUrl'"
        class="text-break"
        rel="noopener noreferrer"
        target="_blank"
        :href="field.value"
      >
        {{ field.value }}
        <font-awesome-icon
          icon="external-link-alt"
        />
      </b-link>
      <template v-else-if="field.key === 'prediction' || field.key === 'subprediction'">
        <PredictionsDisplay
          v-if="Object.keys(field.value).length"
          :predictions="field.value"
        />
      </template>
      <template v-else-if="field.value === ''">
        <span class="text-muted font-italic">Empty</span>
      </template>
      <template v-else-if="typeof field.value === 'boolean'">
        {{ field.value ? 'True' : 'False' }}
      </template>
      <template v-else>
        {{ field.value }}
      </template>
    </div>
  </b-list-group-item>
</template>

<script>
import PredictionsDisplay from './PredictionsDisplay.vue';

const metadataIconMap = {
  fallback: 'info',
  link: 'link',
  url: 'link',
  department: 'building',
  agent: 'user-tie',
  /* name: 'user',
  mail: 'at',
  ticket: 'ticket',
  tel: 'phone',
  phone: 'phone',
  device: 'mobile-screen',
  os: 'mobile-screen',
  browser: 'globe', */
};

export default {
  name: 'MetaField',
  components: { PredictionsDisplay },
  props: {
    field: {
      required: true,
      type: Object,
    },
  },
  computed: {
    fieldIcon() {
      return this.getMetadataIcon(this.field.key);
    },
  },
  methods: {
    getMetadataIcon(key) {
      const lKey = `${key}`.toLowerCase();
      if (metadataIconMap[lKey]) return metadataIconMap[lKey];
      const iconKeys = Object.keys(metadataIconMap);
      const matchingKey = iconKeys.find((iKey) => lKey.includes(iKey));
      return metadataIconMap[matchingKey] || metadataIconMap.fallback;
    },
  },
};
</script>

<style scoped>
.field-icon-wrap {
  width: 20px;
}
</style>
