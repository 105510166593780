import { connectorAudioConfigs } from '@/js/constants';

const ftpFields = Object.freeze([
  {
    key: 'endpointUrl',
    label: 'FTP Host',
    description: '',
    type: 'input',
    editOnly: false,
  },
  {
    key: 'port',
    label: 'FTP Port',
    description: '',
    type: 'number',
    editOnly: false,
  },
  {
    key: 'username',
    label: 'FTP Username',
    description: '',
    type: 'input',
    editOnly: false,
  },
  {
    key: 'password',
    label: 'FTP Password',
    description: 'Leave empty to omit in update',
    type: 'password',
    editOnly: false,
  },
  {
    key: 'recordingsPath',
    label: 'Recordings Path',
    description: 'Path to call recordings on FTP server',
    type: 'input',
    editOnly: false,
  },
  {
    key: 'metadataPath',
    label: 'Metadata Path',
    description: 'Path to call metadata on FTP server',
    type: 'input',
    editOnly: false,
  },
  {
    key: 'audioConfig',
    label: 'Audio Config',
    description: 'Select whether incoming call audio is in stereo or mono',
    type: 'select',
    options: connectorAudioConfigs,
    editOnly: false,
  },
]);
const extraConnectorFields = Object.freeze({
  zendeskdatasource: [
    {
      key: 'endpointUrl',
      label: 'Endpoint URL',
      description: 'URL to Zendesk API. Example: https://somename.zendesk.com/',
      type: 'input',
      editOnly: false,
    },
    {
      key: 'credentials',
      label: 'OAuth token',
      description: 'Leave empty to omit in update',
      type: 'input',
      editOnly: false,
    },
  ],
  puzzeldatasource: ftpFields.concat([
    {
      key: 'customerKey',
      label: ' Customer key',
      description: '',
      type: 'input',
      editOnly: false,
    },
    {
      key: 'apiUsername',
      label: 'API username',
      description: '',
      type: 'input',
      editOnly: false,
    },
    {
      key: 'apiPassword',
      label: 'API password',
      description: '',
      type: 'password',
      editOnly: false,
    },
  ]),
  triodatasource: ftpFields.concat([
    {
      key: 'apiUsername',
      label: 'API username',
      description: '',
      type: 'input',
      editOnly: false,
    },
    {
      key: 'apiPassword',
      label: 'API password',
      description: '',
      type: 'password',
      editOnly: false,
    },
    {
      key: 'apiKey',
      label: 'API key',
      description: '',
      type: 'password',
      editOnly: false,
    },
    {
      key: 'host',
      label: 'API Host',
      description: '',
      type: 'input',
      editOnly: false,
    },
  ]),
  fileuploaddatasource: [],
});
export default extraConnectorFields;
