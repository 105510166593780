<template>
  <div>
    <b-dropdown
      ref="dropdown"
      menu-class="bg-white text-dark"
      toggle-class="text-decoration-none bg-white text-dark border pl-0 dropdown-button"
    >
      <template #button-content>
        <b-button
          class="border-0 m-0 py-0 pl-2 pr-1"
          variant="text"
          @click="removeCategoriesFilter()"
        >
          <font-awesome-icon
            icon="times"
          />
        </b-button>
        <span class="font-weight-normal">
          Categories
        </span>
        <span class="font-weight-bold">
          ({{ filters.includeCategory.length }} included,
          {{ filters.excludeCategory.length }} excluded)
        </span>
      </template>
      <b-dropdown-header>
        Select which categories should be included/excluded in statistics
      </b-dropdown-header>
      <div class="mx-1">
        <b-input-group>
          <b-input-group-text
            class="bg-success text-white justify-content-center m-0 key-prop"
            style="width: 100px !important; border:none;"
          >
            Include
          </b-input-group-text>
          <b-button
            v-b-tooltip.hover.noninteractive.viewport="'click to toggle'"
            variant="primary"
            class="d-flex ml-1 key-prop"
            style="width:50px"
            @click="setFilterLocal('includeAll', !filters.includeAll)"
          >
            <span class="mx-auto">
              {{ filters.includeAll ? 'all' : 'any' }}
            </span>
          </b-button>
        </b-input-group>
        <chip-list
          :value="filters.includeCategory"
          :completions="categoriesIncludeOptions"
          placeholder="choose category"
          @input="v=>addCategory(v, 'includeCategory')"
        />
      </div>
      <div class="mx-1 mt-2">
        <b-input-group>
          <b-input-group-text
            class="bg-danger text-white justify-content-center m-0 key-prop"
            style="width: 100px !important; border:none;"
          >
            Exclude
          </b-input-group-text>
          <b-button
            v-b-tooltip.hover.noninteractive.viewport="'click to toggle'"
            variant="primary"
            class="d-flex ml-1 key-prop"
            style="width:50px"
            @click="setFilterLocal('excludeAll', !filters.excludeAll)"
          >
            <span class="mx-auto">
              {{ filters.excludeAll ? 'all' : 'any' }}
            </span>
          </b-button>
        </b-input-group>
        <chip-list
          placeholder="choose category"
          :value="filters.excludeCategory"
          :completions="categoriesExcludeOptions"
          @input="v=>addCategory(v, 'excludeCategory')"
        />
      </div>
      <b-button
        variant="primary"
        style="width:154px;"
        class="mx-1 mt-2 py-1 font-weight-normal"
        @click="openCategoryModal"
      >
        Add new category
      </b-button>
    </b-dropdown>
    <add-category-modal
      :modal-info="modalInfo"
      :active-item="activeItem"
      @hide="clearModal"
    />
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import ChipList from 'supwiz/components/ChipList.vue';
import AddCategoryModal from '@/components/Analyzer/AddCategoryModal.vue';
import { categoryTemplate } from '@/js/utils';

export default {
  name: 'Categories',
  components: {
    ChipList,
    AddCategoryModal,
  },
  data() {
    return {
      modalInfo: {
        action: null,
        activeItemId: null,
      },
      activeItem: {},
    };
  },
  computed: {
    ...mapGetters('statisticsFiltersStore', ['filters']),
    ...mapGetters('category', ['analyzerCategories']),
    ...mapState('analyzer', { analyzerDetails: 'details' }),
    categoriesExcludeOptions() {
      return this.analyzerCategories.map((e) => ({ key: e.id, value: e.name }))
        .filter((e) => !this.filters.includeCategory.includes(e.key));
    },
    categoriesIncludeOptions() {
      return this.analyzerCategories.map((e) => ({ key: e.id, value: e.name }))
        .filter((e) => !this.filters.excludeCategory.includes(e.key));
    },
  },
  methods: {
    categoryTemplate,
    ...mapMutations('statisticsFiltersStore', ['setFilter']),
    setFilterLocal(prop, value) {
      this.setFilter({ prop, value });
    },
    addCategory(payload, prop) {
      this.setFilterLocal(prop, payload);
      setTimeout(() => {
        this.$refs.dropdown.show(true);
      }, 0);
    },
    removeCategoriesFilter() {
      this.$root.$emit('remove-filter', 'categories');
    },
    openCategoryModal() {
      this.modalInfo.action = 'Add';
      this.activeItem = this.categoryTemplate(this.analyzerDetails.id);
      this.$nextTick(() => {
        this.$bvModal.show('add-category-modal');
      });
    },
    clearModal() {
      this.modalInfo.action = null;
      this.activeItem = {};
      this.$refs.dropdown.show();
    },
  },
};
</script>
