<template>
  <b-row
    v-if="!ready"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <div v-else>
    <statistics-filter
      v-if="ready"
      ref="statisticsFilter"
      :extra-filters="extraFilters"
      :base-filters="[]"
      :configuration="localStatsConfig"
      calendar-tooltip="Select date range for computing insights"
      @newFilters="(val) => needsRefresh = val"
      @fetchData="fetchStatistics('agentInsights')"
    >
      <template #filtersHeader>
        <stats-configuration-dropdown
          :default-filter-values="{}"
          :filter-source="statisticsConfigSource.AGENT_INSIGHTS"
          title="agent insights"
          @needsRefresh="v=>needsRefresh = v"
        />
      </template>
      <template #categories>
        <categories />
      </template>
    </statistics-filter>
    <b-overlay
      variant="white stat-overlay shadow"
      :show="computingStats || needsRefresh"
      :opacity="needsRefresh ? '1.0' : '0.7'"
      :no-center="needsRefresh"
      style="min-height:50px"
    >
      <template
        v-if="needsRefresh"
        #overlay
      >
        <h4
          style="padding-top:20px"
          class="my-auto text-center pb-3"
        >
          Click "Show" to refresh statistics.
        </h4>
      </template>
      <div v-if="hasData">
        <b-row
          no-gutters
        >
          <b-col cols="6" xl="3" class="mb-3 pr-2">
            <NewKPICard
              title="Conversations"
              format-type="number"
              :value="getKPICardValue('count', 'actual')"
              :previous-value="getKPICardValue('count', 'previous')"
            />
          </b-col>
          <b-col cols="6" xl="3" class="mb-3 pl-2 px-xl-2">
            <NewKPICard
              title="Average agent score"
              format-type="number"
              :value="getKPICardValue('average_score', 'actual')"
              :previous-value="getKPICardValue('average_score', 'previous')"
            />
          </b-col>
          <b-col cols="6" xl="3" class="mb-3 pr-2 px-xl-2">
            <NewKPICard
              title="Agents"
              format-type="number"
              :value="getKPICardValue('agents', 'actual')"
              :previous-value="null"
            />
          </b-col>
          <b-col cols="6" xl="3" class="mb-3 pl-2">
            <NewKPICard
              title="Departments"
              format-type="number"
              :value="getKPICardValue('groups', 'actual')"
              :previous-value="null"
            />
          </b-col>
        </b-row>
        <b-row class="d-flex">
          <b-col cols="12" xl="5" class="mb-3 pr-3 pr-xl-2">
            <b-card body-class="p-3" class="r-75 shadow h-100">
              <b-row class="mb-2">
                <b-col class="my-auto">
                  <b-card-title class="my-auto">
                    Average score
                  </b-card-title>
                </b-col>
              </b-row>

              <div v-if="hasScoreChartData" style="height: 500px;">
                <typed-chart
                  :chart-data="scoreChartData"
                  :options="scoreChartOptions"
                  chart-type="bar"
                  class="h-100"
                />
              </div>
              <div v-else class="text-center py-4">
                There no records to show.
              </div>
            </b-card>
          </b-col>
          <b-col cols="12" xl="7" class="mb-3 pl-3 pl-xl-2">
            <b-card body-class="p-3" class="r-75 shadow h-100">
              <b-row>
                <b-col class="my-auto">
                  <b-card-title>
                    Agent performance
                  </b-card-title>
                </b-col>
                <b-col cols="auto">
                  <b-pagination
                    v-model="agentPerformancePagination.currentPage"
                    :total-rows="agentPerformanceItems.length"
                    :per-page="agentPerformancePagination.perPage"
                    size="sm"
                    aria-controls="agent-performance-table"
                  />
                </b-col>
              </b-row>
              <b-table
                id="agent-performance-table"
                show-empty
                :per-page="agentPerformancePagination.perPage"
                :current-page="agentPerformancePagination.currentPage"
                :fields="agentPerformanceFields"
                :items="agentPerformanceItems"
                :sort-compare="sortTopAgents"
              >
                <template #cell(id)="row">
                  <b-badge
                    v-b-tooltip.hover.noninteractive.viewport="'Click to filter by this agent'"
                    pill
                    class="cursor-pointer big-badge"
                    @click="agentIdClicked(row.item.id)"
                  >
                    {{ row.item.id }}
                  </b-badge>
                </template>
                <template #cell(comparedToAvg)="row">
                  <b-col class="pl-0">
                    <font-awesome-icon
                      v-if="row.item.comparedToAvg > 0"
                      icon="up-long"
                      color="green"
                    />
                    <font-awesome-icon
                      v-else-if="row.item.comparedToAvg < 0"
                      icon="down-long"
                      color="red"
                    />
                    {{ row.item.comparedToAvg }}
                  </b-col>
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mb-3">
            <b-card title="Top topics per agent" body-class="p-3" class="r-75 shadow h-100">
              <b-table
                show-empty
                :fields="agentTopicComparisonFields"
                class="mb-0"
                sticky-header="500px"
                :items="agentTopicComparisonItems"
              >
                <template #cell(id)="row">
                  <b-badge
                    v-b-tooltip.hover.noninteractive.viewport="'Click to filter by this agent'"
                    pill
                    class="cursor-pointer big-badge"
                    @click="agentIdClicked(row.item.id)"
                  >
                    {{ row.item.id }}
                  </b-badge>
                </template>
                <template #cell(topics)="row">
                  <div class="border r-25 h-auto">
                    <strong>
                      <b-row no-gutters class="text-center border-bottom mb-2">
                        <b-col>Topic</b-col>
                        <b-col>Count</b-col>
                        <b-col>Score</b-col>
                      </b-row>
                    </strong>
                    <b-row v-for="(topic, index) in row.item.topics" :key="index" class="text-center pb-1" no-gutters>
                      <b-col class="my-auto py-auto">
                        <b-badge
                          v-b-tooltip.hover.noninteractive.viewport="'Click to filter by this topic'"
                          pill
                          class="cursor-pointer big-badge"
                          @click="topicClicked(topic.name)"
                        >
                          {{ topic.name || 'None' }}
                        </b-badge>
                      </b-col>
                      <b-col> {{ topic.count }}</b-col>
                      <b-col> {{ topic.score || 'N/A' }}</b-col>
                    </b-row>
                  </div>
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <b-card
        v-else
        class="r-75 shadow"
      >
        <h4 class="mb-0">
          There is no data to show.
        </h4>
      </b-card>
    </b-overlay>
    <new-configuration-modal :source="statisticsConfigSource.AGENT_INSIGHTS" />
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import StatisticsFilter from 'supwiz/components/statisticsFilters/StatisticsFilters.vue';
import NewKPICard from 'supwiz/components/NewKPICard.vue';
import NewConfigurationModal from '@/components/Analyzer/NewConfigurationModal.vue';
import {
  defaultFilterValues, metricOptions, statisticsConfigSource,
} from '@/js/constants';
import StatsConfigurationDropdown from '@/components/Analyzer/StatsConfigurationDropdown.vue';
import Categories from '@/components/Analyzer/Categories.vue';
import TypedChart from '@/components/typedChart.vue';
import { getExtraFilters } from '@/js/utils';

export default {
  name: 'AgentInsights',
  components: {
    StatisticsFilter,
    NewConfigurationModal,
    NewKPICard,
    StatsConfigurationDropdown,
    Categories,
    TypedChart,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchStatsConfigs({ params: { analyzer: to.params.analyzerId } });
    });
  },
  data() {
    return {
      defaultFilterValues,
      statisticsConfigSource,
      needsRefresh: false,
      metricOptions,
      ready: false,
      localStatsConfig: null,
      agentPerformanceFields: [
        { key: 'id', label: 'ID' },
        { label: 'Conv. count', key: 'count', sortable: true },
        { label: 'Avg. score', key: 'averageScore', sortable: true },
        { label: 'Compared to avg. call score', key: 'comparedToAvg', sortable: true },
      ],
      agentTopicComparisonFields: [
        { key: 'id', label: 'ID' },
        { key: 'topics', label: 'Topics', thClass: 'text-center' },
      ],
      extraFilters: [],
      agentPerformancePagination: {
        currentPage: 1,
        perPage: 10,
      },
    };
  },
  computed: {
    ...mapState('analyzer', { analyzerDetails: 'details' }),
    ...mapState('statistics', ['computingStats']),
    ...mapGetters('statistics', ['getStatistics', 'getSavedFilters']),
    ...mapGetters('statisticsFiltersStore', ['extraSelectedFilters']),
    ...mapGetters('connector', ['typeFromId']),
    ...mapGetters('statsConfiguration', ['getSavedStatsConfig']),
    ...mapState('statsConfiguration', { statsConfigurationDetails: 'details' }),
    statisticsData() {
      return this.getStatistics('agentInsights');
    },
    connectorType() {
      return this.typeFromId(this.analyzerDetails.dataSource);
    },
    hasData() {
      return Object.values(this.statisticsData).length;
    },
    hasScoreChartData() {
      return !!this.scoreChartData.datasets[0].data.filter((e) => e > 0).length;
    },
    scoreChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: true,
          },
          y: {
            display: true,
            title: {
              display: true,
              text: 'Number of agents',
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      };
    },
    scoreChartData() {
      const data = this.statisticsData.agent_score_histogram?.agent_score;
      return {
        labels: data?.groups || [],
        datasets: [
          {
            backgroundColor: '#3A878C',
            borderRadius: 4,
            data: data?.counts || [],
          },
        ],
      };
    },
    agentPerformanceItems() {
      const data = [];
      (this.statisticsData.group_by_agent?.groups || []).forEach((id, index) => {
        const count = this.statisticsData.group_by_agent?.agent_count?.[index] || 0;
        const averageScore = this.statisticsData.group_by_agent?.agent_score?.[index] || 0;
        const overallAverage = (this.statisticsData.previous.actual?.average_score || 0);
        data.push({
          id,
          count,
          averageScore: averageScore.toFixed(2),
          comparedToAvg: (averageScore - overallAverage).toFixed(2),
        });
      });
      return data.sort((a, b) => b.averageScore - a.averageScore);
    },
    agentTopicComparisonItems() {
      const data = [];
      (this.statisticsData.group_by_agent?.groups || []).forEach((id, index) => {
        const agentTopics = this.statisticsData.group_by_agent?.group_by_topic[index];
        const topics = [];
        agentTopics.groups.forEach((topic, tIndex) => {
          topics.push({
            name: topic,
            count: agentTopics?.topic_count[tIndex],
            score: agentTopics?.topic_score[tIndex]
              ? agentTopics?.topic_score[tIndex].toFixed(2) : null,
          });
        });
        data.push({
          id,
          topics,
        });
      });
      return data.map((e) => {
        const topics = e.topics.sort((a, b) => b.score - a.score).slice(0, 3);
        return { ...e, topics };
      });
    },
  },
  watch: {
    async statsConfigurationDetails(n) {
      if (n.id !== this.localStatsConfig?.id) {
        this.setConfigProxy(this.statsConfigurationDetails);
      }
    },
  },
  async mounted() {
    await this.fetchFilters();
    // if there is saved stats config, load it
    const savedStatsConfig = this.getSavedStatsConfig('agentInsights');
    if (savedStatsConfig) {
      await this.setConfigProxy(savedStatsConfig);
      this.setStatsConfigurationDetails(savedStatsConfig);
    }
    // if there are saved filters, load it
    this.$nextTick(() => {
      const savedFilters = this.getSavedFilters('agentInsights');
      if (savedFilters) {
        this.setFilters(cloneDeep(savedFilters.filters));
        savedFilters.extraSelectedFilters.forEach((item) => {
          const alreadyAdded = this.extraSelectedFilters.find((e) => e.key === item.key);
          if (!alreadyAdded) {
            this.$refs.statisticsFilter.addExtraFilterLocal(item);
          }
        });
      } else {
        // fresh setup
        this.setFilters(cloneDeep(this.defaultFilterValues));
      }
    });
  },
  beforeDestroy() {
    // if there is config selected, save it
    if (this.localStatsConfig) {
      this.setLocalStatsConfig({ key: 'agentInsights', value: cloneDeep(this.localStatsConfig) });
    }
    // save all current filters
    this.setLocalStatisticsFilters({ key: 'agentInsights', value: cloneDeep(this.$store.state.statisticsFiltersStore) });
  },
  destroyed() {
    this.setStatsConfigurations({});
    this.setStatsConfigurationDetails({});
  },
  methods: {
    ...mapActions('statsConfiguration', ['setFiltersFromConfig']),
    ...mapMutations('statsConfiguration', ['setLocalStatsConfig']),
    ...mapActions('statistics', ['fetchStatistics']),
    ...mapMutations('statistics', ['setLocalStatisticsFilters']),
    ...mapMutations('statisticsFiltersStore', ['setFilters', 'setFilter', 'setExtraSelectedFilters']),
    ...mapActions('externalSystem', ['fetchGroups', 'fetchFilterValues']),
    ...mapActions('category', { fetchCategories: 'fetchItems' }),
    ...mapMutations('statsConfiguration', {
      setStatsConfigurations: 'setItems',
      setStatsConfigurationDetails: 'setItemDetails',
    }),
    ...mapActions('statsConfiguration', {
      fetchStatsConfigs: 'fetchItems',
    }),
    getExtraFilters,
    async setConfigProxy(payload) {
      this.setFilters(cloneDeep(this.defaultFilterValues));
      const configCopy = cloneDeep(payload);
      await this.setFiltersFromConfig(configCopy);
      this.localStatsConfig = configCopy;
      return true;
    },
    async fetchFilters() {
      try {
        this.ready = false;
        const resp = await this.fetchFilterValues();
        this.filterOptions = resp.data;
        this.filterOptions.groups = await this.fetchGroups();
        this.extraFilters = getExtraFilters(
          this.filterOptions,
          this.analyzerDetails,
          this.connectorType,
        );
        await this.fetchCategories();
      } catch (error) {
        const filterValues = `agents${this.analyzerDetails.classification ? ' and tags' : ''}`;
        this.$store.dispatch('sidebar/showWarning', {
          title: `Failed to fetch filter values (${filterValues})`,
          text: error.message,
        });
        throw error;
      } finally {
        this.ready = true;
      }
    },
    getKPICardValue(key, period) {
      switch (key) {
        case 'count': {
          return this.statisticsData.previous[period]?.[key];
        }
        case 'average_score': {
          return parseFloat((this.statisticsData.previous[period]?.[key] || 0).toFixed(2), 10);
        }
        case 'agents': return this.filterOptions.agents.length;
        case 'groups': return this.filterOptions.groups.length;
        default: return null;
      }
    },
    agentIdClicked(id) {
      const agentFilter = this.extraFilters.find((e) => e.key === 'agentId');
      if (!this.extraSelectedFilters.find((e) => e.key === 'agentId')) {
        this.$refs.statisticsFilter.addExtraFilterLocal(agentFilter);
      }
      this.setFilter({ prop: 'agentId', value: [id] });
      this.$nextTick(() => {
        this.needsRefresh = false;
        this.fetchStatistics('agentInsights');
      });
    },
    topicClicked(topic) {
      const topicFilter = this.extraFilters.find((e) => e.key === 'tag');

      if (!this.extraSelectedFilters.find((e) => e.key === 'tag')) {
        this.$refs.statisticsFilter.addExtraFilterLocal(topicFilter);
      }
      this.setFilter({ prop: 'tag', value: [topic] });
      this.$nextTick(() => {
        this.needsRefresh = false;
        this.fetchStatistics('agentInsights');
      });
    },
    sortTopAgents(a, b, key) {
      return b[key] - a[key];
    },
  },
};
</script>
<style scoped>
.big-badge {
  scale: 1.4;
}
</style>
