var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isFetching)?_c('b-row',{staticClass:"h-100 align-items-center"},[_c('b-col',{staticClass:"text-center"},[_c('b-spinner',{staticStyle:{"width":"5rem","height":"5rem"}})],1)],1):(_vm.objectNotFound)?_c('div',{staticClass:"text-center mt-5 text-muted"},[_c('h1',[_vm._v("Call not found")]),_c('p',[_vm._v("Requested call could not be found.")])]):_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"7","lg":"5","xl":"4"}},[_c('DetailsCard',_vm._b({},'DetailsCard',{
        callDetails: _vm.callDetails,
        formatTime: _vm.formatTime,
      },false))],1),_c('b-col',{staticClass:"pl-0",attrs:{"sm":"12","md":"5","lg":"7","xl":"8"}},[_c('div',{staticClass:"mb-2"},[_c('b-card',{staticClass:"r-50 p-2 d-inline-block mr-2",attrs:{"no-body":""}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Return ")]),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"primary"},on:{"click":_vm.rehandleCallProxy}},[_vm._v(" Re-handle call ")]),(_vm.hasTranscript)?_c('b-dropdown',{staticClass:"ml-2",attrs:{"text":"Configuration","variant":"primary","menu-class":"bg-white"}},[_c('b-dropdown-form',{attrs:{"form-class":"p-2"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Change User"}},[_c('b-form-select',{attrs:{"options":_vm.visitorOptions,"size":"sm"},model:{value:(_vm.visitorId),callback:function ($$v) {_vm.visitorId=$$v},expression:"visitorId"}})],1)],1)],1):_vm._e()],1),(_vm.hasTranscript && !_vm.hasSummary && _vm.openAiEnabled())?_c('b-card',{staticClass:"r-50 p-2 d-inline-block mr-2",attrs:{"no-body":""}},[_c('b-overlay',{attrs:{"show":_vm.summarizing}},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.summarizing},on:{"click":function($event){return _vm.fetchSummary(_vm.callDetails.id)}}},[_vm._v(" Summarize ")])],1)],1):_vm._e()],1),(_vm.hasTranscript)?_c('b-card',{staticClass:"r-50"},[(_vm.hasSummary)?_c('collapsible-section',{staticClass:"mt-3",attrs:{"title":"Summary","rounded":""},on:{"visible":(val) => _vm.isVisible = val},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"bg-white p-2"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.callDetails.summary)+" ")])])]},proxy:true}],null,false,1051884674)}):_vm._e(),(_vm.hasSentiment)?_c('sentiment-analysis',{staticClass:"mb-3",attrs:{"data":_vm.sentimentData,"object-type":"call"}}):_vm._e(),_c('CallLog',_vm._b({},'CallLog',{
          callDetails: _vm.callDetails,
          formatTime: _vm.formatTime,
          hasSentiment: _vm.hasSentiment,
          visitorSpeaker: _vm.visitorSpeaker,
        },false))],1):_c('div',{staticClass:"text-center mt-5 text-muted"},[_c('h2',[_vm._v("No Transcript")]),_c('p',[_vm._v("A transcript could not be found.")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }