<template>
  <b-modal
    ref="createConfigModal"
    title="Save filters"
    ok-title="Save"
    :ok-disabled="$v.newConfigName.$invalid"
    @hide="newConfigName = ''"
    @ok="createStatsConfigLocal()"
  >
    <edit-key-value
      :min-key-width="100"
      key-prop="Name"
      description=""
      :value-prop="newConfigName"
      type="input"
      :state="!$v.newConfigName.$invalid"
      @input="val=>newConfigName = val"
    >
      <template #feedback>
        <b-form-invalid-feedback
          v-if="!$v.newConfigName.required"
        >
          Name is required
        </b-form-invalid-feedback>
        <b-form-invalid-feedback
          v-if="!$v.newConfigName.isUnique"
        >
          Name must be unique
        </b-form-invalid-feedback>
      </template>
    </edit-key-value>
  </b-modal>
</template>
<script>
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { cloneDeep } from 'lodash';
import {
  defaultFilterValues,
} from '@/js/constants';

export default {
  name: 'NewConfigurationModal',
  components: {
    EditKeyValue,
  },
  mixins: [validationMixin],
  props: {
    source: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newConfigName: '',
    };
  },
  computed: {
    ...mapGetters('statsConfiguration', ['formattedConfigData']),
    ...mapState('statsConfiguration', { statsConfigurations: 'items' }),
  },
  mounted() {
    this.$root.$on('show-config-modal', () => {
      if (this.$refs.createConfigModal) {
        this.$refs.createConfigModal.show();
      }
    });
  },
  methods: {
    ...mapActions('statsConfiguration', { addStatsConfig: 'addItem' }),
    ...mapMutations('statsConfiguration', { setStatsConfigurationDetails: 'setItemDetails' }),
    ...mapMutations('statisticsFiltersStore', ['setFilters']),
    async createStatsConfigLocal() {
      const newConfig = this.formattedConfigData(true);
      newConfig.name = this.newConfigName;
      newConfig.source = this.source;
      await this.addStatsConfig({ newItem: newConfig });
      this.setStatsConfigurationDetails({});
      this.setFilters(cloneDeep(defaultFilterValues));
    },
  },
  validations() {
    return {
      newConfigName: {
        required,
        isUnique(value) {
          return !Object.values(this.statsConfigurations).map((e) => e.name).includes(value);
        },
      },
    };
  },
};
</script>
