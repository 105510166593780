import { agentAnonymizationMode } from '@/js/featureFlags';

const analyzerPages = [
  {
    display: 'Configuration',
    linkName: 'configuration',
    icon: 'cog',
  },
  {
    display: 'Calls',
    linkName: 'call',
    icon: 'list',
    matchOn: ['call-overview', 'call-single'],
  },
  {
    display: 'Agent Insights',
    linkName: 'agent-insights',
    icon: 'headset',
  },
  {
    display: 'Conversation Insights',
    linkName: 'conversation-insights',
    icon: 'comment',
  },
  {
    display: 'Analytics',
    linkName: 'analytics',
    icon: 'microscope',
  },
  {
    display: 'Status',
    linkName: 'status',
    icon: 'info-circle',
  },
];

const sidebarState = {
  showSidebar: false,
  pageLoading: false,
};

const sidebarGetters = {
  pages: (state, getters, rootState) => {
    const route = rootState.route;
    if (route.fullPath.startsWith('/analyzer') && route.params.analyzerId) {
      const pages = [...analyzerPages];
      if (agentAnonymizationMode() === 'FULL') {
        pages.splice(2, 1);
      }
      return pages;
    }
    return [];
  },
  loggedIn: (state, getters, rootState) => rootState.auth.loggedIn,
};

const mutations = {
  setShowSidebar(state, payload) {
    state.showSidebar = payload;
  },
  setLoading(state, payload) {
    state.pageLoading = payload;
  },
};

const actions = {
  showWarning({ dispatch }, { title, text, variant }) {
    dispatch('templateStore/templateSendNotification', {
      title,
      text,
      variant: variant || 'danger',
      toast: true,
    }, { root: true });
  },
};

export default {
  namespaced: true,
  state: sidebarState,
  getters: sidebarGetters,
  mutations,
  actions,
};
