import { createSsoEndpoints } from 'supwiz/util/sso';

const BACKEND_PATH = '/api';

export default {
  ...createSsoEndpoints(BACKEND_PATH),
  // auth
  obtainJWT: `${BACKEND_PATH}/auth/obtain-token/`,
  refreshJWT: `${BACKEND_PATH}/auth/refresh-token/`,
  verifyJWT: `${BACKEND_PATH}/auth/verify-token/`,
  permissions: `${BACKEND_PATH}/auth/permissions/`,
  userDetails: `${BACKEND_PATH}/user-details/`,
  // data
  statistics: `${BACKEND_PATH}/dashboard/data`,
  dataSource: `${BACKEND_PATH}/data-source/`,
  dataSourceZendesk: `${BACKEND_PATH}/data-source-zendesk/`,
  dataSourcePuzzel: `${BACKEND_PATH}/data-source-puzzel/`,
  dataSourceTrio: `${BACKEND_PATH}/data-source-trio/`,
  dataSourceFileUpload: `${BACKEND_PATH}/data-source-file-upload/`,
  call: `${BACKEND_PATH}/call/`,
  task: `${BACKEND_PATH}/task/`,
  analyzer: `${BACKEND_PATH}/analyzer/`,
  filterCategory: `${BACKEND_PATH}/analytics-filter-category/`,
  statsConfiguration: `${BACKEND_PATH}/stats-config/`,
  groups: `${BACKEND_PATH}/groups/`,
  jsonCallUpload: `${BACKEND_PATH}/json-call-upload/`,
};
