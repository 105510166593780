<template>
  <b-row
    v-if="isFetching"
    class="h-100 align-items-center"
  >
    <b-col class="text-center">
      <b-spinner
        style="width: 5rem; height: 5rem;"
      />
    </b-col>
  </b-row>
  <connector-form
    v-else
    :is-edit="true"
  />
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import ConnectorForm from '@/components/Connector/ConnectorForm.vue';

export default {
  name: 'ConnectorSingle',
  components: { ConnectorForm },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.params.connectorId) {
        vm.fetchConnectorDetails({ id: to.params.connectorId });
        vm.localIsFetching = false; // eslint-disable-line no-param-reassign
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.connectorId !== from.params.connectorId) {
      this.fetchConnectorDetails({ id: to.params.connectorId });
    }
    next();
  },
  data() {
    return {
      localIsFetching: true,
    };
  },
  computed: {
    ...mapState('connector', ['isFetchingDetails']),
    isFetching() {
      return this.localIsFetching || this.isFetchingDetails;
    },
  },
  destroyed() {
    this.setConnectorDetails({});
  },
  methods: {
    ...mapActions('connector', { fetchConnectorDetails: 'fetchItemDetails' }),
    ...mapMutations('connector', { setConnectorDetails: 'setItemDetails' }),
  },
};
</script>
