import Vue from 'vue';
import { getModuleProps } from '@/js/utils';
import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';

const configurationsState = {
  statsConfigurations: {},
  isFetching: false,
  activeConfigurationId: null,
  // below object is used for storing stats config, when switching between pages
  localStatsConfig: {
    agentInsights: null,
    conversationInsights: null,
    analytics: null,
  },
};

const configurationGetters = {
  formattedConfigData: (state, getters, rootState, rootGetters) => (isNewConfiguration) => {
    const filters = rootGetters['statisticsFiltersStore/filters'];
    const extraSelectedFilters = rootGetters['statisticsFiltersStore/extraSelectedFilters'];
    const config = {
      analyzer: rootState.analyzer.details.id,
      source: state.details.source,
      groupBy: filters.groupBy,
      metrics: filters.metrics,
      timeRange: filters.timeRange,
      timeRangeStart: filters.startDate.toISOString(),
      timeRangeEnd: filters.endDate.toISOString(),
      filters: {
        interval: filters.interval,
        tag: filters.tag,
        agent: filters.agent,
        department: filters.department,
        transcribeDuration: filters.transcribeDuration,
        sentiments: filters.sentiments,
        includeCategory: filters.includeCategory,
        includeAll: filters.includeAll,
        excludeCategory: filters.excludeCategory,
        excludeAll: filters.excludeAll,
        search: filters.search,
      },
      extraSelectedFilters: extraSelectedFilters.map((e) => e.key),
    };
    if (!isNewConfiguration) {
      config.id = state.details.id;
      config.name = state.details.name;
    }
    return config;
  },
  getSavedStatsConfig: (state) => (key) => state.localStatsConfig[key],
};

const mutations = {
  clearLocalStatsConfig(state) {
    Vue.set(state, 'localStatsConfig', {
      agentInsights: null,
      conversationInsights: null,
      analytics: null,
    });
  },
  setLocalStatsConfig(state, payload) {
    Vue.set(state.localStatsConfig, payload.key, payload.value);
  },
};

const actions = {
  setFiltersFromConfig({ commit }, item) {
    if (Object.keys(item).length !== 0) {
      if (item.filters.interval) {
        commit('statisticsFiltersStore/setFilter', { prop: 'interval', value: item.filters.interval }, { root: true });
      }
      commit('statisticsFiltersStore/setFilter', { prop: 'groupBy', value: item.groupBy }, { root: true });
      commit('statisticsFiltersStore/setFilter', { prop: 'metrics', value: item.metrics }, { root: true });
      commit('statisticsFiltersStore/setFilter', { prop: 'timeRange', value: item.timeRange }, { root: true });
      commit('statisticsFiltersStore/setFilter', { prop: 'startDate', value: item.timeRangeStart }, { root: true });
      commit('statisticsFiltersStore/setFilter', { prop: 'endDate', value: item.timeRangeEnd }, { root: true });
      commit('statisticsFiltersStore/setFilter', { prop: 'tag', value: item.filters.tag }, { root: true });
      commit('statisticsFiltersStore/setFilter', { prop: 'agent', value: item.filters.agent }, { root: true });
      commit('statisticsFiltersStore/setFilter', { prop: 'department', value: item.filters.department }, { root: true });
      commit('statisticsFiltersStore/setFilter', { prop: 'search', value: item.filters.search }, { root: true });
      if (item.filters.transcribeDuration) {
        commit(
          'statisticsFiltersStore/setFilter',
          { prop: 'transcribeDuration', value: item.filters.transcribeDuration },
          { root: true },
        );
      }

      commit('statisticsFiltersStore/setFilter', { prop: 'sentiments', value: item.filters.sentiments }, { root: true });
      commit('statisticsFiltersStore/setFilter', { prop: 'includeCategory', value: item.filters.includeCategory }, { root: true });
      commit('statisticsFiltersStore/setFilter', { prop: 'includeAll', value: item.filters.includeAll }, { root: true });
      commit('statisticsFiltersStore/setFilter', { prop: 'excludeCategory', value: item.filters.excludeCategory }, { root: true });
      commit('statisticsFiltersStore/setFilter', { prop: 'excludeAll', value: item.filters.excludeAll }, { root: true });
    }
    return true;
  },
};
const templates = [
  new GeneralTemplate(endpoints.statsConfiguration, 'statsConfiguration'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: configurationsState, getters: configurationGetters, mutations, actions,
  }, templates),
};
