import buildinfo from '@/assets/buildinfo.json';
import { strToCamel, strToSnake } from 'supwiz/util/data';
import {
  CallStatusTypes,
  sentimentOptions,
  connectorGroupNames,
  connectorTooltipMap,
} from '@/js/constants';
import { agentAnonymizationMode } from '@/js/featureFlags';
import spec from '../../package.json';

const name = spec.name;
const version = spec.version;

export function categoryTemplate(id) {
  return {
    name: '',
    analyzer: id,
    lines: {
      phrasesAllowed: [],
      tagsAllowed: [],
      phrasesDisallowed: [],
      tagsDisallowed: [],
    },
    stemAndSynonym: false,
  };
}
export function versionInfo() {
  return {
    name,
    version,
    ...buildinfo,
  };
}
export function versionInfoArray() {
  const info = versionInfo();
  const ret = [];
  for (const k of Object.keys(info)) {
    ret.push({ name: k, value: info[k] });
  }
  return ret;
}
// SNAKE-CAMEL-CONVERSION
export function tagMappingToCamel(obj, parentIsTagMapping = false) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => {
    if (typeof v === 'object' && v && !Array.isArray(v)) {
      if (parentIsTagMapping) {
        return [k, tagMappingToCamel(v, k === 'tag_mapping')];
      }
      return [strToCamel(k), tagMappingToCamel(v, k === 'tag_mapping')];
    }
    return [strToCamel(k), v];
  }));
}

export function tagMappingToSnake(obj, parentIsTagMapping = false) {
  return Object.fromEntries(Object.entries(obj || {}).map(([k, v]) => {
    if (typeof v === 'object' && v && !Array.isArray(v)) {
      if (parentIsTagMapping) {
        return [k, tagMappingToSnake(v, k === 'tagMapping')];
      }
      return [strToSnake(k), tagMappingToSnake(v, k === 'tagMapping')];
    }
    return [strToSnake(k), v];
  }));
}

export function snakeCaseToText(str) {
  return str.split('_').map((e) => e.charAt(0).toUpperCase() + e.slice(1)).join(' ');
}
export function camelCaseToText(str) {
  return str.replace(/([A-Z])/g, ' $1').trim().toLowerCase();
}
export function getSpeechAnalyticsEnvironment() {
  // Be cautious of what you allow yourself to use this value for. An enduser
  // (although a technical one) may change the value him/herself in the developer console.
  // In any case you should not rely on information based in the frontend to allow/disallow making
  // changes in the backend; the backend should always itself verify that the user is allowed to
  // perform the requested operation.
  return process.env.NODE_ENV;
}

// vuex templates init function
export function getModuleProps(moduleSpecific, templates) {
  return {
    state: {
      ...moduleSpecific.state,
      ...Object.assign({}, ...templates.map((e) => e.state)),
    },
    getters: {
      ...moduleSpecific.getters,
      ...Object.assign({}, ...templates.map((e) => e.getters)),
    },
    mutations: {
      ...moduleSpecific.mutations,
      ...Object.assign({}, ...templates.map((e) => e.mutations)),
    },
    actions: {
      ...moduleSpecific.actions,
      ...Object.assign({}, ...templates.map((e) => e.actions)),
    },
  };
}

export function mapDecimalToColor(decimal, options) {
  const saturation = options?.saturation || 70;
  const lightness = options?.lightness || 50;
  const offset = options?.offset || 120;
  const hue = (decimal * offset).toString(10);
  return `hsl(${hue},${saturation}%,${lightness}%)`;
}

export function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }
  return `${text.substring(0, maxLength)}...`;
}

export function getFirstUserMessage(analyzerDetails, item) {
  if (!analyzerDetails) {
    return '';
  }
  if (item.firstVisitorMessage) {
    return truncateText(item.firstVisitorMessage, 100);
  }
  if (!item.transcript?.recognizedPhrases) { return 'Not found'; }
  // we assume agent always speaks first
  let customId = item.customVisitorSpeakerId;
  customId = customId !== null ? customId : analyzerDetails.defaultVisitorSpeakerId;
  const visitorId = item.transcriptSpeakers[customId];
  for (const transcript of item.transcript.recognizedPhrases) {
    if (transcript.speaker === visitorId) {
      return truncateText(transcript.nBest[0].display, 100);
    }
  }
  return '';
}

export function getAgentInsightsMetrics() {
  return {
    previous: {
      metric_type: 'compute_previous',
      metrics: {
        count: {
          metric_type: 'count',
        },
        average_score: {
          metric_type: 'average',
          field: 'conversation_score',
        },
      },
    },
    agent_score_histogram: {
      metric_type: 'grouped_histogram',
      nr_of_bins: 8,
      min_value: -100,
      max_value: 100,
      field: 'agent_id',
      autogroup: true,
      metrics: {
        agent_score: {
          metric_type: 'average',
          field: 'conversation_score',
        },
      },
    },
    group_by_agent: {
      metric_type: 'group_by',
      field: 'agent_id',
      autogroup: true,
      metrics: {
        agent_count: {
          metric_type: 'count',
        },
        agent_score: {
          metric_type: 'average',
          field: 'conversation_score',
        },
        group_by_topic: {
          metric_type: 'group_by',
          field: 'tag',
          autogroup: true,
          metrics: {
            topic_count: {
              metric_type: 'count',
            },
            topic_score: {
              metric_type: 'average',
              field: 'conversation_score',
            },
          },
        },
      },
    },
  };
}
export function getConversationInsightsMetrics(interval) {
  return {
    previous: {
      metric_type: 'compute_previous',
      metrics: {
        count: {
          metric_type: 'count',
        },
        average_score: {
          metric_type: 'average',
          field: 'conversation_score',
        },
      },
    },
    group_by_topic: {
      metric_type: 'group_by',
      field: 'tag',
      autogroup: true,
      metrics: {
        topic_count: {
          metric_type: 'count',
        },
        topic_score: {
          metric_type: 'average',
          field: 'conversation_score',
        },
        group_by_subtopic: {
          metric_type: 'group_by',
          field: 'subtag',
          autogroup: true,
          metrics: {
            topic_count: {
              metric_type: 'count',
            },
          },
        },
        group_by_agent: {
          metric_type: 'group_by',
          field: 'agent_id',
          autogroup: true,
          metrics: {
            agent_count: {
              metric_type: 'count',
            },
            agent_score: {
              metric_type: 'average',
              field: 'conversation_score',
            },
          },
        },
      },
    },
    group_by_interval: {
      metric_type: 'group_by',
      field: interval,
      autogroup: true,
      metrics: {
        call_count: {
          metric_type: 'count',
        },
        conversation_score: {
          metric_type: 'average',
          field: 'conversation_score',
        },
      },
    },
  };
}
export function getConversationInsightsDistributionMetrics(interval) {
  return {
    group_by_interval: {
      metric_type: 'group_by',
      field: interval,
      autogroup: true,
      metrics: {
        call_count: {
          metric_type: 'count',
        },
        conversation_score: {
          metric_type: 'average',
          field: 'conversation_score',
        },
      },
    },
  };
}
const metric2Type = {
  call_count: {
    metric_type: 'count',
  },
  average_conversation_score: {
    metric_type: 'average',
    field: 'conversation_score',
  },
  average_transcript_duration: {
    metric_type: 'average',
    field: 'transcript_duration',
  },
  sum_transcript_duration: {
    metric_type: 'sum',
    field: 'transcript_duration',
  },
  average_call_duration: {
    metric_type: 'average',
    field: 'duration',
  },
  average_handling_time: {
    metric_type: 'average',
    field: 'average_handling_time',
  },
  words_per_minute_visitor: {
    metric_type: 'average',
    field: 'words_per_minute_visitor',
  },
  words_per_minute_agent: {
    metric_type: 'average',
    field: 'words_per_minute_agent',
  },
  average_time_between_phrases: {
    metric_type: 'average',
    field: 'time_between_phrases',
  },
};
export function getAnalyticsMetrics(filters) {
  const metrics = filters.metrics;
  const formattedMetrics = {
    general: {
      metric_type: 'group_by',
      field: filters.groupBy,
      autogroup: true,
      metrics: {
        grouped: {
          metric_type: 'group_by',
          field: filters.interval,
          autogroup: true,
          metrics: {},
        },
      },
    },
  };
  metrics.forEach((metric) => {
    formattedMetrics.general.metrics.grouped.metrics[metric] = metric2Type[metric];
  });
  if (filters.interval === 'Full') {
    formattedMetrics.general.metrics = formattedMetrics.general.metrics.grouped.metrics;
  }
  return formattedMetrics;
}

export function rgbToHsl(rgbString) {
  const rgbValues = rgbString.match(/\d+/g);
  const r = parseInt(rgbValues[0], 10) / 255;
  const g = parseInt(rgbValues[1], 10) / 255;
  const b = parseInt(rgbValues[2], 10) / 255;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;
  if (delta === 0) {
    h = 0;
  } else if (cmax === r) {
    h = ((g - b) / delta) % 6;
  } else if (cmax === g) {
    h = ((b - r) / delta) + 2;
  } else {
    h = ((r - g) / delta) + 4;
  }

  h = Math.round(h * 60);

  if (h < 0) { h += 360; }
  l = (cmax + cmin) / 2;

  s = delta === 0 ? 0 : delta / (1 - Math.abs((2 * l) - 1));

  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { hue: h, saturation: s, lightness: l };
}

export function getBaseFilters() {
  return [
    {
      key: 'search', label: 'Content', type: 'text', tooltip: 'Enter expression you want to search for', custom: true,
    },
  ];
}

function formatDurationOptions(transcribeDurations) {
  const options = [];
  for (const option of transcribeDurations) {
    if (option === -1) {
      options.push({ text: 'Full transcript', value: -1 });
      continue;
    }
    options.push({ text: `${option} seconds`, value: option });
  }
  return options;
}

function formatTagOptions(tags) {
  const options = [];
  for (const tag of tags) {
    if (tag === null) {
      options.push({ text: 'No tag', value: null });
      continue;
    }
    options.push({ text: tag, value: tag });
  }
  return options;
}

function formattedGroupName(connectorType) {
  const groupName = connectorGroupNames[connectorType] || 'department';
  return `${groupName.charAt(0).toUpperCase() + groupName.slice(1)}s`;
}

export function getExtraFilters(options, analyzerDetails, connectorType) {
  const filters = [
    {
      key: 'status', label: 'Status', type: 'multiselect', options: CallStatusTypes, tooltip: 'Select status', clearOnRemove: true,
    },
    {
      key: 'transcribeDuration', label: 'Transcript duration', type: 'multiselect', options: formatDurationOptions(options.transcribe_durations), tooltip: 'Select transcript duration', clearOnRemove: true,
    },
    {
      key: 'department', label: formattedGroupName(connectorType), type: 'multiselect', options: options.groups.map((e) => ({ text: e.name, value: e.id })), tooltip: connectorTooltipMap[connectorType] || 'Select which departments should be included',
    },
    {
      key: 'categories', label: 'Categories', type: 'multiselect', tooltip: 'Select which categories should be included/excluded in statistics', custom: true,
    },
  ];
  if (agentAnonymizationMode() !== 'FULL') {
    filters.push({
      key: 'agentId', label: 'Agent', type: 'multiselect', options: options.agents.map((e) => ({ text: e, value: e })), tooltip: 'Select agent', clearOnRemove: true,
    });
  }
  if (options.tags && options.tags.length > 1) {
    filters.push({
      key: 'tag', label: 'Tags', type: 'multiselect', tooltip: 'Select tag', options: formatTagOptions(options.tags), clearOnRemove: true,
    });
  }
  if (analyzerDetails.sentimentAnalysis) {
    filters.push({
      key: 'sentimentCall', label: 'Sentiment', type: 'multiselect', options: sentimentOptions, tooltip: 'Select sentiment', clearOnRemove: true,
    });
  }
  return filters;
}
