import axios from 'axios';
import endpoints from '@/js/endpoints';
import {
  connectorTypes,
} from '@/js/constants';

const statisticsState = {
  isFetching: false,
};
const statisticsGetters = {
};
const statisticsMutations = {
  setIsFetching(state, value) {
    state.isFetching = value;
  },
};
async function fetchDataFromSource(
  { dispatch, commit, rootGetters },
  dataSourceId,
  dataSourceName,
  endpoint,
) {
  try {
    const auth = rootGetters['auth/headerAuthorization'];
    commit('setIsFetching', true);
    const request = { ...auth };
    const { data } = await axios.get(`${endpoint}${dataSourceId}/groups/`, request);
    commit('setIsFetching', false);
    return data;
  } catch (error) {
    dispatch('sidebar/showWarning', {
      title: `Failed to fetch ${dataSourceName}`,
      text: error.message,
    }, { root: true });
    commit('setIsFetching', false);
    return [];
  }
}
const statisticsActions = {
  fetchGroups({ dispatch, rootGetters, rootState }) {
    const connectorType = rootGetters['connector/typeFromId'](rootState.analyzer.details.dataSource);
    if (connectorType === connectorTypes.ZENDESK.value) {
      return dispatch('fetchZendeskDepartments', rootState.analyzer.details.dataSource);
    } if (connectorType === connectorTypes.PUZZEL.value) {
      return dispatch('fetchPuzzelQueues', rootState.analyzer.details.dataSource);
    } if (connectorType === connectorTypes.TRIO.value) {
      return dispatch('fetchTrioServices', rootState.analyzer.details.dataSource);
    }
    return [];
  },
  async fetchZendeskDepartments({ dispatch, commit, rootGetters }, dataSourceId) {
    return fetchDataFromSource(
      { dispatch, commit, rootGetters },
      dataSourceId,
      'Zendesk departments',
      endpoints.dataSourceZendesk,
    );
  },
  async fetchPuzzelQueues({ dispatch, commit, rootGetters }, dataSourceId) {
    return fetchDataFromSource(
      { dispatch, commit, rootGetters },
      dataSourceId,
      'Puzzel queues',
      endpoints.dataSourcePuzzel,
    );
  },
  async fetchTrioServices({ dispatch, commit, rootGetters }, dataSourceId) {
    return fetchDataFromSource(
      { dispatch, commit, rootGetters },
      dataSourceId,
      'Trio services',
      endpoints.dataSourceTrio,
    );
  },
  async fetchFilterValues({ dispatch, rootState, rootGetters }) {
    try {
      const request = {
        ...rootGetters['auth/headerAuthorization'],
        params: {
          analyzer: rootState.analyzer.details.id,
        },
      };
      return await axios.get(`${endpoints.call}/filter_values/`, request);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch filter values (agents and tags)',
        text: error.message,
      }, { root: true });
      return {};
    }
  },
};

export default {
  namespaced: true,
  state: statisticsState,
  getters: statisticsGetters,
  mutations: statisticsMutations,
  actions: statisticsActions,
};
