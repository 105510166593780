<template>
  <collapsible-section
    title="Sentiment Analysis"
    rounded
    @visible="(val) => isVisible = val"
  >
    <template #content>
      <div class="bg-white p-2">
        <b-row
          v-if="hasData"
          no-gutters
          style="min-height:400px;"
        >
          <b-form-checkbox
            v-model="showAgentSentiment"
          >
            Include agent sentiment
          </b-form-checkbox>
          <b-col>
            <typed-chart
              :key="isVisible + selectedChartType"
              :chart-data="getMetricData()"
              :options="chartOptions"
              :chart-type="selectedChartType"
              class="h-100"
            />
          </b-col>
        </b-row>
      </div>
    </template>
  </collapsible-section>
</template>

<script>
import moment from 'moment';
import TypedChart from '@/components/typedChart.vue';
import CollapsibleSection from '@/components/CollapsibleSection.vue';
import { cloneDeep } from 'lodash';

export default {
  name: 'SentimentAnalysis',
  components: { CollapsibleSection, TypedChart },
  props: {
    data: {
      type: Object,
      required: true,
    },
    objectType: {
      type: String,
      default: 'dialogue',
    },
  },
  data() {
    return {
      isVisible: false,
      selectedChartType: 'line',
      showAgentSentiment: false,
    };
  },
  computed: {
    hasData() {
      return true;
    },
    chartOptions() {
      const options = {
        responsive: true,
        elements: {
          line: {
            tension: 0.3,
          },
        },
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: `${this.objectType} timestamp (minutes)`,
            },
          },
          y: {
            min: -1,
            max: 1,
            ticks: {
              stepSize: 1,
              callback(value) {
                if (value < 0) return 'negative';
                if (value > 0) return 'positive';
                return 'neutral';
              },
            },
          },
        },
        plugins: {
          tooltip: {
            enabled: true,
          },
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
        onHover: (event, chartElement) => {
          // eslint-disable-next-line no-param-reassign
          event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
      };
      return options;
    },
  },
  methods: {
    getMetricData() {
      let labels = this.data.labels.map((e) => moment.utc(e * 1000).format('mm:ss.S'));
      const datasets = cloneDeep(this.data.datasets);
      if (!this.showAgentSentiment) {
        labels = this.data.visitorPhrases.map((e) => labels[e]);
        datasets[0].data = this.data.visitorPhrases.map((e) => datasets[0].data[e]);
      }
      return {
        labels,
        datasets,
      };
    },
  },
};
</script>
