<template>
  <div class="container">
    <div>
      <b-row class="text-center justify-content-center align-items-center pt-5">
        <b-col cols="6">
          <b-card>
            <b-card-title>
              Insufficient permissions to view the requested page.
            </b-card-title>
            <b-button
              variant="primary"
              @click="goBack"
            >
              Go back
            </b-button>

            <b-card-text class="mt-4">
              Given your user-permissions you're not allowed to view the requested page. <br>
              If you believe this is an error, please provide the technical details below,
              when you report the error to your administrator.
            </b-card-text>

            <b-card-text>
              Your user permission is: {{ getUserPermission }} <br>
              The requested path was: {{ requestedPath }}
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'NotAllowedToViewPage',

  computed: {
    ...mapGetters('auth', [
      'isSuperuser',
    ]),
    requestedPath() {
      return this.$route.query.requestedPath;
    },
    getUserPermission() {
      return this.isSuperuser ? 'Super user' : 'Normal user';
    },
  },
  methods: {
    goBack() {
      // Going back -1 will take us to the place that the user was _not_ allowed to visit.
      // This implementation still has its drawback. For instance, a user might attempt to visit A,
      // then attempt to visit B, where both A and B are restricted from him/her. Going back -2 will
      // still being the user to an inaccessible page.
      // Alternative implementation: Take user to /Home.
      this.$router.go(-2);
    },
  },
};
</script>
