<template>
  <main>
    <b-row
      v-if="localIsFetching"
      class="h-100 align-items-center"
    >
      <b-col class="text-center">
        <b-spinner
          style="width: 5rem; height: 5rem;"
        />
      </b-col>
    </b-row>
    <b-card
      class="r-75"
      body-class="px-3"
      title="Analyzer Status"
    >
      <b-list-group-item class="r-25 p-0 mt-3">
        <b-row no-gutters>
          <b-col cols="auto">
            <div
              class="status-left text-center"
              :class="paused ? 'bg-warning' : 'bg-success'"
            >
              <font-awesome-icon
                style="font-size: 1.8rem; color:white;"
                :icon="paused ? 'minus-circle' : 'check-circle'"
                class="my-auto"
              />
            </div>
          </b-col>
          <b-col class="my-auto px-2">
            <span
              style="font-size: 1.8rem;"
              class="bold"
            >{{ status }}</span>
          </b-col>
          <b-col cols="auto">
            <b-button
              class="h-100"
              style="width:80px"
              variant="primary"
              @click="toggleStatus"
            >
              {{ paused ? 'Activate' : 'Pause' }}
            </b-button>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-card>
    <b-row class="my-3">
      <b-col class="pr-2" cols="5">
        <b-card
          class="r-75"
          body-class="p-3"
          title="Calls handling status"
        >
          <b-list-group>
            <b-list-group-item
              v-for="(callStatus, index) of CallStatusTypes"
              :key="index"
              class="d-flex justify-content-between align-items-center"
            >
              {{ callStatus.text }}
              <b-badge variant="primary" pill>
                {{ getCallStatusCount(callStatus.value) }}
              </b-badge>
            </b-list-group-item>
          </b-list-group>
          <small class="text-muted mt-2 d-block">
            {{ callStatusCaption }}
          </small>
        </b-card>
      </b-col>
      <b-col class="pl-2">
        <b-card
          class="r-75"
          body-class="p-3"
        >
          <b-row class="mb-2">
            <b-col
              class="my-auto"
            >
              <h4 class="card-title my-auto">
                Synchronization log
              </h4>
            </b-col>
            <b-col
              class="my-auto"
              cols="auto"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="pagination.count"
                :per-page="pagination.perPage"
                size="sm"
                class="my-auto"
                aria-controls="task-table"
              />
            </b-col>
          </b-row>
          <table-data
            id="task-table"
            ref="task-table"
            :busy="tableBusy"
            responsive
            :fields="taskFields"
            show-empty
            :items="taskItems"
          >
            <template #cell(type)="data">
              {{ snakeCaseToText(data.item.type) }}
            </template>
            <template #cell(status)="data">
              <span
                v-b-tooltip.hover.noninteractive.viewport
                :title="taskStatusTooltip(data.item)"
                :class="taskStatusColor(data.item.status)"
              >
                <font-awesome-icon :icon="taskStatusIcon(data.item.status)" />
              </span>
            </template>
          </table-data>
          <small class="text-muted mt-2 d-block">
            {{ taskTableCaption }}
          </small>
        </b-card>
      </b-col>
    </b-row>
  </main>
</template>
<script>
import TableData from 'supwiz/components/TableData.vue';
import { mapGetters, mapState } from 'vuex';
import axios from 'axios';
import Vue from 'vue';
import endpoints from '@/js/endpoints';
import { snakeCaseToText } from '@/js/utils';
import { CallStatusTypes } from '@/js/constants';

export default {
  name: 'AnalyzerStatus',
  components: {
    TableData,
  },
  data() {
    return {
      CallStatusTypes,
      paused: null,
      localIsFetching: true,
      taskFields: [
        {
          key: 'syncFrom',
          label: 'Sync from',
          formatter: (v) => this.formatDate(v),
        },
        {
          key: 'syncTo',
          label: 'Sync to',
          formatter: (v) => this.formatDate(v),
        },
        { key: 'type', label: 'Type' },
        { key: 'status', label: 'Status' },
      ],
      items: [],
      tableBusy: false,
      pagination: {
        count: null,
        page: 1,
        perPage: 10,
      },
      intervalId: null,
      lastUpdated: null,
      callStatusItems: [],
      isFetchingCallStatus: false,
      callStatusLastUpdated: null,
    };
  },
  computed: {
    ...mapState('analyzer', { analyzerDetails: 'details' }),
    ...mapGetters('auth', ['headerAuthorization']),
    status() {
      switch (this.paused) {
        case true: return 'Paused';
        case false: return 'Active';
        default: return 'Unknown';
      }
    },
    taskItems() {
      return this.items;
    },
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        Vue.set(this.pagination, 'page', val);
      },
    },
    taskTableCaption() {
      return `Last updated: ${this.lastUpdated ? this.lastUpdated.toLocaleString() : '-'}`;
    },
    callStatusCaption() {
      return `Last updated: ${this.callStatusLastUpdated ? this.callStatusLastUpdated.toLocaleString() : '-'}`;
    },
  },
  watch: {
    analyzerDetails(n, o) {
      if (n && n?.id !== o?.id) {
        this.fetchAnalyzerStatus();
      }
    },
    currentPage() {
      this.fetchTasks();
    },
  },
  mounted() {
    if (this.analyzerDetails) {
      this.fetchAnalyzerStatus();
      this.fetchTasks();
      this.setTaskInterval();
      this.fetchCallsStatus();
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    snakeCaseToText,
    async fetchAnalyzerStatus() {
      try {
        this.localIsFetching = true;
        const request = { ...this.headerAuthorization };
        const resp = await axios.get(`${endpoints.analyzer}${this.analyzerDetails.id}/status/`, request);
        this.paused = resp.data.paused;
        this.localIsFetching = false;
      } catch (error) {
        this.$store.dispatch('sidebar/showWarning', {
          title: 'Failed to fetch analyzer status',
          text: error.message,
        });
        this.localIsFetching = false;
      }
    },
    setTaskInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(() => {
        this.fetchTasks(true);
        this.fetchCallsStatus(true);
      }, 20000);
    },
    getCallStatusCount(key) {
      return this.callStatusItems.find((e) => e.status === key)?.the_count || 0;
    },
    async fetchCallsStatus(refreshing = false) {
      if (!refreshing) {
        this.isFetchingCallStatus = true;
      }
      try {
        const request = { ...this.headerAuthorization };
        const resp = await axios.get(`${endpoints.analyzer}${this.analyzerDetails.id}/get_call_distribution/`, request);
        this.callStatusLastUpdated = new Date();
        this.callStatusItems = resp.data;
      } catch (error) {
        this.$store.dispatch('sidebar/showWarning', {
          title: 'Failed to fetch calls status',
          text: error.message,
        });
        this.callStatusItems = [];
      } finally {
        this.isFetchingCallStatus = false;
      }
    },
    async fetchTasks(refreshing = false) {
      if (!refreshing) {
        this.tableBusy = true;
      }
      try {
        const request = {
          ...this.headerAuthorization,
          params:
         { analyzer: this.analyzerDetails.id, page: this.pagination.page },
        };
        const { data } = await axios.get(`${endpoints.task}`, request);
        const { results, count } = data;
        for (const item of results) {
          item.syncFrom = this.taskTime(item, 'start');
          item.syncTo = this.taskTime(item, 'end');
        }
        this.pagination.count = count;
        this.lastUpdated = new Date();
        this.tableBusy = false;
        this.items = results;
      } catch (error) {
        this.$store.dispatch('sidebar/showWarning', {
          title: 'Failed to fetch tasks',
          text: error.message,
        });
        this.items = [];
        this.tableBusy = false;
      }
    },
    async toggleStatus() {
      try {
        const authentication = this.$store.getters['auth/headerAuthorization'];
        const resp = await axios.patch(`${endpoints.analyzer}${this.analyzerDetails.id}/status/`, { paused: !this.paused }, authentication);
        this.paused = resp.data.paused;
      } catch (error) {
        this.$store.dispatch('sidebar/showWarning', {
          title: `Failed to ${this.paused ? 'activate' : 'pause'} analyzer`,
          text: error.message,
        });
      } finally {
        document.activeElement.blur();
      }
    },
    taskStatusIcon(status) {
      switch (status) {
        case 'done': return 'check-circle';
        case 'pending': return 'clock';
        case 'failed': return 'minus-circle';
        default: return 'question-circle';
      }
    },
    taskStatusColor(status) {
      switch (status) {
        case 'done': return 'text-success';
        case 'pending': return 'text-primary';
        case 'failed': return 'text-danger';
        default: return 'text-warning';
      }
    },
    taskStatusTooltip(task) {
      return task.aborted ? 'aborted' : task.status;
    },
    taskTime(task, field) {
      if (!task || !task.meta.date_range) {
        return '';
      }
      const dateValue = task.meta.date_range[field];
      return dateValue ? new Date(dateValue) : dateValue;
    },
    formatDate(date) {
      return date ? date.toLocaleString() : date;
    },
  },
};
</script>
<style scoped>
.status-left{
  border-radius: 0.25rem 0 0 0.25rem;
  height: 50px;
  width: 50px;
  line-height: 60px;
  vertical-align: middle;
}
</style>
