<template>
  <app-template
    v-bind="{
      pages,
      showSidebar,
      userDetails,
      toolDetails: {
        name: 'SpeechAnalytics',
        logo: require('./assets/supwiz-logo-flat.svg'),
        env: speechAnalyticsEnvironment,
      },
      showSpinner: pageLoading,
      loggedIn,
    }"
    notifications-center
  >
    <template #header>
      <div
        v-if="loggedIn && $route.name !== 'home'"
        class="mr-3"
      >
        <b-nav pills>
          <b-nav-item
            v-b-tooltip.hover.noninteractive
            title="Connectors"
            class="menu-item text-center mb-0 ease-in-out mr-3"
            :to="{ name: 'connector-overview' }"
            :class="{ active: isRouteActive('connector') }"
          >
            <font-awesome-icon
              icon="link"
              size="lg"
            />
          </b-nav-item>
          <b-nav-item
            v-b-tooltip.hover.noninteractive
            title="Analyzers"
            class="menu-item text-center mb-0 ease-in-out mr-3"
            :to="{ name: 'analyzer-overview' }"
            :class="{ active: isRouteActive('analyzer') }"
          >
            <font-awesome-icon
              icon="microphone-alt"
              size="lg"
            />
          </b-nav-item>
          <b-nav-item
            v-if="isSuperuser"
            v-b-tooltip.hover.noninteractive
            title="Administration"
            class="menu-item text-center mb-0 ease-in-out"
            :to="{ name: 'administration' }"
            :class="{ active: isRouteActive('administration') }"
          >
            <font-awesome-icon
              icon="user-lock"
              size="lg"
            />
          </b-nav-item>
        </b-nav>
      </div>
      <b-button
        v-if="loggedIn"
        id="info-button"
        v-b-tooltip.noninteractive.bottom
        v-b-modal.version-info-modal
        title="Show software version"
        class="d-flex justify-content-center
        align-items-center overflow-hidden
        shadow-none font-weight-bold mr-3"
      >
        <font-awesome-icon
          size="lg"
          icon="info"
        />
      </b-button>
      <b-modal
        id="version-info-modal"
        title="Version Information"
        hide-footer
      >
        <b-table
          borderless
          striped
          small
          :items="versionInfoArray"
        />
      </b-modal>
    </template>

    <template #page-content>
      <transition
        mode="out-in"
        name="fade"
      >
        <router-view />
      </transition>
    </template>
  </app-template>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AppTemplate from 'supwiz/components/template/AppTemplate.vue';
import { getSpeechAnalyticsEnvironment, versionInfoArray } from '@/js/utils';

export default {
  components: { AppTemplate },
  computed: {
    ...mapState('sidebar', ['pageLoading', 'showSidebar']),
    ...mapGetters('sidebar', ['loggedIn', 'pages']),
    ...mapGetters('auth', ['displayName', 'isSuperuser']),
    userDetails() {
      return {
        displayName: this.displayName,
      };
    },
    speechAnalyticsEnvironment() {
      return getSpeechAnalyticsEnvironment();
    },
  },
  methods: {
    versionInfoArray,
    isRouteActive(name) {
      return this.$route.path.includes(name);
    },
  },
};
</script>

<style scoped>
.nav-item {
  opacity:.5;
}
.nav-item > a{
  color:#fff;
  padding: 7px;
  border: none !important;
}
.nav-item > a:hover{
  color:white;
}
::v-deep .dropdown .nav-link {
  color:#fff;
  padding: 7px;
  border: none !important;
}
::v-deep .dropdown .nav-link:hover {
  color:#fff;
}
.nav-item.active {
  background:rgba(0, 0, 0, 0.25)!important;
  opacity:.9;
}
.nav-item.show {
  background:rgba(0, 0, 0, 0.25)!important;
  opacity:1;
  z-index: 1;
}
.menu-item {
  width:50px !important;
  height:36px !important;
  border: none!important;
}
</style>

<style>
#app {
  background-image:
    linear-gradient(to right, rgba(2,76,115,0.97), rgba(2,76,115,0.97)),
    url('./assets/background-pattern.svg');
}
.unsaved-text{
  font-size: 80%;
  color:#E99002 !important;
  line-height: 1.5;
  font-weight: 400;
}
.border-warning{
  border: 3px solid #E99002 !important;
  margin: -3px !important;
}
.key-prop{
  border: none;
  font-weight: 500;
  font-size: 1rem;
  padding-top: 0.20rem;
  padding-bottom: 0.20rem;
}
.table-label{
   font-weight: 500;
   font-size: 1rem;
   font-family: inherit;
   border-top-left-radius: 0.25rem;
   border-top-right-radius: 0.25rem;
}
/* statistics/insights */
.stat-overlay{
  border-radius: 0.75rem;
}
.config-title{
  font-size: 1.40625rem;
  display: inline-block;
  font-weight:300;
  line-height: 1.4;
}
.config-dropdown:hover{
  border-color: #b8bec1 !important;
}
</style>
<style scoped>
#info-button{
  background: rgba(0,0,0,0.1);
  border-color: transparent;
  color: #fff;
  min-height: 36.4px;
  max-height: 36.4px;
  min-width: 36.4px;
  max-width: 36.4px;
}
#info-button:hover {
  background-color: rgba(0,0,0,0.25)!important;
}
</style>
