<template>
  <b-modal
    v-if="Object.keys(localCopy).length"
    id="add-category-modal"
    size="lg"
    scrollable
    :title="`${modalInfo.action} Category`"
    :ok-disabled="$v.localCopy.$invalid"
    @hide="$emit('hide', null)"
    @ok="okClicked()"
  >
    <edit-key-value
      key-prop="Category name"
      description="Name of the custom category"
      :value-prop="localCopy.name"
      :min-key-width="keyWidth"
      :state="!$v.localCopy.$invalid"
      type="input"
      class="my-3"
      @input="(x)=> localCopy.name = x"
    >
      <template #feedback>
        <b-form-invalid-feedback
          v-if="!$v.localCopy.name.required"
        >
          Name is required.
        </b-form-invalid-feedback>
        <b-form-invalid-feedback
          v-if="!$v.localCopy.name.isUnique"
        >
          Name must be unique
        </b-form-invalid-feedback>
      </template>
    </edit-key-value>
    <edit-key-value
      v-if="!stemsAndSynonymsDisabled"
      key-prop="Stems & synonyms"
      description="Choose whether the category should match only exact matches (disabled) or
          synonyms and stemmed words (enabled)"
      description-no-viewport
      :value-prop="localCopy.stemAndSynonym"
      :min-key-width="keyWidth"
      class="mt-3"
      type="checkbox"
      @input="(x)=>setStemAndSynonym(x)"
    />
    <edit-key-value
      v-if="!stemsAndSynonymsDisabled"
      key-prop="Full transcript only"
      description="Choose whether the category should only match calls that are fully transcribed"
      description-no-viewport
      :value-prop="localCopy.fullTranscriptOnly"
      :min-key-width="keyWidth"
      class="mt-3"
      type="checkbox"
      @input="(x)=>setFullTranscriptOnly(x)"
    />
    <edit-key-value
      key-prop="Speaker filter"
      description="Choose whether the category should be applied to the agent, visitor or both"
      description-no-viewport
      :value-prop="localCopy.speakerFilter"
      :min-key-width="keyWidth"
      class="mt-3"
      type="select"
      default="both"
      :options="SpeakerFilterTypes"
      @input="(x)=>setSpeakerFilter(x)"
    />
    <b-input-group class="mt-3">
      <b-input-group-text
        class="bg-success text-white justify-content-center w-25 m-0 key-prop"
        :style="`width: ${keyWidth - 35}px !important;`"
      >
        Include
      </b-input-group-text>
      <b-button
        variant="success"
        class="d-flex ml-1"
        @click="addList('include')"
      >
        <font-awesome-icon
          icon="plus"
        />
      </b-button>
    </b-input-group>

    <b-form-group
      class="mb-3 mt-1"
      description="Include calls which have at least one item (text/tag)
          from each of the include lists above."
    >
      <div
        v-if="includeCategoriesLength(localCopy) === 0"
        class="border r-25 p-2 mb-2"
      >
        Press + button to add a list.
      </div>
      <selector
        v-for="index in includeCategoriesLength(localCopy)"
        v-else
        :key="index"
        :active-item="localCopy"
        :index="index - 1"
        type="include"
        @add="(v) => addItem(v, index - 1)"
        @delete-list="deleteList('include', index - 1)"
        @delete-item="(v) => deleteItem(v, index - 1)"
      />
    </b-form-group>
    <b-input-group>
      <b-input-group-text
        class="bg-danger text-white justify-content-center w-25 m-0 key-prop"
        :style="`width: ${keyWidth - 35}px !important;`"
      >
        Exclude
      </b-input-group-text>
      <b-button
        variant="danger"
        class="d-flex ml-1"
        @click="addList('exclude')"
      >
        <font-awesome-icon
          icon="plus"
        />
      </b-button>
    </b-input-group>
    <b-form-group
      class="mb-3 mt-1"
      description="Exclude calls which at least one item (text/tag)
          from each of the exclude lists above."
    >
      <div
        v-if="excludeCategoriesLength(localCopy) === 0"
        class="border r-25 p-2 mb-2"
      >
        Press + button to add a list.
      </div>
      <selector
        v-for="index in excludeCategoriesLength(localCopy)"
        v-else
        :key="index - 1"
        :active-item="localCopy"
        :index="index - 1"
        type="exclude"
        @add="(v) => addItem(v, index - 1)"
        @delete-list="deleteList('exclude', index - 1)"
        @delete-item="(v) => deleteItem(v, index - 1)"
      />
    </b-form-group>
  </b-modal>
</template>
<script>
import EditKeyValue from 'supwiz/components/EditKeyValue.vue';
import { mapActions, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import Selector from '@/components/Selector.vue';
import { supportedStemmingLanguages, SpeakerFilterTypes } from '@/js/constants';

export default {
  name: 'AddCategoryModal',
  components: {
    EditKeyValue, Selector,
  },
  mixins: [validationMixin],
  props: {
    activeItem: {
      type: Object,
      default: () => {},
    },
    modalInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      SpeakerFilterTypes,
      keyWidth: 200,
      localCopy: {},
    };
  },
  computed: {
    ...mapState('analyzer', { analyzerDetails: 'details' }),
    ...mapState('category', { categories: 'items' }),
    stemsAndSynonymsDisabled() {
      return !supportedStemmingLanguages.includes(this.analyzerDetails.language.slice(0, 2));
    },
  },
  watch: {
    activeItem(n) {
      this.localCopy = cloneDeep(n);
    },
  },
  methods: {
    ...mapActions('category', {
      fetchCategories: 'fetchItems',
      addCategory: 'addItem',
      updateCategory: 'patchItem',
    }),
    includeCategoriesLength(item) {
      return item.lines.phrasesAllowed ? item.lines.phrasesAllowed.length : 0;
    },
    excludeCategoriesLength(item) {
      return item.lines.phrasesDisallowed ? item.lines.phrasesDisallowed.length : 0;
    },
    setStemAndSynonym(v) {
      Vue.set(this.localCopy, 'stemAndSynonym', v);
    },
    setSpeakerFilter(v) {
      Vue.set(this.localCopy, 'speakerFilter', v);
    },
    setFullTranscriptOnly(v) {
      Vue.set(this.localCopy, 'fullTranscriptOnly', v);
    },
    addList(type) {
      if (type === 'include') {
        this.localCopy.lines.phrasesAllowed.push([]);
        this.localCopy.lines.tagsAllowed.push([]);
      } else {
        this.localCopy.lines.phrasesDisallowed.push([]);
        this.localCopy.lines.tagsDisallowed.push([]);
      }
    },
    deleteList(type, index) {
      if (type === 'include') {
        this.localCopy.lines.phrasesAllowed.splice(index, 1);
        this.localCopy.lines.tagsAllowed.splice(index, 1);
      } else {
        this.localCopy.lines.phrasesDisallowed.splice(index, 1);
        this.localCopy.lines.tagsDisallowed.splice(index, 1);
      }
    },
    addItem(item, index) {
      this.localCopy.lines[item.type][index].push(item.value);
    },
    deleteItem(item, index) {
      const itemIndex = this.localCopy.lines[item.type][index].indexOf(item.value);
      this.localCopy.lines[item.type][index].splice(itemIndex, 1);
    },
    async okClicked() {
      if (this.modalInfo.action === 'Add') {
        await this.addCategory({ newItem: this.localCopy });
      } else {
        await this.updateCategory(this.localCopy);
        this.fetchCategories();
      }
    },
  },
  validations() {
    return {
      localCopy: {
        name: {
          required,
          isUnique(value) {
            return !Object.values(this.categories).map((e) => e.name).includes(value)
            || value === this.modalInfo.activeItemId;
          },
        },
      },
    };
  },

};
</script>
